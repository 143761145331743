<template>
  <div class="flexible-pricing-component">
    <validation-observer ref="observer">
      <b-row>
        <b-col lg="12" md="12" sm="12" cols="12">
          <v-form ref="form" v-model="valid" lazy-validation>
            <label v-if="pricing_flexible.length === 0" class="span-alert">{{
              $t("MESSAGE.ERROR.AT_LEAST_ONE_PRICE")
            }}</label>
            <table class="table-content">
              <thead>
                <tr>
                  <th>Min.</th>
                  <th>Max.</th>
                  <th>{{ $t("PRICING.NEW.FLEXIBLE_TITLE_TABLE_1") }}</th>
                  <th>{{ $t("PRICING.NEW.FLEXIBLE_TITLE_TABLE_2") }}</th>
                  <th v-if="!onlyView"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in pricing_flexible"
                  :key="index"
                  class="mb-3 border-row"
                >
                  <td>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('PRICING.NO_MIN')"
                      :rules="
                        'required|min_value:' +
                          (index > 0
                            ? parseInt(
                                pricing_flexible[index - 1].minDuration
                              ) + 1
                            : 0)
                      "
                    >
                      <v-text-field
                        type="number"
                        v-model.trim="item.minDuration"
                        hide-details="auto"
                        :placeholder="$t('PRICING.NO_MIN')"
                        @change="changeMax(index)"
                        outlined
                        dense
                        :error-messages="errors"
                        :disabled="onlyView"
                      />
                    </validation-provider>
                  </td>
                  <td class="input-max">
                    {{ item.maxDuration ? item.maxDuration : "+" }}
                  </td>
                  <td class="input-unit">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Duration Type"
                      :rules="validations.optionRules"
                    >
                      <v-select
                        v-model="item.durationTypeId"
                        :items="options"
                        :rules="validations.optionRules"
                        item-text="text"
                        item-value="value"
                        v-if="index === 0"
                        @change="changeUnit"
                        dense
                        outlined
                        hide-details="auto"
                        :error-messages="errors"
                        :disabled="onlyView"
                      />
                    </validation-provider>
                    <div class="disabled-select">
                      <b-form-select
                        v-model="item.durationTypeId"
                        :options="options"
                        @change="changeUnit"
                        v-if="index >= 1"
                        disabled
                      />
                    </div>
                  </td>
                  <td class="ml-3">
                    <v-text-field
                      v-model.trim="item.amount"
                      :rules="validations.amount"
                      type="number"
                      outlined
                      dense
                      :disabled="onlyView"
                    >
                      <template
                        :slot="campus.before !== 1 ? 'append' : 'prepend-inner'"
                      >
                        {{ campus.symbol }}
                      </template>
                    </v-text-field>
                  </td>
                  <td class="text-center" v-if="!onlyView">
                    <a
                      class="btn btn-icon btn-light btn-hover-danger"
                      @click="deleteItem(index)"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-danger">
                        <v-icon color="red">
                          mdi-delete
                        </v-icon>
                      </span>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <v-btn
              v-if="!onlyView"
              depressed
              color="primary"
              @click="add"
              class="mt-5"
              >{{ $t("PRICING.NEW.BTN_FLEXIBLE") }}</v-btn
            >
          </v-form>
        </b-col>
      </b-row>
    </validation-observer>
    <b-row v-if="btnSave === 'true' && !onlyView">
      <div class="d-flex justify-content-end w-100">
        <v-btn depressed color="primary" @click="save">{{
          $t("GENERAL.SAVE")
        }}</v-btn>
      </div>
    </b-row>
  </div>
</template>

<script>
import { STORE_PRODUCT_PRICING } from "@/core/services/store/configure/product_pricing.module";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Rules from "@/core/services/utils/validations";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      valid: true,
      options: [
        { value: 2, text: this.$t("GENERAL.DAYS") },
        { value: 3, text: this.$t("GENERAL.WEEKS") },
        { value: 4, text: this.$t("GENERAL.MONTHS") },
      ],
      isTiered: false,
      pricing_flexible: [
        {
          amount: null,
          durationTypeId: 3,
          maxDuration: "+",
          minDuration: null,
          position: 1,
        },
      ],
      // VALIDATIONS
      validations: {
        valid: true,
        minDuration: [v => !!v || this.$t("GENERAL.FIELD_ERROR")],
        optionRules: [v => !!v || this.$t("GENERAL.FIELD_ERROR")],
        amount: [v => !!v || this.$t("GENERAL.FIELD_ERROR")],
      },
      newItem: {
        amount: null,
        durationTypeId: null,
        maxDuration: "+",
        minDuration: null,
        productPricingId: null,
      },
      validations_global: {
        ...Rules,
      },
    };
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    btnSave: String,
    name: String,
    dataPeak: Array,
    onlyView: Boolean,
  },
  watch: {
    pricing_flexible: {
      deep: true,
      handler() {
        this.$emit("data", this.pricing_flexible);
      },
    },
  },
  methods: {
    deleteItem(item) {
      this.pricing_flexible.splice(item, 1);
      this.$emit("data", this.pricing_flexible);
    },
    add() {
      let newItem;
      if (this.pricing_flexible.length > 0) {
        newItem = {
          amount: null,
          durationTypeId: this.pricing_flexible[0].durationTypeId,
          maxDuration: "+",
          minDuration: null,
          position: this.pricing_flexible.length + 1,
          productPricingId: null,
        };
      } else {
        newItem = {
          amount: null,
          durationTypeId: 3,
          maxDuration: "+",
          minDuration: null,
          position: 1,
          productPricingId: null,
        };
      }

      this.pricing_flexible.push(newItem);
    },
    changeUnit() {
      for (let index = 1; index < this.pricing_flexible.length; index++) {
        this.pricing_flexible[
          index
        ].durationTypeId = this.pricing_flexible[0].durationTypeId;
      }
    },
    changeMax(index) {
      if (index >= 1) {
        let value = parseInt(this.pricing_flexible[index].minDuration) - 1;
        this.pricing_flexible[index - 1].maxDuration = value;
      }
    },
    getDataToStore() {
      if (!this.$refs.form.validate()) {
        return false;
      } else {
        let product_pricings;

        //Convert Data String To Int
        product_pricings = this.pricing_flexible.map(item => {
          item.amountIsPerDuration = true;
          item.amount = parseFloat(item.amount);
          item.minDuration = parseInt(item.minDuration);
          item.maxDuration =
            item.maxDuration === "+" ? null : parseInt(item.maxDuration);
          return item;
        });

        return product_pricings;
      }
    },
    save() {
      if (!this.$refs.form.validate() || this.name === "") {
        if (this.name === "") {
          const input = document.getElementById("nameNew").parentNode.parentNode
            .firstChild;
          input.style.border = "2px solid #FF0202";
        }
        this.$bvToast.toast(this.$t("MESSAGE.ERROR.DESCRIPTION_NEW"), {
          title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
          variant: "danger",
          solid: true,
        });
      } else {
        const input = document.getElementById("nameNew").parentNode.parentNode
          .firstChild;
        input.style.border = "1px solid black";
        let product_pricings, data;

        //Convert Data String To Int
        product_pricings = this.pricing_flexible.map(item => {
          item.amountIsPerDuration = true;
          item.amount = parseFloat(item.amount);
          item.minDuration = parseInt(item.minDuration);
          item.maxDuration =
            item.maxDuration === "+" ? 0 : parseInt(item.maxDuration);
          return item;
        });

        data = {
          name: this.name,
          campusId: this.campus.campusId,
          isTiered: this.isTiered,
          product_pricings: product_pricings,
        };

        this.$store
          .dispatch(STORE_PRODUCT_PRICING, data)
          .then(({ productPricingTemplateId }) => {
            this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
              title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
              variant: "success",
              solid: true,
            });

            setTimeout(() => {
              this.$router.push({
                name: "pricing-edit",
                params: { id: productPricingTemplateId },
              });
            }, 1500);
          });
      }
    },
  },
  computed: {
    ...mapGetters(["campus"]),
    getInfo() {
      return this.pricing_flexible;
    },
  },
  mounted() {
    this.$emit("data", this.pricing_flexible);
    if (this.dataPeak) {
      this.pricing_flexible = this.dataPeak;
      this.pricing_flexible.forEach((product_pricing, index) => {
        this.changeMax(index);
      });
    } else {
      const {
        productPricing,
        isEditingProductPricing,
        isType,
      } = this.$store.getters;
      if (productPricing.product_pricings && isEditingProductPricing) {
        if (isType === 2) {
          this.pricing_flexible = productPricing.product_pricings.filter(
            product_pricing => {
              if (
                product_pricing.start === null &&
                product_pricing.end === null
              )
                return true;
              else return false;
            }
          );

          let durationTypeId = 0;
          this.pricing_flexible.forEach((product_pricing, index) => {
            if (durationTypeId === 0)
              durationTypeId = product_pricing.durationTypeId;
            this.pricing_flexible.splice(index, 1, {
              ...product_pricing,
              durationTypeId: durationTypeId,
            });
            this.changeMax(index);
          });
          this.isTiered = Boolean(productPricing.isTiered);
        }
      }
    }
    if (this.pricing_flexible.length === 0) {
      let newData = {
        ...this.newItem,
        position: 0,
      };
      this.pricing_flexible.push(newData);
    }
  },
};
</script>

<style lang="scss" scoped>
label.span-alert {
  color: red;
}
</style>
