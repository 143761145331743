<template>
  <v-card class="mb-2">
    <v-card-title>{{ $t("PRODUCTS_ADDONS.LODGING_TITLE") }}</v-card-title>
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="12" md="12">
          <ProductsTreeView
            :input.sync="accommodationsData"
            :selection="accommodationsData"
            :data="accommodationList"
            :name="$t('PRODUCTS_ADDONS.LODGING_NAME')"
            keyId="accommodationId"
            keyName="name"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProductsTreeView from "@/view/content/components/ProductsTreeView";

export default {
  components: { ProductsTreeView },
  props: {
    editData: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {
      errors: [],
      accommodationsData: [],
    };
  },
  mounted() {
    this.getAllAccommodation();
  },
  methods: {
    ...mapActions(["getAllAccommodation"]),
    addDataForm: data => {
      this.formCourse = data;
    },
  },
  computed: {
    ...mapGetters(["accommodations"]),
    accommodationList() {
      return this.accommodations?.map(accommodation => ({
        ...accommodation,
        name: accommodation.name || "No Name",
      }));
    },
  },
  watch: {
    accommodationsData: {
      handler: function() {
        this.$emit("data", this.accommodationsData);
      },
      deep: true,
    },
    editData: {
      handler: function() {
        const dataForm = JSON.parse(JSON.stringify(this.editData));
        this.accommodationsData = dataForm.accommodations.map(item => {
          return { id: item.accommodationId };
        });
      },
      deep: true,
    },
  },
};
</script>
