import integer from
'../../../assets/plugins/formvalidation/src/js/validators/integer';
<template>
  <div class="RatingCoursesSelector">
    <v-text-field
      outlined
      dense
      v-model="value"
      @change="$emit('update:input', value)"
      item-value="id"
      label="Rating"
      :rules="rules"
    >
    </v-text-field>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  isRequired: {
    type: Number,
    default: 0,
  },
  data() {
    return {
      rules: [
        v => v >= 0 || "Manual rating must be higher than 0",
        v => v <= 100 || "Manual rating must not be above 100",
      ],
    };
  },
};
</script>
