<template>
  <v-row>
    <v-col cols="12" md="12">
      <validation-observer ref="observer">
        <v-form>
          <v-row gutters="2" class="pt-0 mt-0 pb-0 mb-0">
            <v-col cols="12" md="6">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('FEE.NAME')"
                rules="required|max: 50"
              >
                <v-text-field
                  v-model="form.name"
                  :label="$i18n.t('FEE.NAME')"
                  dense
                  outlined
                  required
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('FEE.FEE_TYPE')"
                rules="required"
              >
                <v-select
                  v-model="form.feeTypeId"
                  :label="$i18n.t('FEE.FEE_TYPE')"
                  :items="feeTypes"
                  item-text="name"
                  item-value="feeTypeId"
                  dense
                  outlined
                  required
                  :error-messages="errors"
                ></v-select>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row align-content="center" align="center">
            <v-col cols="12" md="6">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('FEE.FEE_ALTERNATIVE_TO')"
                v-if="
                  parseInt(form.feeTypeId) === parseInt(FEE_TYPE_ALTERNATIVE) &&
                    (materialFees.length > 0 || form.feeAlternativeId > 0)
                "
              >
                <v-autocomplete
                  v-model="form.feeAlternativeId"
                  :label="$i18n.t('FEE.FEE_ALTERNATIVE_TO')"
                  :items="materialFees"
                  item-text="name"
                  item-value="feeId"
                  dense
                  outlined
                  required
                  :error-messages="errors"
                ></v-autocomplete>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('FEE.APPLICABLE_TO')"
                rules="required"
              >
                <v-select
                  :disabled="type"
                  v-model="form.applicableTo"
                  :label="$i18n.t('FEE.APPLICABLE_TO')"
                  :items="applicableTo"
                  item-text="name"
                  item-value="id"
                  dense
                  required
                  :error-messages="errors"
                  outlined
                ></v-select>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('FEE.DESCRIPTION')"
                rules="max:3300"
              >
                <v-textarea
                  v-model="form.description"
                  :label="$i18n.t('FEE.DESCRIPTION')"
                  dense
                  outlined
                  :error-messages="errors"
                ></v-textarea>
              </validation-provider>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-col>
  </v-row>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    type: Number,
    formData: {
      type: Object,
      default: function() {
        return {
          name: "",
          feeTypeId: "",
          applyOnlyOneTime: false,
          applicableTo: "",
          description: "",
          feeAlternativeId: null,
        };
      },
    },
  },
  data() {
    return {
      form: {
        name: "",
        feeTypeId: "",
        applyOnlyOneTime: false,
        applicableTo: "",
        description: "",
        feeAlternativeId: null,
      },
      applicableTo: [
        { id: 1, name: this.$i18n.t("FEE.COURSES") },
        { id: 2, name: this.$i18n.t("FEE.LODGING") },
        { id: 4, name: this.$i18n.t("FEE.PACKAGES") },
      ],
      FEE_TYPE_ALTERNATIVE: "3",
    };
  },
  mounted() {
    if (this.type) {
      this.form.applicableTo = Number(this.type);
    }
  },
  computed: {
    ...mapGetters(["getFees", "feeTypes"]),
    materialFees() {
      if (this.$route.params.index) {
        return this.getFees?.filter(item => {
          return (
            parseInt(item.feeTypeId) === parseInt(this.FEE_TYPE_ALTERNATIVE) &&
            this.$route.params.index &&
            parseInt(this.$route.params.index) !== parseInt(item.feeId) &&
            (parseInt(item.feeId) === parseInt(this.form.feeAlternativeId) ||
              !item.feeAlternativeId)
          );
        });
      } else {
        return this.getFees?.filter(item => {
          return (
            parseInt(item.feeTypeId) === parseInt(this.FEE_TYPE_ALTERNATIVE) &&
            !item.feeAlternativeId
          );
        });
      }
    },
  },
  methods: {
    ...mapActions(["storeFee"]),
    validateForm() {
      return this.$refs.observer.validate();
    },
    getData() {
      return this.form;
    },
    cleanData() {
      this.form = {
        name: "",
        feeTypeId: "",
        applyOnlyOneTime: false,
        applicableTo: "",
        description: "",
      };
    },
  },
  watch: {
    "form.feeTypeId": {
      handler(newValue) {
        if (parseInt(newValue) !== parseInt(this.FEE_TYPE_ALTERNATIVE)) {
          this.form.feeAlternativeId = null;
        }
      },
      deep: true,
    },
    form: {
      handler(newValue) {
        this.$emit("data", newValue);
      },
      deep: true,
    },
    formData: {
      handler(newValue) {
        this.form.name = newValue.name;
        this.form.feeTypeId = newValue.feeTypeId;
        this.form.applyOnlyOneTime = newValue.applyOnlyOneTime;
        this.form.applicableTo = newValue.applicableTo;
        this.form.description = newValue.description;
        this.form.feeAlternativeId = newValue.feeAlternativeId;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0 !important;
}
</style>
