<template>
  <div class="Market-List-component">
    <!--begin::PeakSeason-->
    <div class="row">
      <div class="card card-custom card-stretch gutter-b w-100">
        <!--begin::Body-->
        <div class="card-body pt-5">
          <!--begin::Head Table-->
          <div class="container-head">
            <div>
              {{ $t("PRICING.MARKET.NAME") }}
            </div>
            <div>
              {{ $t("PRICING.MARKET.EXPIRY_DATE") }}
            </div>
            <div />
          </div>
          <!--begin::Body Table-->
          <div
            class="container-body"
            v-for="(item, index) in optionsSelect"
            :key="index"
          >
            <div>{{ item.name }}</div>
            <div>{{ item.expiry }}</div>
            <div class="content-options">
              <span
                class="icon-style icon-style--info"
                @click="ShowItem(index)"
              >
                <font-awesome-icon icon="info-circle" />
              </span>
              <span
                class="icon-style icon-style--edit"
                @click="EditItem(index)"
              >
                <font-awesome-icon icon="edit" />
              </span>
              <span
                class="icon-style icon-style--delete"
                @click="deleteItem(index)"
              >
                <font-awesome-icon icon="trash-alt" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end::PeakSeason-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      item: null,
      optionsSelect: null,
    };
  },
  methods: {
    ShowItem(index) {
      if (this.optionsSelect[index].panel) {
        this.optionsSelect[index].panel = false;
      } else {
        this.optionsSelect[index].panel = true;
      }
    },
    EditItem(index) {
      return index;
    },
    DeleteItem(index) {
      return index;
    },
  },
  mounted() {
    const { markets } = this.$store.getters;
    this.optionsSelect = markets.map(item => {
      item.panel = false;
      item.expiry = "No Expiry";
      return item;
    });
  },
};
</script>
