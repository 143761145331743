<template>
  <div class="one-pricing-component">
    <b-row>
      <b-col lg="12" md="12" sm="12">
        <v-form ref="form" v-model="valid" lazy-validation>
          <label>{{ $t("PRICING.NEW.SIMPLE_TITLE") }}</label>
          <b-col lg="8" md="12" sm="12" cols="12" class="content-row">
            <b-row>
              <b-col lg="5" md="4" sm="4" cols="10" class="content-row">
                <v-text-field
                  type="number"
                  v-model="one_time[0].amount"
                  :rules="validations.amount"
                  outlined
                  dense
                  :disabled="onlyView"
                >
                  <template
                    :slot="campus.before !== 1 ? 'append' : 'prepend-inner'"
                  >
                    {{ campus.symbol }}
                  </template>
                </v-text-field>
              </b-col>
            </b-row>
          </b-col>
        </v-form>
      </b-col>
    </b-row>
    <b-row v-if="btnSave === 'true' && !onlyView">
      <div class="d-flex justify-content-end w-100">
        <v-btn depressed color="primary" @click="save">{{
          $t("GENERAL.SAVE")
        }}</v-btn>
      </div>
    </b-row>
  </div>
</template>

<script>
import { STORE_PRODUCT_PRICING } from "@/core/services/store/configure/product_pricing.module";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      valid: true,
      one_time: [
        {
          amount: 0,
          amountIsPerDuration: true,
          durationTypeId: null,
          position: 1,
        },
      ],
      // VALIDATIONS
      validations: {
        valid: true,
        amount: [v => !!v || this.$t("GENERAL.FIELD_ERROR")],
      },
      newItem: [
        {
          amount: null,
          amountIsPerDuration: true,
          durationTypeId: null,
        },
      ],
    };
  },
  props: {
    btnSave: String,
    name: String,
    dataPeak: Array,
    onlyView: Boolean,
  },
  computed: {
    ...mapGetters(["campus"]),
  },
  watch: {
    one_time: {
      deep: true,
      handler() {
        this.$emit("data", this.one_time);
      },
    },
  },
  methods: {
    getDataToStore() {
      if (!this.$refs.form.validate() || this.name === "") {
        return false;
      } else {
        this.one_time[0].amount = parseFloat(this.one_time[0].amount);
        return this.one_time;
      }
    },
    save() {
      if (!(!this.$refs.form.validate() || this.name === "")) {
        let data;

        const { currentUser } = this.$store.getters;

        this.one_time[0].amount = parseFloat(this.one_time[0].amount);

        data = {
          name: this.name,
          campusId: currentUser.campusId,
          product_pricings: this.one_time,
        };

        this.$store
          .dispatch(STORE_PRODUCT_PRICING, data)
          .then(({ productPricingTemplateId }) => {
            this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
              title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
              variant: "success",
              solid: true,
            });

            setTimeout(() => {
              this.$router.push({
                name: "pricing-edit",
                params: { id: productPricingTemplateId },
              });
            }, 1500);
          });
      }
    },
  },
  mounted() {
    this.$emit("data", this.one_time);
    if (this.dataPeak) {
      this.one_time = this.dataPeak;
      this.one_time.forEach((product_pricing, index) => {
        if (index > 0) this.one_time.splice(1, 1);
      });
    } else {
      const {
        productPricing,
        isType,
        isEditingProductPricing,
      } = this.$store.getters;

      if (isEditingProductPricing) {
        if (isType === 4) {
          this.one_time = productPricing.product_pricings.filter(
            product_pricing => {
              if (
                product_pricing.start === null &&
                product_pricing.end === null
              )
                return true;
              else return false;
            }
          );
          this.one_time.forEach((product_pricing, index) => {
            if (index > 0) this.one_time.splice(1, 1);
          });
        }
      }
    }
    if (this.one_time.length === 0) {
      let newData = {
        ...this.newItem,
        position: 0,
      };
      this.one_time.push(newData);
    }
    if (!this.one_time[0].amount) this.one_time[0].amount = null;
  },
};
</script>
