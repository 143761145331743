<template>
  <div class="expiry-date-component">
    <!--begin::PeakSeason-->
    <v-card class="mt-6">
      <v-card-title>
        {{ $t("PRICING.EXPIRY_DATE.TITLE") }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col lg="4" md="4" sm="6" cols="6">
            <DatePicker
              :date="data.ruleTypeThresholdDate"
              :is-required="false"
              :name="$t('PRICING.EXPIRY_DATE.PLACHEHOLDER')"
              :input.sync="data.ruleTypeThresholdDate"
              @changes="getDate"
            />
          </v-col>
          <v-col lg="4" md="4" sm="6" cols="6">
            <v-checkbox
              v-model="data.allowExpiredBookings"
              class="mt-0 mb-0 pt-2 pb-0"
              :label="$t('PRICING.EXPIRY_DATE.CHECK')"
              @change="doesNotExpire"
              :disabled="data.ruleTypeThresholdDate === null || onlyView"
            />
          </v-col>
        </v-row>
        <div v-if="data.ruleTypeThresholdDate !== null">
          <h5>
            <strong>{{ $t("PRICING.EXPIRY_DATE.TITLE_DESCRIPTION") }}</strong>
          </h5>
          <div>
            <div class="subtitle-1">
              {{ $t("PRICING.EXPIRY_DATE.DESCRIPTION") }}
            </div>
          </div>
          <v-row>
            <v-col lg="6" md="6" sm="12" cols="12">
              <v-autocomplete
                dense
                outlined
                :items="optionsSelect"
                item-text="text"
                item-value="value"
                v-model="data.carryOverProductPricingTemplateId"
                :label="$t('PRICING.EXPIRY_DATE.SELECT_ITEM')"
                :disabled="onlyView"
              />
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <!--end::PeakSeason-->
  </div>
</template>

<script>
import DatePicker from "@/view/content/components/DatePicker";
export default {
  props: {
    onlyView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      panel: false,
      data: {
        carryOverProductPricingTemplateId: null,
        optionsSelect: null,
        checkNotAllow: false,
        allowExpiredBookings: true,
        ruleTypeThresholdDate: null,
      },
    };
  },
  components: {
    DatePicker,
  },
  mounted() {
    this.mountData();
  },
  methods: {
    mountData() {
      this.$emit("data", this.data);
      const { productPricing, productPricings } = this.$store.getters;
      // SET optionsSelect
      this.optionsSelect = productPricings.map(item => {
        let option = {
          value: item.productPricingTemplateId,
          text: item.name,
        };
        return option;
      });
      if (
        productPricing.ruleTypeThresholdDate === "0000-00-00 00:00:00" ||
        productPricing.ruleTypeThresholdDate === null
      ) {
        this.data.ruleTypeThresholdDate = null;
        this.data.allowExpiredBookings = true;
      } else {
        this.data.ruleTypeThresholdDate = productPricing.ruleTypeThresholdDate;
        this.data.allowExpiredBookings = false;
      }
      this.data.carryOverProductPricingTemplateId =
        productPricing.carryOverProductPricingTemplateId;
      if (
        this.data.carryOverProductPricingTemplateId !== null ||
        this.data.carryOverProductPricingTemplateId !== 0
      ) {
        this.panel = true;
      }
    },
    getDate(date) {
      if (date === null) {
        //this.panel = false;
      } else {
        this.data.allowExpiredBookings = false;
        this.data.ruleTypeThresholdDate = date;
        //this.panel = true;
      }
    },
    doesNotExpire() {
      this.data.ruleTypeThresholdDate = null;
      if (!this.panel) {
        this.panel = true;
      }
      this.panel = false;
    },
  },
};
</script>
