<template>
  <div
    :class="[
      parseInt(productPricingTemplateId) === 0 ? 'pricing-new' : 'pricing-edit',
    ]"
  >
    <validation-observer ref="observer">
      <v-card :elevation="withCard ? '2' : '0'">
        <v-row
          align="center"
          justify="end"
          v-if="withHelp"
          class="pt-0 mt-2 pb-0 mb-0"
        >
          <v-col cols="12">
            <v-alert
              v-model="isShowHelp"
              type="info"
              border="left"
              elevation="2"
              colored-border
            >
              {{ helpMessage }}
            </v-alert>
          </v-col>
        </v-row>
        <div :class="withCard ? 'card-body pt-1' : ''">
          <div class="content-form">
            <v-row
              align="center"
              justify="end"
              class="pt-0 mt-0 pb-0 mb-0"
              v-if="withHelp"
            >
              <a
                class="body-2"
                v-if="isShowHelp === false"
                @click="isShowHelp = true"
              >
                {{ $t("GENERAL.SHOW_HELP") }}
              </a>
              <a class="body-2" v-else @click="isShowHelp = false">
                {{ $t("GENERAL.HIDE_HELP") }}
              </a>
            </v-row>
            <!--begin::Body-->
            <v-row
              v-if="displayName || parseInt(boxes) !== 1 || options !== null"
              class="mt-0 pt-0"
            >
              <v-col lg="6" md="6" sm="12" v-if="displayName">
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('PRICING.NEW.FIELD_NAME')"
                  :rules="{
                    required: true,
                  }"
                >
                  <v-text-field
                    v-model="getData.name"
                    :label="$t('PRICING.NEW.FIELD_NAME')"
                    dense
                    outlined
                    :error-messages="errors"
                    :readonly="onlyView"
                  />
                </validation-provider>
              </v-col>
              <!--begin::Select Options-->
              <v-col
                cols="12"
                lg="6"
                md="6"
                sm="12"
                v-show="parseInt(boxes) !== 1 || options !== null"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('PRICING.NEW.OPTIONS')"
                  :rules="{
                    required: true,
                  }"
                >
                  <v-select
                    v-model="options"
                    :items="optionsSelect"
                    :label="$t('PRICING.NEW.OPTIONS')"
                    item-text="title"
                    item-value="value"
                    dense
                    outlined
                    @change="onChange"
                    :error-messages="errors"
                    :readonly="onlyView"
                  ></v-select>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row v-show="parseInt(boxes) === 1 && options === null">
              <v-col cols="12" lg="12" md="12" sm="12">
                <h2 class="v-card__title required-label">
                  {{ $t("PRICING.NEW.OPTIONS") }}
                </h2>
              </v-col>
              <v-col
                lg="4"
                md="4"
                sm="12"
                v-if="availableOptions.indexOf(1) !== -1"
              >
                <NewPricingTemplate types="simple" @setType="options = 1" />
              </v-col>
              <!--begin::Options 2 (flexible)-->
              <v-col
                lg="4"
                md="4"
                sm="12"
                v-if="availableOptions.indexOf(2) !== -1"
              >
                <NewPricingTemplate types="flexible" @setType="options = 2" />
              </v-col>
              <!--begin::Options 3 (fixed)-->
              <v-col
                lg="4"
                md="4"
                sm="12"
                v-if="availableOptions.indexOf(3) !== -1"
              >
                <NewPricingTemplate types="fixed" @setType="options = 3" />
              </v-col>
              <v-col
                lg="4"
                md="4"
                sm="12"
                v-if="availableOptions.indexOf(4) !== -1"
              >
                <NewPricingTemplate types="one" @setType="options = 4" />
              </v-col>
              <v-col
                lg="4"
                md="4"
                sm="12"
                v-if="availableOptions.indexOf(5) !== -1"
              >
                <NewPricingTemplate types="without" @setType="options = 5" />
              </v-col>
              <v-col
                lg="12"
                md="12"
                sm="12"
                v-if="check && !options"
                class="alert-message"
              >
                {{ $t("PRICING.ERROR.SELECT_PRODUCT_PRICE") }}
              </v-col>
            </v-row>
            <!--begin::Options 1::Content-->
            <div v-if="getOptions === 1">
              <SimplePricing
                btnSave="false"
                :name="getData.name"
                @data="dataForm = $event"
                :key="savings"
                ref="SimplePricing"
                :onlyView="onlyView"
              />
            </div>
            <!--begin::Options 2::Content-->
            <div v-if="getOptions === 2">
              <FlexiblePricing
                btnSave="false"
                :name="getData.name"
                @data="dataForm = $event"
                :key="savings"
                ref="FlexiblePricing"
                :onlyView="onlyView"
              />
            </div>
            <!--begin::Options 3::Content-->
            <div v-if="getOptions === 3">
              <FixedPricing
                btnSave="false"
                :name="getData.name"
                @data="dataForm = $event"
                :key="savings"
                ref="FixedPricing"
                :onlyView="onlyView"
              />
            </div>
            <!--begin::Options 4::Content-->
            <div v-if="getOptions === 4">
              <OneTime
                btnSave="false"
                :name="getData.name"
                @data="dataForm = $event"
                :key="savings"
                ref="OneTime"
                :onlyView="onlyView"
              />
            </div>
            <!--begin::Options 4::Content-->
            <div v-if="getOptions === 5">
              <WithoutPricing
                btnSave="false"
                :name="getData.name"
                ref="WithoutPricing"
                :onlyView="onlyView"
              />
            </div>
          </div>
        </div>
      </v-card>
      <!-- PeakSeasonPricing -->
      <div v-if="parseInt(productPricingTemplateId) !== 0 && season !== false">
        <PeakSeasonPricing
          :option="getOptions"
          v-if="getOptions !== 5"
          @data="dataPeak = $event"
          :key="savings"
          :dataForm="dataForm"
          ref="peakSeasonPricing"
          :onlyView="onlyView"
        />
        <!-- ExpiryDate -->
        <ExpiryDate
          @data="dataExpiryDate = $event"
          v-if="productPricingTemplateId !== 0 && expiry !== false"
          ref="expiryDate"
          :onlyView="onlyView"
        />
        <!-- MarketList -->
        <div v-if="productPricingTemplateId !== 0 && market !== false">
          <MarketList :onlyView="onlyView" />
        </div>
      </div>
    </validation-observer>
    <ForceDelete
      typeProduct="PRICING_TEMPLATE"
      :showForceDelete="false"
      :validateServices="validateServices"
      :validateCourses="validateCourses"
      :validateRooms="validateRooms"
      :validatePackages="validatePackages"
      @close-force-delete="cleanDataForceDelete()"
    ></ForceDelete>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import NewPricingTemplate from "@/view/content/components/cards/NewPricingTemplate";
import SimplePricing from "@/view/content/components/forms/SimplePricing";
import FlexiblePricing from "@/view/content/components/forms/FlexiblePricing";
import FixedPricing from "@/view/content/components/forms/FixedPricing";
import WithoutPricing from "@/view/content/components/forms/WithoutPricing";
import OneTime from "@/view/content/components/forms/OneTime";
import PeakSeasonPricing from "@/view/content/components/PeakSeasonPricing";
import ExpiryDate from "@/view/content/components/ExpiryDate";
import MarketList from "@/view/content/components/MarketList";
import ForceDelete from "@/view/pages/productAddon/components/ForceDelete";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import {
  GET_PRODUCT_PRICING,
  GET_TYPE,
  RESET_PRICINGS,
  STORE_PRODUCT_PRICING,
  DELETE_PRODUCT_PRICING,
  UPDATE_PRODUCT_PRICING,
  SET_PRODUCT_PRICING,
} from "@/core/services/store/configure/product_pricing.module";
import { GET_ALL_MARKETS } from "@/core/services/store/market/market.module";

export default {
  components: {
    PeakSeasonPricing,
    ExpiryDate,
    MarketList,
    SimplePricing,
    FlexiblePricing,
    FixedPricing,
    WithoutPricing,
    OneTime,
    ValidationObserver,
    ValidationProvider,
    NewPricingTemplate,
    ForceDelete,
  },
  data() {
    return {
      options: null,
      dataForm: null,
      dataPeak: null,
      dataExpiryDate: null,
      savings: 0,
      errors: [],
      full_loaded: false,
      check: false,
      dataFormPrevious: {},
      optionsPrevious: -1,
      isShowHelp: false,
      validateServices: [],
      validateCourses: [],
      validateRooms: [],
      validatePackages: [],
    };
  },
  props: {
    productPricingTemplateId: {
      type: Number,
      default: 0,
    },
    availableOptions: {
      type: Array,
      default: function() {
        return [1, 2, 3, 4];
      },
    },
    isReady: {
      type: Number,
      default: 0,
    },
    displayName: {
      type: Number,
      default: 0,
    },
    boxes: {
      type: Number,
      default: 0,
    },
    redirect: {
      type: Boolean,
      default: false,
    },
    season: {
      type: Boolean,
      default: false,
    },
    expiry: {
      type: Boolean,
      default: false,
    },
    market: {
      type: Boolean,
      default: false,
    },
    withCard: {
      type: Boolean,
      default: false,
    },
    defaultSelected: {
      type: Number,
      default: null,
    },
    onlyView: {
      type: Boolean,
      default: false,
    },
    isShowMarkets: {
      type: Boolean,
      default: false,
    },
    showMessages: {
      type: Boolean,
      default: true,
    },
    withHelp: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    dataForm: function(to) {
      to.map((productPricing, index) => {
        productPricing.position = index + 1;
        return productPricing;
      });
    },
    dataPeak: function(to) {
      to;
    },
    productPricingTemplateId: {
      handler: function(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          if (
            parseInt(this.productPricingTemplateId) !== 0 &&
            this.productPricingTemplateId !== ""
          ) {
            this.$store.commit("setIsVisibleLoader", true);
            this.$store
              .dispatch(GET_PRODUCT_PRICING, this.productPricingTemplateId)
              .then(data => {
                this.$store.dispatch(
                  GET_TYPE,
                  data.productPricingTemplateRuleTypeId
                );
                this.options = data.productPricingTemplateRuleTypeId;
                this.$store.commit("setIsVisibleLoader", false);
              });
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    isEmpty(obj) {
      for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          return false;
        }
      }
      return JSON.stringify(obj) === JSON.stringify({});
    },
    onChange(value) {
      Vue.set(this.dataFormPrevious, this.optionsPrevious, this.dataForm);
      this.dataForm = [];
      if (this.dataFormPrevious[value]) {
        if (this.full_loaded)
          this.$store.dispatch(RESET_PRICINGS, this.dataFormPrevious[value]);
      } else {
        if (this.full_loaded)
          this.$store.dispatch(RESET_PRICINGS, this.dataForm);
      }
      this.optionsPrevious = value;
      this.$store.dispatch(GET_TYPE, value);
    },
    async storeOrUpdate() {
      let result = false;
      if (parseInt(this.productPricingTemplateId) === 0) {
        result = await this.store();
      } else {
        result = await this.save();
      }
      return result;
    },
    async duplicate() {
      this.$store.commit("setIsVisibleLoader", true);
      let productPrincing = Vue.util.extend({}, this.productPricing);
      productPrincing.name += " (Copy)";
      productPrincing.isReady = 1;
      await this.$store
        .dispatch(STORE_PRODUCT_PRICING, productPrincing)
        .then(({ productPricingTemplateId }) => {
          if (this.redirect !== false) {
            this.$router.push({
              name: "pricing-edit",
              params: { id: productPricingTemplateId },
            });
          }
        })
        .catch();

      this.$bvToast.toast(this.$t("PRICING.DUPLICATED"), {
        title: this.$t("MESSAGE.DELETE.TITLE_NEW"),
        variant: "success",
        solid: true,
      });
      this.$store.commit("setIsVisibleLoader", false);
    },
    async deleteItem() {
      this.$store.commit("setIsVisibleLoader", true);
      this.$store
        .dispatch(
          DELETE_PRODUCT_PRICING,
          this.productPricing.productPricingTemplateId
        )
        .then(response => {
          if (response.success) {
            this.$bvToast.toast(this.$t("MESSAGE.DELETE.DESCRIPTION_NEW"), {
              title: this.$t("MESSAGE.DELETE.TITLE_NEW"),
              variant: "success",
              solid: true,
            });

            setTimeout(() => {
              this.$router.push({ name: "pricing" });
            }, 1500);
          } else {
            this.validateCourses = response.productPricingTemplate.courses;
            this.validateRooms = response.productPricingTemplate.rooms;
            this.validateServices = response.productPricingTemplate.services;
            this.validatePackages = response.productPricingTemplate.packages;
            this.keyValidateDelete += 1;
          }
        });
      this.$store.commit("setIsVisibleLoader", false);
    },
    async validateAndGetData() {
      this.check = true;
      data = await this.$refs.observer.validate();
      if (!data) {
        if (this.showMessages) {
          this.$bvToast.toast(this.$t("MESSAGE.ERROR.DESCRIPTION_NEW"), {
            title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
            variant: "danger",
            solid: true,
          });
        }
        return false;
      }

      let data = [];
      switch (this.getOptions) {
        case 1:
          data = this.$refs.SimplePricing.getDataToStore();
          break;
        case 2:
          data = this.$refs.FlexiblePricing.getDataToStore();
          break;
        case 3:
          data = this.$refs.FixedPricing.getDataToStore();
          break;
        case 4:
          data = this.$refs.OneTime.getDataToStore();
          break;
        case 5:
          data = this.$refs.WithoutPricing.getDataToStore();
          break;
      }
      data;
      if (data.length === 0) {
        if (this.showMessages) {
          this.$bvToast.toast(this.$t("MESSAGE.ERROR.AT_LEAST_ONE_PRICE"), {
            title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
            variant: "danger",
            solid: true,
          });
        }
        return false;
      }

      if (!data) {
        if (this.showMessages) {
          this.$bvToast.toast(this.$t("MESSAGE.ERROR.DESCRIPTION_NEW"), {
            title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
            variant: "danger",
            solid: true,
          });
        }
        return false;
      }

      let result = false;
      if (this.season) {
        result = await this.$refs.peakSeasonPricing.validate();
        if (!result) {
          if (this.showMessages) {
            this.$bvToast.toast(this.$t("MESSAGE.ERROR.DESCRIPTION_NEW"), {
              title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
              variant: "danger",
              solid: true,
            });
          }
          return false;
        }
      }

      if (parseInt(this.productPricingTemplateId) === 0) {
        result = await this.validateStore();
      } else {
        result = await this.validateUpdate();
      }

      //Clean the data that is not necessary
      Vue.delete(result, "list_product_pricings");
      Vue.delete(result, "product_pricing_templates");
      Vue.delete(result, "updated_at");
      Vue.delete(result, "created_at");
      Vue.delete(result, "deleted_at");
      if (result.product_pricings && result.product_pricings.length > 0) {
        result.product_pricings.map(product_pricing => {
          product_pricing.amount = parseFloat(product_pricing.amount);
          return product_pricing;
        });
      }

      if (result.allowExpiredBookings) {
        result.ruleTypeThresholdDate = null;
        result.carryOverProductPricingTemplateId = null;
      }
      result.campusId = this.campus.campusId;
      return result;
    },
    async validateUpdate() {
      Vue;
      let auxEnd, auxStart, isSupplement, supplementAmount, supplementType;
      if (this.expiry) {
        this.productPricing.carryOverProductPricingTemplateId = this.dataExpiryDate.carryOverProductPricingTemplateId;
        this.productPricing.checkNotAllow = this.dataExpiryDate.checkNotAllow;
        this.productPricing.allowExpiredBookings = this.dataExpiryDate.allowExpiredBookings;
        if (this.dataExpiryDate.ruleTypeThresholdDate)
          this.productPricing.ruleTypeThresholdDate = this.dataExpiryDate.ruleTypeThresholdDate;
      }
      this.productPricing.start = null;
      this.productPricing.end = null;
      this.productPricing.isTiered = false;
      if (this.isReady !== "undefined")
        this.productPricing.isReady = this.isReady;
      else this.productPricing.isReady = 1;
      this.productPricing.productPricingTemplateRuleTypeId = this.options;

      let newDataProduct = Vue.util.extend([], this.dataForm);

      if (this.season) {
        this.$refs.peakSeasonPricing.update_emit();

        if (this.dataPeak && this.dataPeak.length > 0) {
          if (this.dataPeak[0].end !== null) {
            this.dataPeak.forEach(item => {
              auxEnd = item.end;
              auxStart = item.start;
              isSupplement = item.isSupplement;
              supplementAmount = item.supplementAmount;
              supplementType = item.supplementType;
              let n_inserted = 0;
              item.list.forEach(off => {
                var productPricingPeak = Vue.util.extend({}, off);
                productPricingPeak.end = auxEnd;
                productPricingPeak.start = auxStart;
                productPricingPeak.productPricingId = null;
                productPricingPeak.position = newDataProduct.length + 1;
                if (isSupplement === 1) productPricingPeak.isSupplement = true;
                else productPricingPeak.isSupplement = false;
                productPricingPeak.supplementAmount = supplementAmount;
                productPricingPeak.supplementType = supplementType;
                if (isSupplement === 1) {
                  productPricingPeak.amountIsPerDuration = null;
                  productPricingPeak.durationTypeId = null;
                  productPricingPeak.minDuration = null;
                  productPricingPeak.maxDuration = null;
                  productPricingPeak.amount = 0;
                }
                //In case of supplement we only need to insert only one.
                if (!(isSupplement === 1 && n_inserted > 0))
                  newDataProduct.push(productPricingPeak);
                n_inserted++;
              });
            });
          }
        }
      }

      let productPricing = Vue.util.extend({}, this.productPricing);
      productPricing.product_pricings = newDataProduct;

      return productPricing;
    },
    async validateStore() {
      let productPricingData = false;
      await this.$refs.observer.validate().then(success => {
        if (success) {
          let data = [];
          switch (this.getOptions) {
            case 1:
              data = this.$refs.SimplePricing.getDataToStore();
              break;
            case 2:
              data = this.$refs.FlexiblePricing.getDataToStore();
              break;
            case 3:
              data = this.$refs.FixedPricing.getDataToStore();
              break;
            case 4:
              data = this.$refs.OneTime.getDataToStore();
              break;
            case 5:
              data = this.$refs.WithoutPricing.getDataToStore();
              break;
          }
          if (data) {
            let productPricing = {
              ...this.productPricing,
              campusId: this.campus.campusId,
              product_pricings: data,
            };
            if (typeof this.isReady !== "undefined")
              productPricing.isReady = this.isReady;
            productPricingData = productPricing;
            return productPricing;
          } else {
            if (this.showMessages) {
              this.$bvToast.toast(this.$t("MESSAGE.ERROR.DESCRIPTION_NEW"), {
                title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
                variant: "danger",
                solid: true,
              });
            }
          }
          return false;
        }
      });
      return productPricingData;
    },
    async store(productPricing) {
      //let productPricing = this.validateStore();
      if (productPricing) {
        this.$store.commit("setIsVisibleLoader", true);

        await this.$store
          .dispatch(STORE_PRODUCT_PRICING, productPricing)
          .then(productPricingTemplate => {
            this.productPricingTemplateId =
              productPricingTemplate.productPricingTemplateId;

            this.$store.commit("setIsVisibleLoader", false);

            this.$store.commit(
              SET_PRODUCT_PRICING,
              Vue.util.extend({}, productPricingTemplate)
            );
            if (this.showMessages) {
              this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
                title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
                variant: "success",
                solid: true,
              });
            }

            if (this.redirect !== false) {
              setTimeout(() => {
                this.$router.push({
                  name: "pricing-edit",
                  params: {
                    id: productPricingTemplate.productPricingTemplateId,
                  },
                });
              }, 1500);
            }
          });
      } else {
        if (this.showMessages) {
          this.$bvToast.toast(this.$t("MESSAGE.ERROR.DESCRIPTION_NEW"), {
            title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
            variant: "danger",
            solid: true,
          });
        }
      }
    },
    async save(productPricing) {
      //let productPricing = this.validateUpdate();
      if (productPricing) {
        this.$store.commit("setIsVisibleLoader", true);
        await this.$store
          .dispatch(UPDATE_PRODUCT_PRICING, productPricing)
          .then(() => {
            if (this.showMessages) {
              this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
                title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
                variant: "success",
                solid: true,
              });
            }
            this.savings = this.savings + 1;
            this.$store.commit("setIsVisibleLoader", false);
          });
      } else {
        return productPricing;
      }
    },
    cleanDataForceDelete() {
      this.validateServices = [];
      this.validateCourses = [];
      this.validateRooms = [];
      this.validatePackages = [];
    },
  },
  computed: {
    ...mapGetters(["productPricing", "campus"]),
    getData() {
      return this.productPricing;
    },
    getOptions() {
      return this.options;
    },
    optionsSelect() {
      let optionsSelect = [
        { value: 1, title: this.$t("PRICING.NEW.SIMPLE.TITLE") },
        { value: 2, title: this.$t("PRICING.NEW.FLEXIBLE.TITLE") },
        { value: 3, title: this.$t("PRICING.NEW.FIXED.TITLE") },
        { value: 4, title: this.$t("PRICING.NEW.ONE_TITTLE") },
        { value: 5, title: this.$t("PRICING.NEW.WITHOUT_TITLE") },
      ];
      if (typeof this.availableOptions !== "undefined") {
        optionsSelect = optionsSelect.filter(option => {
          if (this.availableOptions.includes(option.value)) return true;
          else return false;
        });
      }

      return optionsSelect;
    },
    helpMessage() {
      switch (this.options) {
        case 1:
          return (
            this.$t("PRICING.NEW.SIMPLE.TEXT1") +
            this.$t("PRICING.NEW.SIMPLE.TEXT2")
          );
        case 2:
          return (
            this.$t("PRICING.NEW.FLEXIBLE.TEXT1") +
            this.$t("PRICING.NEW.FLEXIBLE.TEXT2") +
            this.$t("PRICING.NEW.FLEXIBLE.TEXT3")
          );
        case 3:
          return this.$t("PRICING.NEW.FIXED.TEXT1");
        case 4:
          return this.$t("PRICING.NEW.HELP.ONE_TIME");
        default:
          return this.$t("PRICING.NEW.HELP.PLEASE_SELECT_TEMPLATE_TYPE");
      }
    },
  },
  created() {
    this.$store.dispatch(GET_ALL_MARKETS);
  },
  async mounted() {
    if (
      parseInt(this.productPricingTemplateId) !== 0 &&
      this.productPricingTemplateId !== ""
    ) {
      this.$store.commit("setIsVisibleLoader", true);
      await this.$store
        .dispatch(GET_PRODUCT_PRICING, this.productPricingTemplateId)
        .then(async () => {
          const { productPricing } = this.$store.getters;
          await this.$store.dispatch(
            GET_TYPE,
            productPricing.productPricingTemplateRuleTypeId
          );
          if (
            this.availableOptions.includes(
              productPricing.productPricingTemplateRuleTypeId
            )
          )
            this.options = productPricing.productPricingTemplateRuleTypeId;
          else this.options = null;
          this.$store.commit("setIsVisibleLoader", false);
          if (this.season) this.$refs.peakSeasonPricing.mountData();
          if (this.$refs.expiryDate) this.$refs.expiryDate.mountData();
          this.optionsPrevious = this.options;
        });
    }
    if (this.productPricingTemplateId === 0) {
      if (this.displayName === 0) Vue.set(this.productPricing, "name", "-");
      else Vue.set(this.productPricing, "name", "");

      Vue.set(this.productPricing, "productPricingTemplateId", 0);
      Vue.set(this.productPricing, "name", this.productPricing.name);
      Vue.set(this.productPricing, "product_pricings", []);
      Vue.set(this.productPricing, "list_product_pricings", []);
      Vue.set(this.productPricing, "allowExpiredBookings", false);
      Vue.set(this.productPricing, "carryOverProductPricingTemplateId", false);
      Vue.set(this.productPricing, "end", false);
      Vue.set(this.productPricing, "start", false);
      Vue.set(this.productPricing, "isReady", false);
      Vue.set(this.productPricing, "isSupplement", false);
      Vue.set(this.productPricing, "isTiered", false);
      Vue.set(this.productPricing, "parentProductPricingTemplateId", false);
      Vue.set(this.productPricing, "ruleTypeThresholdDate", null);
      Vue.set(this.productPricing, "supplementAmount", false);
      Vue.set(this.productPricing, "supplementType", false);
      Vue.set(this.productPricing, "created_at", false);
      Vue.set(this.productPricing, "updated_at", false);
      Vue.set(this.productPricing, "deleted_at", false);

      this.options = this.defaultSelected;
    }
    this.full_loaded = true;
  },
};
</script>

<style lang="scss" scoped>
.v-card__title {
  margin: 0px;
  padding: 0px;
}
.alert-message {
  color: red;
}
</style>
