<template>
  <v-card class="mb-2">
    <v-card-title>{{ $t("PRODUCTS_ADDONS.TEMPORAL_SETTINGS") }}</v-card-title>
    <v-card-text>
      <v-form :disabled="disableFields">
        <validation-observer ref="SelectTypeDuration">
          <!-- Row Field Name -->
          <v-row class="mb-0 mt-0 pb-0 pt-0">
            <v-col cols="12">
              <ValidationProvider
                v-slot="{ errors }"
                :name="$t('PRODUCTS_ADDONS.DURATION_TYPE')"
                rules="required"
              >
                <v-select
                  v-model="form.typeDuration"
                  dense
                  outlined
                  :error-messages="errors"
                  :label="$t('PRODUCTS_ADDONS.DURATION_TYPE')"
                  required
                  :items="typesDuration"
                  item-value="id"
                  item-text="title"
                  @change="resetForm()"
                >
                </v-select>
              </ValidationProvider>
            </v-col>
          </v-row>
        </validation-observer>
        <validation-observer ref="TypeDuration1">
          <v-row class="mb-0 mt-0 pb-0 pt-0">
            <v-col cols="12">
              <validation-observer ref="multipleDates">
                <v-expand-transition>
                  <div v-show="form.typeDuration === 1">
                    <v-row class="mb-0 mt-0 pb-0 pt-0">
                      <v-col cols="12">
                        <v-divider class="mb-0 mt-0 pb-0 pt-0"></v-divider>
                      </v-col>
                      <v-col cols="12" class="mb-0 mt-0 pb-0 pt-0">
                        <v-row
                          class="mb-0 mt-0 pb-0 pt-0"
                          v-for="(item, index) in form.fixedTypeDuration"
                          :key="index"
                        >
                          <v-col cols="12" md="4">
                            <DatePicker
                              :date="form.fixedTypeDuration[index].startDate"
                              :is-required="true"
                              :name="$t('PRODUCTS_ADDONS.START_DATE')"
                              :input.sync="
                                form.fixedTypeDuration[index].startDate
                              "
                              required
                              @change="differenceTwoDates(index)"
                            />
                          </v-col>
                          <v-col cols="12" md="4">
                            <DatePicker
                              :date="form.fixedTypeDuration[index].endDate"
                              :is-required="true"
                              :date-min="
                                form.fixedTypeDuration[index].startDate
                              "
                              :name="$t('PRODUCTS_ADDONS.END_DATE')"
                              :input.sync="
                                form.fixedTypeDuration[index].endDate
                              "
                              required
                              @change="differenceTwoDates(index)"
                            />
                          </v-col>
                          <v-col cols="12" md="3">
                            <validation-provider
                              v-slot="{ errors }"
                              :name="$t('PRODUCTS_ADDONS.PRICE')"
                              rules="required|numeric"
                            >
                              <v-text-field
                                v-model="item.amount"
                                dense
                                outlined
                                :error-messages="errors"
                                :label="$t('PRODUCTS_ADDONS.PRICE')"
                                required
                                hide-details="auto"
                                type="number"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" md="1">
                            <div
                              v-if="!disableFields"
                              class="content-btn-delete"
                              @click="deleteItemFixed(index)"
                            >
                              <a
                                class="btn btn-icon btn-light btn-hover-danger"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-danger"
                                >
                                  <v-icon color="red">
                                    mdi-delete
                                  </v-icon>
                                </span>
                              </a>
                            </div>
                          </v-col>
                          <v-col cols="12" md="8" class="mt-0 pt-0 mb-0 pb-0">
                            <h5>
                              {{ differenceTwoDates(index) }}
                            </h5>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row justify="end">
                      <v-col cols="12" md="2" align="end">
                        <v-btn
                          v-if="!disableFields"
                          depressed
                          color="primary"
                          @click="addNewFixedDuration"
                        >
                          {{ $t("PRODUCTS_ADDONS.NEW") }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-expand-transition>
              </validation-observer>
            </v-col>
          </v-row>
        </validation-observer>
        <validation-observer ref="TypeDuration2">
          <v-row class="mb-0 mt-0 pb-0 pt-0" v-show="form.typeDuration === 2">
            <v-col cols="4">
              <validation-provider
                vid="minimumDuration"
                v-slot="{ errors }"
                :name="$t('PRODUCTS_ADDONS.MINIMUM_DURATION')"
                :rules="`required|numeric`"
              >
                <v-text-field
                  v-model="form.minimumDuration"
                  dense
                  outlined
                  :error-messages="errors"
                  :label="$t('PRODUCTS_ADDONS.MINIMUM_DURATION')"
                  required
                  hide-details="auto"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="4">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('PRODUCTS_ADDONS.MAXIMUM_DURATION')"
                :rules="{
                  numeric: true,
                  maxValueDuration: { minimumValue: '@minimumDuration' },
                }"
              >
                <v-text-field
                  v-model="form.maximumDuration"
                  dense
                  outlined
                  :error-messages="errors"
                  :label="$t('PRODUCTS_ADDONS.MAXIMUM_DURATION')"
                  hide-details="auto"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="4">
              <ValidationProvider
                v-slot="{ errors }"
                :name="$t('PRODUCTS_ADDONS.DURATION_TYPE')"
                :rules="`required`"
              >
                <v-select
                  v-model="form.typeDurationMinMax"
                  dense
                  outlined
                  :error-messages="errors"
                  :label="$t('PRODUCTS_ADDONS.DURATION_TYPE')"
                  required
                  :items="typesDurationValue"
                  item-value="id"
                  item-text="title"
                >
                </v-select>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row v-show="form.typeDuration === 2" class="mb-2">
            <v-col cols="12">
              <h5>
                <b>{{ $t("PRODUCTS_ADDONS.START_DATES_COURSE") }}</b>
              </h5>
            </v-col>
            <v-col class="m-0 pt-0">
              <v-expand-transition>
                <div v-show="form.typeDuration === 2">
                  <v-radio-group
                    v-model="form.startDateType"
                    row
                    hide-details="auto"
                  >
                    <v-radio
                      :label="$t('PRODUCTS_ADDONS.SCHOOL_CALENDAR')"
                      :value="0"
                    ></v-radio>
                    <v-radio
                      :label="$t('PRODUCTS_ADDONS.CUSTOM_CALENDAR')"
                      :value="1"
                    ></v-radio>
                  </v-radio-group>
                  <v-expand-transition>
                    <v-row class="mb-0" v-show="form.startDateType === 1">
                      <v-col cols="12">
                        <StartDateTemplate
                          ref="startDateTemplate"
                          :startDateTemplate="form.startDateTemplate"
                          v-on:save_start_date_template="
                            getDataStartDate($event)
                          "
                        ></StartDateTemplate>
                      </v-col>
                    </v-row>
                  </v-expand-transition>
                </div>
              </v-expand-transition>
            </v-col>
          </v-row>
          <v-row class="mb-0 mt-0 pb-0 pt-0" v-show="form.typeDuration === 2">
            <v-col cols="12">
              <h5>
                <b>
                  {{ $t("PRODUCTS_ADDONS.WEEKLY_CONTROL_DATES_LABEL") }}
                </b>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                  </template>

                  {{ $t("PRODUCTS_ADDONS.WEEKLY_CONTROL_DATES_HELP") }}
                </v-tooltip>
              </h5>
            </v-col>
            <v-col cols="12" md="6">
              <DatePicker
                :date="form.availabilityStartDate"
                :is-required="false"
                :name="$t('PRODUCTS_ADDONS.START_DATE')"
                :input.sync="form.availabilityStartDate"
                :change="() => this.clearEndDate()"
              />
            </v-col>
            <v-col cols="12" md="6">
              <DatePicker
                :date="form.availabilityEndDate"
                :is-required="false"
                :date-min="form.availabilityStartDate"
                :name="$t('PRODUCTS_ADDONS.END_DATE')"
                :input.sync="form.availabilityEndDate"
              />
            </v-col>
          </v-row>
        </validation-observer>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { mapActions, mapGetters } from "vuex";
import DatePicker from "@/view/content/components/DatePicker";
import StartDateTemplate from "@/view/content/components/forms/StartDateTemplate";
import { DEFAULT_DURATION_TYPE } from "@/core/services/utils/durationTypesFormat";

extend("maxValueDuration", {
  params: ["minimumValue"],
  validate: (value, { minimumValue }) => {
    return Number(value) > Number(minimumValue);
  },
  message: "It must be greater than {minimumValue}",
});
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    DatePicker,
    StartDateTemplate,
  },
  props: {
    editData: {
      type: Object,
      default: function() {
        return {};
      },
    },
    disableFields: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: [],
      isShowDialogDelete: false,
      form: {
        typeDuration: null,
        fixedTypeDuration: [
          {
            startDate: null,
            endDate: null,
            amount: null,
          },
        ],
        availabilityStartDate: null,
        availabilityEndDate: null,
        minimumDuration: null,
        maximumDuration: null,
        typeDurationMinMax: DEFAULT_DURATION_TYPE,
        listOrCustomStartDate: null,
        startDateType: 0,
        startDateTemplate: {},
      },

      // Values to list in form
      typesDuration: [
        { id: 1, title: "Fixed" },
        { id: 2, title: "Variable" },
      ],
      typeDurationFixedValue: [
        { id: 2, title: "Day" },
        { id: 3, title: "Week" },
      ],
      typesDurationValue: [
        { id: 3, title: "Week" },
        { id: 4, title: "Month" },
      ],
      isChangingData: false,
      TYPE_DURATION_VARIABLE: 2,
    };
  },
  mounted() {
    if (this.$route.params.operation === "edit" && this.$route.params.index) {
      this.getCourse(this.$route.params.index).then(response => {
        this.formCourse = response;
        this.formCourse.description = response.product.description;
      });
    }
  },
  computed: {
    ...mapGetters(["getCampus"]),
    maxValueValidationDuration: function() {
      if (this.form.maximumDuration) {
        return `|max_value:${this.form.maximumDuration}`;
      } else {
        return "";
      }
    },
    minValueValidationDuration: function() {
      if (this.form.minimumDuration) {
        return `|min_value:${this.form.minimumDuration}`;
      } else {
        return "";
      }
    },
  },
  methods: {
    ...mapActions(["storeCourse", "updateCourse", "deleteCourse", "getCourse"]),
    addNewFixedDuration() {
      this.$refs.multipleDates.validate().then(data => {
        if (data) {
          this.form.fixedTypeDuration.push({
            startDate: null,
            endDate: null,
            amount: null,
          });
        }
      });
    },
    deleteItemFixed(index) {
      this.form.fixedTypeDuration.splice(index, 1);
    },
    getDataStartDate(startDateTemplate) {
      this.form.startDateTemplate = startDateTemplate;
    },
    async storeForm() {
      this.$refs.observer.validate().then(data => {
        if (data) {
          if (this.$route.params.operation === "new") {
            this.storeCourse(this.formCourse).then(response => {
              this.$router.push("/products/edit/course/" + response);
            });
          } else if (this.$route.params.operation === "edit") {
            this.formCourse.productId = this.$route.params.index;
            this.updateCourse(this.formCourse);
          }
        }
      });
    },
    duplicate() {
      this.$refs.observer.validate().then(data => {
        if (data) {
          this.formCourse.name = `${this.formCourse.name} (copy)`;
          delete this.formCourse.productId;
          this.storeCourse(this.formCourse).then(response => {
            this.$router.push("/products/edit/course/" + response);
          });
        }
      });
    },
    deleteRecord() {
      this.isShowDialogDelete = true;
    },
    removeCourse() {
      this.deleteCourse(this.formCourse).then(() => {
        this.$router.push("/products");
      });
    },
    addDataForm: data => {
      this.formCourse = data;
    },
    differenceTwoDates(index) {
      if (
        this.form.fixedTypeDuration[index].startDate &&
        this.form.fixedTypeDuration[index].endDate
      ) {
        const startDate = this.$moment(
          this.form.fixedTypeDuration[index].startDate
        );
        const endDate = this.$moment(
          this.form.fixedTypeDuration[index].endDate
        );
        let difference = endDate.diff(startDate, "days");
        if (difference < 0) {
          difference = difference * 2;
        }
        if (difference <= 7 && difference >= 0) {
          return `${difference} Days`;
        } else if (difference > 7) {
          const quantityWeeks = parseInt(difference) / 7;
          const quantityWeeksRounded = Math.floor(quantityWeeks);
          const restantDays = difference - quantityWeeksRounded * 7;
          return `${this.$i18n.t(
            "PRODUCTS_ADDONS.DURATION"
          )}: ${quantityWeeksRounded} Weeks and ${restantDays} Days`;
        } else {
          this.form.fixedTypeDuration[index].endDate = null;
        }
      }
    },
    cleanEndDateAvailability() {
      this.form.availabilityEndDate = null;
    },
    resetForm() {
      const typeDuration = this.form.typeDuration;
      this.form = {
        typeDuration: typeDuration,
        fixedTypeDuration: [
          {
            startDate: null,
            endDate: null,
            amount: null,
          },
        ],
        availabilityStartDate: null,
        availabilityEndDate: null,
        minimumDuration: null,
        maximumDuration: null,
        typeDurationMinMax: DEFAULT_DURATION_TYPE,
        listOrCustomStartDate: null,
        startDateType: 0,
        startDateTemplate: {},
      };
      this.$refs.SelectTypeDuration.reset();
      this.$refs.TypeDuration1.reset();
      this.$refs.TypeDuration2.reset();

      if (this.$route.params.operation !== "edit") {
        if (this.form.minimumDuration === null) {
          this.form.minimumDuration = this.getCampus.courseMinDuration;
        }

        if (this.form.maximumDuration === null) {
          this.form.maximumDuration = this.getCampus.courseMaxDuration;
        }

        if (this.form.maximumDuration === null) {
          this.form.maximumDuration = this.getCampus.courseMaxDuration;
        }

        this.form.typeDurationMinMax = this.getCampus.courseDurationTypeId;
      }
    },
    async validateForm() {
      let validateSelectTypeDuration = false;
      let validateTypeDuration1 = false;
      let validateTypeDuration2 = false;

      validateSelectTypeDuration = await this.$refs.SelectTypeDuration.validate();

      if (this.form.typeDuration === 1) {
        validateTypeDuration1 = await this.$refs.TypeDuration1.validate();
        validateTypeDuration2 = true;
      }

      if (this.form.typeDuration === 2) {
        validateTypeDuration2 = await this.$refs.TypeDuration2.validate();
        validateTypeDuration1 = true;
      }

      if (
        validateSelectTypeDuration &&
        validateTypeDuration1 &&
        validateTypeDuration2
      ) {
        return true;
      } else {
        return false;
      }
    },
    validateMinMaxValue(type, value) {
      if (value) {
        if (type === "max") {
          return "max_value:" + value + "|";
        } else return "min_value:" + value + "|";
      } else {
        return "";
      }
    },
    clearEndDate() {
      this.form.availabilityEndDate = null;
    },
  },
  watch: {
    form: {
      handler: function() {
        this.$emit("data", this.form);
      },
      deep: true,
    },
    editData: {
      handler: function() {
        this.isChangingData = true;
        const dataForm = JSON.parse(JSON.stringify(this.editData));
        if (dataForm.edition.length > 0) {
          this.form.fixedTypeDuration = dataForm.edition;
          this.form.typeDuration = 1;
        } else {
          this.form.typeDuration = 2;
          this.form.startDateTemplate = dataForm.product_start_date_template;
          if (dataForm.productStartDateTemplateId === 0) {
            this.form.startDateType = 0;
          } else if (dataForm.productStartDateTemplateId > 0) {
            this.form.startDateType = 1;
          }
        }

        this.form.minimumDuration = dataForm.product.reqMinDurationAmount;
        this.form.maximumDuration = dataForm.product.reqMaxDurationAmount;
        this.form.typeDurationMinMax = dataForm.product.reqMinDurationTypeId;
        this.form.availabilityStartDate = dataForm.product.availabilityStart;
        this.form.availabilityEndDate = dataForm.product.availabilityEnd;
        this.isChangingData = false;
      },
      deep: true,
    },
  },
};
</script>
