<template>
  <div class="simple-pricing-component">
    <b-row>
      <b-col lg="12" md="12" sm="12">
        <v-form ref="form" v-model="valid" lazy-validation>
          <b-form-group>
            <label
              for="input-none"
              :class="[getInfo.length === 0 ? 'span-alert' : '']"
              >{{ $t("PRICING.NEW.SIMPLE_TITLE") }}</label
            >
            <b-row
              v-for="(item, index) in getInfo"
              :key="index"
              style="margin-bottom: 0px"
            >
              <b-col
                lg="8"
                md="12"
                sm="12"
                cols="12"
                class="content-row"
                style="padding-left: 0px;"
              >
                <b-row>
                  <b-col
                    lg="5"
                    md="4"
                    sm="4"
                    cols="10"
                    style="padding-left: 0px;"
                  >
                    <v-text-field
                      v-model.trim="item.amount"
                      :rules="validations.amount"
                      outlined
                      dense
                      type="number"
                      :disabled="onlyView"
                    >
                      <template
                        :slot="campus.before !== 1 ? 'append' : 'prepend-inner'"
                      >
                        {{ campus.symbol }}
                      </template>
                    </v-text-field>
                  </b-col>
                  <div class="content-label-per">Per</div>
                  <b-col lg="5" md="4" sm="4" cols="10">
                    <v-select
                      v-model="item.durationTypeId"
                      :items="options1_mod"
                      :rules="validations.optionRules"
                      placeholder="unit"
                      item-text="text"
                      item-value="value"
                      dense
                      outlined
                      v-if="index === 0"
                      @change="filterUnit(index)"
                      :disabled="onlyView"
                    />
                    <v-select
                      v-model="item.durationTypeId"
                      :items="options2_mod"
                      :rules="validations.optionRules"
                      placeholder="unit"
                      item-text="text"
                      item-value="value"
                      dense
                      outlined
                      v-if="index === 1"
                      @change="filterUnit(index)"
                      :disabled="onlyView"
                    />
                    <v-select
                      v-model="item.durationTypeId"
                      :items="options3_mod"
                      :rules="validations.optionRules"
                      placeholder="unit"
                      item-text="text"
                      item-value="value"
                      dense
                      outlined
                      v-if="index === 2"
                      @change="filterUnit(index)"
                      :disabled="onlyView"
                    />
                  </b-col>
                  <b-col lg="1" md="1" sm="1" cols="2" v-if="!onlyView">
                    <a
                      class="btn btn-icon btn-light btn-hover-danger"
                      @click="deleteItem(index)"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-danger">
                        <v-icon color="red">
                          mdi-delete
                        </v-icon>
                      </span>
                    </a>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form-group>
          <v-btn
            depressed
            color="primary"
            @click="add"
            v-if="pricing_simple.length < 3 && !onlyView"
            >{{ $t("PRICING.NEW.BTN_SIMPLE") }}</v-btn
          >
        </v-form>
      </b-col>
    </b-row>
    <b-row v-if="btnSave === 'true' && !onlyView">
      <div class="d-flex justify-content-end w-100">
        <v-btn depressed color="primary" @click="save">{{
          $t("GENERAL.SAVE")
        }}</v-btn>
      </div>
    </b-row>
  </div>
</template>

<script>
import Vue from "vue";
import { STORE_PRODUCT_PRICING } from "@/core/services/store/configure/product_pricing.module";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      valid: true,
      btnDelete: false,
      valoresDurationType: [
        { value: 2, text: this.$t("GENERAL.DAYS") },
        { value: 3, text: this.$t("GENERAL.WEEKS") },
        { value: 4, text: this.$t("GENERAL.MONTHS") },
      ],
      options1: [
        { value: 2, text: this.$t("GENERAL.DAYS") },
        { value: 3, text: this.$t("GENERAL.WEEKS") },
        { value: 4, text: this.$t("GENERAL.MONTHS") },
      ],
      options2: [
        { value: 2, text: this.$t("GENERAL.DAYS") },
        { value: 3, text: this.$t("GENERAL.WEEKS") },
        { value: 4, text: this.$t("GENERAL.MONTHS") },
      ],
      options3: [
        { value: 2, text: this.$t("GENERAL.DAYS") },
        { value: 3, text: this.$t("GENERAL.WEEKS") },
        { value: 4, text: this.$t("GENERAL.MONTHS") },
      ],
      pricing_simple: [
        {
          amount: 0,
          durationTypeId: 3,
          position: 1,
        },
      ],
      // VALIDATIONS
      validations: {
        valid: true,
        nameRules: [v => !!v || this.$t("PRICING.NEW.FIELD_NAME_ERROR")],
        amount: [v => !!v || this.$t("GENERAL.FIELD_ERROR")],
        optionRules: [v => !!v || this.$t("GENERAL.FIELD_ERROR")],
      },
      newData: {
        amount: null,
        durationTypeId: null,
        productPricingId: null,
      },
    };
  },
  props: {
    btnSave: String,
    name: String,
    dataPeak: Array,
    onlyView: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    pricing_simple: {
      deep: true,
      handler() {
        this.$emit("data", this.pricing_simple);
      },
    },
  },
  methods: {
    add() {
      let newData = {
        ...Vue.util.extend({}, this.newData),
        position: this.pricing_simple.length + 1,
      };

      if (this.pricing_simple.length === 1) {
        if (this.pricing_simple[0].durationTypeId === 2) {
          this.options2.splice(0, 1);
          this.options3.splice(0, 1);
        } else if (this.pricing_simple[0].durationTypeId === 3) {
          this.options2.splice(1, 1);
          this.options3.splice(1, 1);
        } else {
          this.options2.splice(2, 1);
          this.options3.splice(2, 1);
        }
      } else if (this.pricing_simple.length > 1) {
        if (this.pricing_simple[1].durationTypeId === 2) {
          this.options3.splice(0, 1);
        } else if (this.pricing_simple[0].durationTypeId === 3) {
          this.options3.splice(1, 1);
        } else {
          this.options3.splice(2, 1);
        }
      }

      this.pricing_simple.push(newData);
      this.btnDelete = true;
    },
    filterUnit() {},
    deleteItem(item) {
      this.pricing_simple.splice(item, 1);
      if (this.pricing_simple.length === 1) {
        this.btnDelete = false;
      }
    },
    getDataToStore() {
      if (
        !this.$refs.form.validate() ||
        //this.name === "" || TODO: is not necessary for Override Pricing Promotion
        this.pricing_simple.length === 0
      ) {
        return false;
      } else {
        let product_pricings;
        // Convert Data String To Int
        product_pricings = this.pricing_simple.map(item => {
          item.amountIsPerDuration = true;
          item.amount = parseFloat(item.amount);
          return item;
        });
        return Vue.util.extend([], product_pricings);
      }
    },
    save() {
      if (!this.$refs.form.validate() || this.name === "") {
        return false;
      } else {
        let product_pricings, data;
        // Convert Data String To Int
        product_pricings = this.pricing_simple.map(item => {
          item.amountIsPerDuration = true;
          item.amount = parseFloat(item.amount);
          return item;
        });

        data = {
          name: this.name,
          campusId: this.campus.campusId,
          product_pricings: product_pricings,
        };

        this.$store.dispatch(STORE_PRODUCT_PRICING, data).then(() => {
          this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
            title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
            variant: "success",
            solid: true,
          });
        });
      }
    },
  },
  computed: {
    ...mapGetters(["campus", "Currencies"]),
    options1_mod() {
      let valores = Vue.util.extend([], this.valoresDurationType);
      this.pricing_simple.forEach((price, index) => {
        if (index !== 0) {
          valores = valores.filter(valor => {
            if (parseInt(valor.value) === parseInt(price.durationTypeId))
              return false;
            return true;
          });
        }
      });
      return valores;
    },
    options2_mod() {
      let valores = Vue.util.extend([], this.valoresDurationType);
      this.pricing_simple.forEach((price, index) => {
        if (index !== 1) {
          valores = valores.filter(valor => {
            if (parseInt(valor.value) === parseInt(price.durationTypeId))
              return false;
            return true;
          });
        }
      });
      return valores;
    },
    options3_mod() {
      let valores = Vue.util.extend([], this.valoresDurationType);
      this.pricing_simple.forEach((price, index) => {
        if (index !== 2) {
          valores = valores.filter(valor => {
            if (parseInt(valor.value) === parseInt(price.durationTypeId))
              return false;
            return true;
          });
        }
      });
      return valores;
    },
    currency_position() {
      let currencies = this.Currencies.filter(currency => {
        if (currency.currencyId === this.campus.currencyId) return true;
        else return false;
      });
      if (currencies.length > 0) return currencies[0].before;
      else return "";
    },
    currency() {
      let currencies = this.Currencies.filter(currency => {
        if (currency.currencyId === this.campus.currencyId) return true;
        else return false;
      });
      if (currencies.length > 0) return currencies[0].symbol;
      else return "";
    },
    getInfo() {
      return this.pricing_simple;
    },
  },
  mounted() {
    this.$emit("data", this.pricing_simple);
    if (this.dataPeak) {
      this.pricing_simple = this.dataPeak;
      if (this.dataPeak.length > 1) {
        this.btnDelete = true;
      }
    } else {
      const {
        productPricing,
        isEditingProductPricing,
        isType,
      } = this.$store.getters;
      if (productPricing.product_pricings && isEditingProductPricing) {
        if (isType === 1) {
          this.pricing_simple = [];
          let list_durationType = [];
          productPricing.product_pricings.forEach(element => {
            if (element.start === null && element.end === null) {
              if (list_durationType.includes(element.durationTypeId)) {
                element.durationTypeId = null;
              } else {
                list_durationType.push(element.durationTypeId);
              }
              this.pricing_simple.push(element);
            }
          });
          // this.pricing_simple = productPricing.product_pricings;
          this.btnDelete = true;
        }
      }
    }
    if (this.pricing_simple.length === 0) {
      let newData = {
        ...this.newData,
        position: 0,
      };
      this.pricing_simple.push(newData);
    }
  },
};
</script>

<style lang="scss" scoped>
label.span-alert {
  color: red;
}
</style>
