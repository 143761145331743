<template>
  <div>
    <b-row v-if="btnSave === 'true' && !onlyView">
      <div class="d-flex justify-content-end w-100">
        <b-button squared variant="outline-primary" @click="save">
          {{ $t("GENERAL.SAVE") }}
        </b-button>
      </div>
    </b-row>
  </div>
</template>

<script>
import {
  STORE_PRODUCT_PRICING,
  UPDATE_PRODUCT_PRICING,
} from "@/core/services/store/configure/product_pricing.module";

export default {
  props: {
    btnSave: String,
    name: String,
    onlyView: Boolean,
  },
  methods: {
    save() {
      this.$v.$touch();

      if (this.name === "") {
        this.$bvToast.toast(this.$t("MESSAGE.ERROR.DESCRIPTION_NEW"), {
          title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
          variant: "danger",
          solid: true,
        });
      } else {
        let data;

        const {
          currentUser,
          isEditingProductPricing,
          productPricing,
        } = this.$store.getters;

        data = {
          name: this.name,
          campusId: currentUser.campusId,
          product_pricings: null,
        };

        // Edit Data
        if (isEditingProductPricing) {
          data = {
            ...data,
            productPricingTemplateId: productPricing.productPricingTemplateId,
          };

          this.$store.dispatch(UPDATE_PRODUCT_PRICING, data).then(() => {
            this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
              title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
              variant: "success",
              solid: true,
            });
          });
        } else {
          // Create
          this.$store
            .dispatch(STORE_PRODUCT_PRICING, data)
            .then(({ productPricingTemplateId }) => {
              this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
                title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
                variant: "success",
                solid: true,
              });

              setTimeout(() => {
                this.$router.push({
                  name: "pricing-edit",
                  params: { id: productPricingTemplateId },
                });
              }, 1500);
            });
        }
      }
    },
  },
  created() {
    const { isType, isEditingProductPricing } = this.$store.getters;
    if (isEditingProductPricing) {
      if (isType === 4) {
        this.btnDelete = true;
      }
    }
  },
};
</script>
