var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mb-2"},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("PRODUCTS_ADDONS.PRICES")))]),_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-observer',{ref:"observer"},[_c('v-form',{attrs:{"disabled":_vm.disableFields}},[_c('v-row',{staticClass:"pb-0 mb-0 pt-0 mt-0"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.form.typeTariffTemplate),callback:function ($$v) {_vm.$set(_vm.form, "typeTariffTemplate", $$v)},expression:"form.typeTariffTemplate"}},[_c('v-radio',{attrs:{"label":_vm.$t('PRODUCTS_ADDONS.SELECT_TARIFF_TEMPLATE'),"value":1}}),(_vm.create_or_edit_pricing_template)?_c('v-radio',{attrs:{"label":_vm.$t('PRODUCTS_ADDONS.CREATE_NEW_TARIFF'),"value":2}}):_vm._e(),(!_vm.create_or_edit_pricing_template)?_c('v-radio',{attrs:{"label":_vm.$t('PRODUCTS_ADDONS.CUSTOM_FEE_TEMPLATE'),"value":3}}):_vm._e()],1)],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.typeTariffTemplate === 1),expression:"form.typeTariffTemplate === 1"}],staticClass:"pt-0 mt-0 pb-0 mb-0"},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('PRODUCTS_ADDONS.PRICING_TEMPLATE'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-autocomplete',{attrs:{"items":_vm.pricingProducts,"item-text":"name","item-value":"productPricingTemplateId","dense":"","outlined":"","error-messages":errors,"label":_vm.$t('PRODUCTS_ADDONS.PRICING_TEMPLATE'),"required":""},on:{"change":function($event){_vm.keyPricingTemplate += 1}},model:{value:(_vm.form.productPricingTemplateId),callback:function ($$v) {_vm.$set(_vm.form, "productPricingTemplateId", $$v)},expression:"form.productPricingTemplateId"}})]}}])})],1)],1),(
                _vm.form.productPricingTemplateId > 0 &&
                  _vm.form.typeTariffTemplate === 1
              )?_c('v-row',{staticClass:"pt-0 mt-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('PricingTemplate',{key:_vm.keyPricingTemplate,ref:"pricingTemplate",attrs:{"showMessages":false,"productPricingTemplateId":_vm.form.productPricingTemplateId,"availableOptions":[1, 2, 3, 4],"onlyView":true}})],1)],1):_vm._e(),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.typeTariffTemplate === 2),expression:"form.typeTariffTemplate === 2"}]},[_c('v-col',{attrs:{"cols":"12"}},[_c('PricingTemplate',{key:"product-pricing-template",ref:"pricingTemplate",attrs:{"showMessages":false,"productPricingTemplateId":_vm.form.productPricingTemplateId,"availableOptions":[1, 2, 3, 4]}})],1)],1),(
                _vm.form.productPricingTemplateId > 0 &&
                  _vm.form.typeTariffTemplate === 3
              )?_c('v-row',{staticClass:"pt-0 mt-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('PricingTemplate',{key:_vm.keyPricingTemplate,ref:"pricingTemplate",attrs:{"showMessages":false,"productPricingTemplateId":_vm.form.productPricingTemplateId,"availableOptions":[1, 2, 3, 4],"onlyView":false}})],1)],1):_vm._e()],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }