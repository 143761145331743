<template>
  <div class="row-holiday">
    <validation-observer ref="observer_startdate_template">
      <b-row>
        <b-col lg="12" md="12" sm="12">
          <v-form ref="form" style="margin-bottom: -10px">
            <b-row style="margin-bottom: -20px">
              <b-col lg="12" md="12" sm="12" cols="12">
                <v-radio-group
                  v-model="startDateTemplate_modified.isPattern"
                  row
                >
                  <v-radio
                    :label="$t('START_DATE.PATTERN')"
                    :value="1"
                  ></v-radio>
                  <v-radio
                    :label="$t('START_DATE.SPECIFIC')"
                    :value="0"
                  ></v-radio>
                </v-radio-group>
              </b-col>
            </b-row>
            <b-row v-if="isPattern === 1" style="margin-bottom: 0px">
              <b-col lg="6" md="6" sm="6" cols="12">
                <v-radio-group
                  v-model="startDateTemplate_modified.isDaysOfWeek"
                  row
                >
                  <v-radio :label="$t('START_DATE.WEEK')" :value="1"></v-radio>
                  <v-radio :label="$t('START_DATE.MONTH')" :value="0"></v-radio>
                </v-radio-group>
              </b-col>
              <b-col
                lg="12"
                md="12"
                sm="12"
                cols="12"
                class="checkboxes"
                v-if="isDaysOfWeek === 1"
              >
                <v-checkbox
                  v-model="daysOfWeek"
                  :label="$t('TIMETABLE.MONDAY')"
                  value="1"
                ></v-checkbox>
                <v-checkbox
                  v-model="daysOfWeek"
                  :label="$t('TIMETABLE.TUESDAY')"
                  value="2"
                ></v-checkbox>
                <v-checkbox
                  v-model="daysOfWeek"
                  :label="$t('TIMETABLE.WEDNESDAY')"
                  value="3"
                ></v-checkbox>
                <v-checkbox
                  v-model="daysOfWeek"
                  :label="$t('TIMETABLE.THURSDAY')"
                  value="4"
                ></v-checkbox>
                <v-checkbox
                  v-model="daysOfWeek"
                  :label="$t('TIMETABLE.FRIDAY')"
                  value="5"
                ></v-checkbox>
                <v-checkbox
                  v-model="daysOfWeek"
                  :label="$t('TIMETABLE.SATURDAY')"
                  value="6"
                ></v-checkbox>
                <v-checkbox
                  v-model="daysOfWeek"
                  :label="$t('TIMETABLE.SUNDAY')"
                  value="7"
                ></v-checkbox>
              </b-col>
              <b-col
                lg="12"
                md="12"
                sm="12"
                cols="12"
                v-if="isDaysOfWeek === 0"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('START_DATE.DAYS_OF_MONTH')"
                  :rules="{
                    required: true,
                    max: 100,
                    regex_number_commas: /^[0-9]+(,[0-9]+)*$/,
                  }"
                >
                  <v-text-field
                    class="required"
                    style="max-width: 300px"
                    :label="$t('START_DATE.ENTER_DAY_OF_MONTH')"
                    outlined
                    dense
                    v-model="startDateTemplate_modified.daysOfMonth"
                    hide-details="auto"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </b-col>
              <b-col lg="6" md="6" sm="12" cols="12" v-if="isPattern === 1">
                <v-text-field
                  style="max-width: 150px; display: none"
                  :label="$t('START_DATE.REPEAT_FRECUENCY')"
                  outlined
                  dense
                  type="number"
                  v-model="startDateTemplate_modified.repeatDurationAmount"
                  hide-details="auto"
                ></v-text-field>
              </b-col>
            </b-row>
            <b-row class="mb-0" v-if="isPattern === 0">
              <b-col lg="6" md="6" sm="12" cols="12" style="margin-top: 20px">
                <b-row
                  v-for="(manual,
                  index) in startDateTemplate_modified.product_start_date_template_manual_dates"
                  :key="index"
                >
                  <b-col cols="12">
                    <RowStartDate
                      :index="index"
                      :startDate="manual"
                      :contador="contador"
                      v-on:deleteItem="deleteItem(index)"
                      v-on:saveItem="saveItem(index, $event)"
                      ref="row_start_date"
                      :currentdates="
                        startDateTemplate_modified.product_start_date_template_manual_dates
                      "
                    ></RowStartDate>
                  </b-col>
                </b-row>
                <b-row class="mb-0">
                  <b-col>
                    <v-btn color="primary" dark @click.stop="add_date()">
                      {{ $t("START_DATE.ADD_DATE") }}
                    </v-btn>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </v-form>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import RowStartDate from "@/view/content/components/forms/RowStartDate";
import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
  name: "start-date-template",
  data() {
    return {
      menu: false,
      menu_manual_date: false,
      startDateTemplate_modified: {},
      daysOfWeek: [],
      contador: 0,
      errors: [],
    };
  },
  props: {
    startDateTemplate: Object,
  },
  watch: {
    startDateTemplate_modified: {
      handler() {
        this.save_start_date_template();
      },
      deep: true,
    },
    daysOfWeek(to) {
      to;
      this.startDateTemplate_modified.daysOfWeek = Vue.util.extend([], to);
    },
    startDateTemplate: {
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.updateDataProp(this.startDateTemplate);
        }
      },
      deep: false,
    },
  },
  methods: {
    async validate() {
      let response = false;
      let valid_fields = true;
      if (!this.startDateTemplate_modified.isPattern) {
        for (const row of this.$refs.row_start_date) {
          await row.$refs.observer.validate().then(data => {
            if (!data) {
              valid_fields = false;
            }
          });
        }
        if (valid_fields) {
          response = true;
        }
      } else {
        response = true;
      }
      if (this.startDateTemplate_modified.isPattern) {
        if (!this.startDateTemplate_modified.isDaysOfWeek) {
          await this.$refs.observer_startdate_template.validate().then(data => {
            if (!data) {
              response = false;
            }
          });
        }
      }
      return response;
    },
    isEmpty(obj) {
      for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          return false;
        }
      }
      return JSON.stringify(obj) === JSON.stringify({});
    },
    save_start_date_template() {
      if (typeof this.startDateTemplate_modified !== "undefined") {
        //Clean the daysOfWeek and daysOfMonth to maintain the correct format
        var data = Vue.util.extend({}, this.startDateTemplate_modified);
        if (Array.isArray(data.daysOfWeek)) {
          data.daysOfWeek.forEach((day, index) => {
            data.daysOfWeek[index] = parseInt(day);
          });
        } else {
          data.daysOfWeek = [];
        }
        data.daysOfWeek = JSON.stringify(data.daysOfWeek);
        if (Array.isArray(data.daysOfMonth)) {
          data.daysOfMonth.map(day => {
            return parseInt(day);
          });
        } else {
          data.daysOfMonth = data.daysOfMonth.split(",");
          if (data.daysOfMonth.length > 0) {
            const daysOfMonth = [];
            data.daysOfMonth.forEach(day => {
              if (day !== "") {
                daysOfMonth.push(parseInt(day));
              }
            });
            data.daysOfMonth = daysOfMonth;
          } else {
            data.daysOfMonth = [];
          }
        }
        data.daysOfMonth = JSON.stringify(data.daysOfMonth);
        this.$emit("save_start_date_template", data);
      }
    },
    add_date() {
      if (
        !Array.isArray(
          this.startDateTemplate_modified
            .product_start_date_template_manual_dates
        )
      )
        Vue.set(
          this.startDateTemplate_modified,
          "product_start_date_template_manual_dates",
          []
        );
      this.startDateTemplate_modified.product_start_date_template_manual_dates.push(
        {
          startDate: "",
        }
      );
      this.save_start_date_template();
    },
    deleteItem(index) {
      var product_start_date_template_manual_dates = Vue.util.extend(
        [],
        this.startDateTemplate_modified.product_start_date_template_manual_dates
      );
      product_start_date_template_manual_dates.splice(index, 1);
      Vue.set(
        this.startDateTemplate_modified,
        "product_start_date_template_manual_dates",
        product_start_date_template_manual_dates
      );
      this.save_start_date_template();
      this.$forceUpdate();
      this.contador = this.contador + 1;
    },
    saveItem(index, startDate) {
      var date = this.startDateTemplate_modified
        .product_start_date_template_manual_dates[index];
      date.startDate = startDate;
      this.startDateTemplate_modified.product_start_date_template_manual_dates.splice(
        index,
        1,
        date
      );
      this.save_start_date_template();
    },
    updateDataProp(startDateTemplate) {
      var startDateTemplate_modified = Vue.util.extend({}, startDateTemplate);
      if (this.isEmpty(startDateTemplate_modified)) {
        startDateTemplate_modified = {
          productStartDateTemplateId: 0,
          campusId: this.campus.campusId,
          productTypeId: 1,
          isReady: 0,
          isPattern: 1,
          isDaysOfWeek: 1,
          repeatDurationTypeId: 1,
          repeatDurationAmount: 1,
          validFrom: new Date(),
          daysOfWeek: "",
          daysOfMonth: "",
          product_start_date_template_manual_dates: [],
        };
      }
      try {
        this.daysOfWeek = JSON.parse(startDateTemplate_modified.daysOfWeek);
      } catch (e) {
        this.daysOfWeek = [];
      }
      this.daysOfWeek.forEach((week, index) => {
        this.daysOfWeek[index] = week.toString();
      });
      var daysOfMonth = [];
      try {
        daysOfMonth = JSON.parse(startDateTemplate_modified.daysOfMonth);
      } catch (e) {
        daysOfMonth = [];
      }
      if (Array.isArray(daysOfMonth)) {
        daysOfMonth = daysOfMonth.join(",");
      }
      startDateTemplate_modified.daysOfMonth = daysOfMonth;
      this.startDateTemplate_modified = startDateTemplate_modified;
    },
    saveManualDate() {
      this.menu_manual_date = false;
      this.save_start_date_template();
    },
  },
  computed: {
    ...mapGetters(["campus"]),
    isPattern() {
      return this.startDateTemplate_modified.isPattern;
    },
    isDaysOfWeek() {
      return this.startDateTemplate_modified.isDaysOfWeek;
    },
  },
  components: {
    RowStartDate,
    ValidationObserver,
    ValidationProvider,
  },
  mounted() {
    this.updateDataProp(this.startDateTemplate);
  },
};
</script>

<style lang="scss" scoped>
.fields_modified {
  margin-top: 0px;
  .v-input__control {
    .v-messages {
      height: 0px;
      min-height: 0px;
    }
  }
}
.row > div {
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 5px;
}
.checkboxes {
  margin-top: 15px;
  > div {
    display: inline-block;
    width: 130px;
    margin-top: 0px;
    margin-bottom: -15px;
  }
}
</style>
