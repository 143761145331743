<template>
  <v-main>
    <v-row>
      <v-col cols="12">
        <v-btn
          depressed
          color="primary"
          class=""
          @click="isShowDialogNewFee = true"
        >
          {{ `${$t("PRODUCTS_ADDONS.NEW")} ${$t("PRODUCTS_ADDONS.FEE")}` }}
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog :key="key" v-model="isShowDialogNewFee" max-width="700" eager>
      <v-card>
        <v-card-title> {{ $t("FEE.NEW") }} {{ $t("FEE.TITLE") }} </v-card-title>
        <v-card-text>
          <br />
          <v-card>
            <v-card-text>
              <FormFee
                @data="setFormData"
                :formData="formData"
                ref="formFee"
                :type="type"
              ></FormFee>
            </v-card-text>
          </v-card>
          <v-card class="mt-2">
            <v-card-title>
              {{ $t("FEE.PRICINGS") }}
            </v-card-title>
            <v-card-text>
              <FormPricing
                ref="feePricing"
                @data="setFormPricing"
                :pricing="formPricing"
              ></FormPricing>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="grey"
            class="float-right d-inline-block"
            @click="closeFeeForm()"
            >{{ $t("GENERAL.CLOSE") }}</v-btn
          >
          <v-btn
            depressed
            color="primary"
            class="float-right d-inline-block"
            @click="saveFeeExternal()"
            >{{ $t("GENERAL.SAVE") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import { mapActions } from "vuex";

import FormFee from "@/view/content/components/fees/Form";
import FormPricing from "@/view/content/components/fees/FormPricing";
export default {
  components: {
    FormFee,
    FormPricing,
  },
  props: {
    type: Number,
  },
  data() {
    return {
      key: 0,
      isShowDialogNewFee: false,
    };
  },
  methods: {
    ...mapActions(["storeFee"]),
    closeFeeForm() {
      this.$refs.formFee.cleanData();
      this.$refs.feePricing.cleanData();
      this.isShowDialogNewFee = false;
      this.key += 1;
    },
    async saveFeeExternal() {
      const result = await this.$refs.formFee.validateForm();
      const resultPricing = await this.$refs.feePricing.validateForm();
      if (result && resultPricing) {
        const form = this.$refs.formFee.getData();
        const pricing = this.$refs.feePricing.getData();
        const selectedProducts = {
          courses: [],
          packages: [],
          rooms: [],
        };
        this.storeFee({ form, pricing, selectedProducts }).then(() => {
          this.$bvToast.toast(
            this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW").toString(),
            {
              title: this.$t("MESSAGE.SUCCESS.TITLE_NEW").toString(),
              variant: "success",
              solid: true,
            }
          );
          this.closeFeeForm();
        });
      }
    },
  },
};
</script>

<style></style>
