<template>
  <v-main>
    <!-- SKELETON -->
    <v-card v-if="!translations">
      <v-card-title>{{ $t("TRANSLATE.TITLE") }} </v-card-title>
      <v-card-text>
        <div class="maxheight" :style="`max-height:${maxHeight}`">
          <v-skeleton-loader v-bind="attrs" :type="skeletonItems" />
        </div>
      </v-card-text>
    </v-card>
    <!-- LIST OF LANGUAGE -->
    <v-card v-else>
      <v-card-title>
        <span class="d-block w-100">
          {{ $t("TRANSLATE.TITLE") }}
        </span>
        <span class="text-muted font-weight-bold">
          {{ $t("TRANSLATE.SUB_TITLE") }}
        </span>
      </v-card-title>
      <v-card-text v-if="translations.length < 17">
        <div
          class="maxheight text-center d-flex justify-content-center align-items-center h-100 flex-column"
          :style="`min-height:${maxHeight}`"
        >
          <v-progress-circular :size="50" color="primary" indeterminate />
          <div class="text-center">
            <h4 class="primary--text mt-1">
              {{ $t("TRANSLATE.GET") }}
            </h4>
          </div>
        </div>
      </v-card-text>
      <v-card-text v-else>
        <div class="maxheight" :style="`max-height:${maxHeight}`">
          <!-- ITEMS LIST -->
          <v-list-item
            class="reduce-height"
            v-for="(translation, index) in translations"
            :key="translation.languageId"
            link
          >
            <!-- ITEM NAME AND STATUS -->
            <v-list-item-content>
              <span
                class="text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ translation.name }}
              </span>
              <span
                class="text-muted font-weight-bold"
                v-if="translation.automatic"
              >
                {{ $t("TRANSLATE.AUTOMATIC") }}
              </span>
              <span class="text-muted font-weight-bold" v-else>
                {{ $t("TRANSLATE.MANUALLY") }}
              </span>
            </v-list-item-content>
            <!-- ITEM ACTION -->
            <v-list-item-action>
              <v-row>
                <v-col cols="12" md="6">
                  <a
                    class="btn btn-icon btn-light btn-hover-warning"
                    @click="editLanguage(translation, index)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-warning">
                      <v-icon color="orange">
                        mdi-pencil
                      </v-icon>
                    </span>
                  </a>
                </v-col>
              </v-row>
            </v-list-item-action>
          </v-list-item>
        </div>
      </v-card-text>
    </v-card>
    <!-- MODAL EDIT LANGUAGE -->
    <v-dialog
      :key="componentKey"
      v-model="isShowModal"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
      persistent
      v-if="dataEdit.translate"
    >
      <v-card>
        <v-card-title class="p-0">
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="closeModal">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
              {{ dataEdit.name }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="save" v-if="!isOnlyShow">
                {{ $t("GENERAL.SAVE") }}
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="1" cols="12">
              <v-switch
                v-model="dataEdit.automatic"
                :label="$t('TRANSLATE.AUTOMATIC')"
              />
            </v-col>
          </v-row>
          <v-row class="mb-0 pb-0 flex-column">
            <v-col
              cols="12"
              sm="6"
              class="mt-2"
              v-for="(field, index) in fields"
              :key="index"
            >
              <!-- INPUT FIELD -->
              <v-text-field
                v-model="dataEdit.translate[index]"
                :label="field.title"
                dense
                outlined
                :rules="validations.required"
                v-if="field.type === 'name'"
                :disabled="dataEdit.automatic"
              />
              <!-- TEXTAREA FIELD -->
              <v-textarea
                v-else
                v-model="dataEdit.translate[index]"
                :label="field.title"
                dense
                outlined
                :rules="[
                  validations.biggerNumber(dataEdit.translate[index], 300),
                ]"
                :disabled="dataEdit.automatic"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-main>
</template>
<script>
import Vue from "vue";
import Rules from "@/core/services/utils/validations";
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";
const path = "api/v1/translation";
export default {
  props: {
    fields: Object,
    data: {
      type: Array,
      default: () => [],
    },
    model: String,
    skeleton: {
      type: Number,
      default: 8,
    },
    maxHeight: {
      type: String,
      default: "360px",
    },
    isOnlyShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      translations: null,
      isShowModal: false,
      position: null,
      dataEdit: {},
      dataFields: true,
      // VALIDATIONS
      validations: {
        ...Rules,
      },
    };
  },
  methods: {
    /**
     * Open modal and get info for language select
     * @method editLanguage
     * @param language
     * @param index
     * @returns 'dataEdit' with info of the language select
     */
    editLanguage(language, index) {
      this.dataEdit = Vue.util.extend({}, language);
      this.dataFields = Vue.util.extend({}, language.translate);
      this.dataEdit.automatic = Boolean(this.dataEdit.automatic);
      this.position = index;
      this.isShowModal = true;
    },
    /**
     * Close modal and empty data
     * @method closeModal
     */
    closeModal() {
      this.dataEdit = {};
      this.isShowModal = false;
      this.position = null;
    },
    /**
     * Updated data for language selected
     * @method save
     * @return translations updated
     */
    async save() {
      let automatic = this.dataEdit.automatic ? 1 : 0;

      const json = {
        id: this.dataEdit.modelId,
        languageId: this.dataEdit.languageId,
        model: this.model,
        auto: automatic,
        fields: this.dataEdit.translate,
      };

      ApiService.setHeader();
      await ApiService.post(`${path}/translate`, json).then(data => {
        if (data) {
          this.translations[this.position].translate = this.dataEdit.translate;
          this.translations[this.position].automatic = automatic;
          this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
            title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
            variant: "success",
            solid: true,
          });
          setTimeout(() => {
            this.isShowModal = false;
            this.dataEdit = {};
          }, 1500);
        }
      });
    },
    /**
     * Data corresponding to each field
     * @method getInformationFields
     * @param data information of the language
     * @return Object with Clean information
     */
    getInformationFields(data) {
      const auxDataFields = []; // Data corresponding to each field
      this.fields.forEach(element => {
        auxDataFields.push(data[element.type]);
      });
      return auxDataFields;
    },
    /**
     * Get Translations
     * @method getTranslations
     * @param id of the model
     * @param dataFields data of the fields assigned
     * @return translations with all traditions
     */
    getTranslations(id, dataFields) {
      // empty Data
      this.translations = [];
      let auxFields = {};
      dataFields.forEach((element, index) => {
        if (element) {
          auxFields[`${this.fields[index].type}`] = element;
        }
      });
      const json = {
        id: id,
        model: this.model,
        fields: auxFields,
      };

      ApiService.setHeader();
      ApiService.post(`${path}/translate_all`, json).then(({ data }) => {
        const dataTranslations = []; // Information to display in the component
        data?.forEach(element => {
          // Get all the language information filtered by languageId
          let language = this.languages.filter(lang => {
            return lang.languageId === element.languageId;
          });
          // Only if language has data
          if (language[0]) {
            dataTranslations.push({
              languageId: element.languageId,
              code: language[0].code,
              name: language[0].name,
              automatic: element.auto,
              modelId: element.translationable_id,
              translationId: element.translationId,
              translate: this.getInformationFields(element),
            });
          }
        });
        // Set new data
        this.translations = dataTranslations;
      });
    },
    formatData(data) {
      if (data.length) {
        const dataTranslations = []; // Information to display in the component
        data?.forEach(element => {
          // Get all the language information filtered by languageId
          let language = this.languages.filter(lang => {
            return lang.languageId === element.languageId;
          });
          // Only if language has data
          if (language[0]) {
            dataTranslations.push({
              languageId: element.languageId,
              code: language[0].code,
              name: language[0].name,
              automatic: element.auto,
              modelId: element.translationable_id,
              translationId: element.translationId,
              translate: this.getInformationFields(element),
            });
          }
        });
        // Set new data
        this.translations = dataTranslations;
      }
    },
  },
  created() {
    this.formatData(this.data);
  },
  computed: {
    ...mapGetters(["languages"]),
    skeletonItems() {
      let items = "";
      for (let i = 0; i < Number(this.skeleton); i++) {
        items = items + "list-item-two-line,";
      }
      items = items.slice(0, -1);
      return items;
    },
  },
  watch: {
    translations: {
      handler: function(newVal) {
        this.translations = newVal;
      },
      deep: true,
    },
    data: {
      handler: function(newVal) {
        this.formatData(newVal);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.reduce-height {
  height: 20px;
  overflow: hidden;
}
.maxheight {
  overflow: auto;
}
</style>
