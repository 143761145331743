<template>
  <div>
    <validation-observer ref="observerPrice">
      <v-row
        v-for="(pricing, index) in pricings"
        :key="index"
        align="center"
        class="mt-0 mb-0 pt-0 pb-0"
      >
        <v-col cols="12" md="6">
          <validation-provider
            v-slot="{ errors }"
            :name="$t('FEE.PRICING')"
            rules="required|numeric"
          >
            <v-text-field
              v-model="pricings[index].price"
              :label="$i18n.t('FEE.PRICING')"
              dense
              outlined
              :error-messages="errors"
              required
              hide-details="auto"
            ></v-text-field>
          </validation-provider>
        </v-col>
        <v-col cols="12" md="12">
          <validation-provider
            v-slot="{ errors }"
            :name="$t('FEE.TYPE_DURATION')"
            rules=""
          >
            <v-checkbox
              class="mt-0"
              v-model="pricings[index].repeat"
              :label="$i18n.t('FEE.REPEAT')"
              dense
              outlined
              hide-details
              @click="
                pricings[index].quantity = '';
                pricings[index].type_duration = '';
              "
            ></v-checkbox>
          </validation-provider>
        </v-col>
        <v-expand-transition>
          <v-col cols="12" md="3" v-show="pricings[index].repeat">
            <validation-provider
              v-slot="{ errors }"
              :name="$t('FEE.TYPE_DURATION')"
              :rules="pricings[index].repeat ? 'required' : ''"
            >
              <v-select
                v-model="pricings[index].type_duration"
                :label="$i18n.t('FEE.TYPE_DURATION')"
                :items="typeDuration"
                item-text="text"
                item-value="id"
                dense
                outlined
                :error-messages="errors"
                required
              ></v-select>
            </validation-provider>
          </v-col>
        </v-expand-transition>
        <v-expand-transition>
          <v-col cols="12" md="3" v-show="pricings[index].repeat">
            <validation-provider
              v-slot="{ errors }"
              :name="$t('FEE.MAX_REPEAT')"
              :rules="pricings[index].repeat ? 'required' : '' + 'numeric'"
            >
              <v-text-field
                type="number"
                v-model="pricings[index].maxRepeat"
                :label="`${typeDurationSelected}`"
                dense
                outlined
                :error-messages="errors"
                required
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-expand-transition>
      </v-row>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    pricing: {
      type: Array,
      default: function() {
        return [
          {
            price: "",
            repeat: "",
            type_duration: "",
            maxRepeat: "",
          },
        ];
      },
    },
  },

  data() {
    return {
      pricings: [
        {
          price: "",
          repeat: "",
          type_duration: "",
          maxRepeat: "",
        },
      ],
      typeDuration: [
        { text: this.$i18n.t("FEE.WEEK"), id: 3 },
        { text: this.$i18n.t("FEE.MONTH"), id: 4 },
        { text: this.$i18n.t("FEE.YEAR"), id: 7 },
      ],
    };
  },
  computed: {
    typeDurationSelected() {
      if (this.pricings[0].type_duration) {
        const typeDuration = this.typeDuration?.filter(item => {
          return item.id === parseInt(this.pricings[0].type_duration);
        });
        if (typeDuration && typeDuration.length > 0) {
          return this.$t("FEE.MAXIMUM_TYPE_DURATION", {
            typeDuration: typeDuration[0].text,
          });
        } else {
          return this.$t("FEE.MAXIMUM_TO_REPEAT");
        }
      } else {
        return this.$t("FEE.MAXIMUM_TO_REPEAT");
      }
    },
  },
  mounted() {},
  methods: {
    addNewPricing() {
      this.pricings.push({
        price: "",
        repeat: "",
        type_duration: "",
        maxRepeat: "",
      });
    },
    removePricing(index) {
      this.pricings.splice(index, 1);
    },
    async validateForm() {
      return await this.$refs.observerPrice.validate();
    },
    getData() {
      return this.pricings;
    },
    cleanData() {
      this.pricings = [
        {
          price: "",
          repeat: "",
          type_duration: "",
          maxRepeat: "",
        },
      ];
    },
  },
  watch: {
    pricings: {
      handler(newValue) {
        this.$emit("data", newValue);
      },
      deep: true,
    },
    pricing: {
      handler(newValue) {
        this.pricings = newValue;
      },
      deep: true,
    },
  },
};
</script>
