<template>
  <v-card class="mb-2">
    <v-card-title>{{ $t("PRODUCTS_ADDONS.ADDITIONAL_SETTINGS") }}</v-card-title>
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="12" md="12">
          <validation-observer ref="observer">
            <v-form :disabled="disableFields">
              <!-- Row Field Name -->
              <v-row>
                <v-col cols="6">
                  <validation-provider
                    vid="minimumAge"
                    v-slot="{ errors }"
                    :name="$t('PRODUCTS_ADDONS.MINIMUM_AGE')"
                    :rules="{
                      required: true,
                      numeric: true,
                    }"
                  >
                    <v-text-field
                      v-model="form.minimumAge"
                      dense
                      outlined
                      :error-messages="errors"
                      :label="$t('PRODUCTS_ADDONS.MINIMUM_AGE')"
                      required
                      hide-details="auto"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('PRODUCTS_ADDONS.MAXIMUM_AGE')"
                    :rules="{
                      required: false,
                      numeric: true,
                      maxValueAge: { minimumValue: '@minimumAge' },
                    }"
                  >
                    <v-text-field
                      v-model="form.maximumAge"
                      dense
                      outlined
                      :error-messages="errors"
                      :label="$t('PRODUCTS_ADDONS.MAXIMUM_AGE')"
                      hide-details="auto"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row v-if="typeCourse === 1 || typeCourse === '1'">
                <v-col cols="12">
                  <v-card-title>
                    {{ $t("PRODUCTS_ADDONS.NUMBER_OF_STUDENTS") }}
                  </v-card-title>
                </v-col>
                <v-col cols="6">
                  <validation-provider
                    vid="minimumStudents"
                    v-slot="{ errors }"
                    :name="$t('PRODUCTS_ADDONS.MINIMUM_STUDENTS')"
                    :rules="`numeric`"
                  >
                    <v-text-field
                      v-model="form.minimumStudent"
                      dense
                      outlined
                      :error-messages="errors"
                      :label="$t('PRODUCTS_ADDONS.MINIMUM_STUDENTS')"
                      hide-details="auto"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('PRODUCTS_ADDONS.MAXIMUM_STUDENTS')"
                    :rules="{
                      required: true,
                      numeric: true,
                      maxValueStudents: { minimumValue: '@minimumStudents' },
                    }"
                  >
                    <v-text-field
                      v-model="form.maximumStudent"
                      dense
                      outlined
                      :error-messages="errors"
                      :label="$t('PRODUCTS_ADDONS.MAXIMUM_STUDENTS')"
                      required
                      hide-details="auto"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('PRODUCTS_ADDONS.QUANTITY_LESSONS')"
                    rules="required|numeric"
                  >
                    <v-text-field
                      v-model="form.quantityLessons"
                      dense
                      outlined
                      :error-messages="errors"
                      :label="$t('PRODUCTS_ADDONS.QUANTITY_LESSONS')"
                      required
                      hide-details="auto"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('PRODUCTS_ADDONS.DURATION_LESSONS')"
                    rules="required|numeric"
                  >
                    <v-text-field
                      v-model="form.durationLessons"
                      dense
                      outlined
                      :error-messages="errors"
                      :label="$t('PRODUCTS_ADDONS.DURATION_LESSONS')"
                      required
                      hide-details="auto"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('PRODUCTS_ADDONS.MINIMUM_LEVEL_LANGUAGE')"
                    rules="required"
                  >
                    <v-select
                      v-model="form.levelMinimumLanguage"
                      dense
                      outlined
                      :error-messages="errors"
                      :label="$t('PRODUCTS_ADDONS.MINIMUM_LEVEL_LANGUAGE')"
                      required
                      :items="levelLanguageMinimum"
                      item-value="languageLevelId"
                      item-text="code"
                      hide-details="auto"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('PRODUCTS_ADDONS.LEVEL_ASSESSED')"
                    rules="required"
                  >
                    <v-select
                      v-model="form.levelAssessed"
                      dense
                      outlined
                      :error-messages="errors"
                      :label="$t('PRODUCTS_ADDONS.LEVEL_ASSESSED')"
                      :items="levelAssessed"
                    ></v-select>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-form>
          </validation-observer>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="12">
          <validation-provider
            v-slot="{ errors }"
            :name="$t('PRODUCTS_ADDONS.FIRST_DAY')"
            rules="min:50|max:52000"
          >
            <v-textarea
              v-model="form.firstDay"
              :label="$t('PRODUCTS_ADDONS.FIRST_DAY')"
              outlined
              dense
              :error-messages="errors"
              counter="52000"
            ></v-textarea>
          </validation-provider>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { mapActions, mapGetters } from "vuex";

extend("maxValueAge", {
  params: ["minimumValue"],
  validate: (value, { minimumValue }) => {
    return Number(value) > Number(minimumValue);
  },
  message: "It must be greater than {minimumValue}",
});
extend("maxValueStudents", {
  params: ["minimumValue"],
  validate: (value, { minimumValue }) => {
    return Number(value) > Number(minimumValue);
  },
  message: "It must be greater than {minimumValue}",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    editData: {
      type: Object,
      default: function() {
        return {};
      },
    },
    typeCourse: {
      type: Number,
      default: null,
    },
    disableFields: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: [],
      isDisableMaximumAge: false,
      isDisableMinimumStudents: false,
      isDisableMinimumLevelLanguage: false,
      form: {
        minimumAge: null,
        maximumAge: null,
        minimumStudent: null,
        maximumStudent: null,
        quantityLessons: null,
        durationLessons: null,
        cancellationPolicy: null,
        levelMinimumLanguage: null,
        firstDay: null,
        levelAssessed: null,
      },
      levelAssessed: [
        {
          value: 1,
          text: this.$t("PRODUCTS_ADDONS.LEVEL_EVALUATE_1"),
        },
        {
          value: 2,
          text: this.$t("PRODUCTS_ADDONS.LEVEL_EVALUATE_2"),
        },
      ],
      // Values to list in form
      subcategories: [],
    };
  },
  mounted() {
    if (this.$route.params.operation === "edit" && this.$route.params.index) {
      this.getCourse(this.$route.params.index).then(response => {
        this.formCourse = response;
        this.formCourse.description = response.product.description;
      });
    } else {
      this.form.minimumAge = this.getCampus.minAge;
      this.form.maximumAge = this.getCampus.maxAge;
    }
  },
  methods: {
    ...mapActions(["storeCourse", "updateCourse", "deleteCourse", "getCourse"]),
    async storeForm() {
      this.$refs.observer.validate().then(data => {
        if (data) {
          if (this.$route.params.operation === "new") {
            this.storeCourse(this.formCourse).then(response => {
              this.$router.push("/products/edit/course/" + response);
            });
          } else if (this.$route.params.operation === "edit") {
            this.formCourse.productId = this.$route.params.index;
            this.updateCourse(this.formCourse);
          }
        }
      });
    },
    duplicate() {
      this.$refs.observer.validate().then(data => {
        if (data) {
          this.formCourse.name = `${this.formCourse.name} (copy)`;
          delete this.formCourse.productId;
          this.storeCourse(this.formCourse).then(response => {
            this.$router.push("/products/edit/course/" + response);
          });
        }
      });
    },
    deleteRecord() {
      this.isShowDialogDelete = true;
    },
    removeCourse() {
      this.deleteCourse(this.formCourse).then(() => {
        this.$router.push("/products");
      });
    },
    addDataForm: data => {
      this.formCourse = data;
    },
    validateForm() {
      return this.$refs.observer.validate();
    },
  },
  computed: {
    ...mapGetters(["languages", "categories", "englishLevels", "getCampus"]),
    levelLanguageMinimum: function() {
      return this.englishLevels.map(item => {
        const newItem = {
          ...item,
          code: this.$i18n.t(`PRODUCTS_ADDONS.${item.code.toUpperCase()}`),
        };
        return newItem;
      });
    },
    maxValueValidationAge: function() {
      if (this.form.maximumAge) {
        return `|max_value:${this.form.maximumAge}`;
      } else {
        return "";
      }
    },
    minValueValidationAge: function() {
      if (this.form.minimumAge) {
        return `|min_value:${this.form.minimumAge}`;
      } else {
        return "";
      }
    },
    maxValueValidationStudents: function() {
      if (this.form.maximumStudent) {
        return `|max_value:${this.form.maximumStudent}`;
      } else {
        return "";
      }
    },
    minValueValidationStudents: function() {
      if (this.form.minimumStudent) {
        return `|min_value:${this.form.minimumStudent}`;
      } else {
        return "";
      }
    },
  },
  watch: {
    form: {
      handler: function() {
        this.$emit("data", this.form);
      },
      deep: true,
    },
    editData: {
      handler: function() {
        const dataForm = JSON.parse(JSON.stringify(this.editData));
        this.form.minimumAge = dataForm.product.reqMinAge;

        if (dataForm.product.reqMaxAge && dataForm.product.reqMaxAge > 0) {
          this.isDisableMaximumAge = true;
          this.form.maximumAge = dataForm.product.reqMaxAge;
        }

        if (dataForm.product.minQty && dataForm.product.minQty > 0) {
          this.isDisableMinimumStudents = true;
          this.form.minimumStudent = dataForm.product.minQty;
        }

        this.form.maximumStudent = dataForm.product.maxQty;
        this.form.quantityLessons = dataForm.product.lessonQty;
        this.form.durationLessons = dataForm.lessonDuration;
        this.form.firstDay = dataForm.firstDay;
        this.form.levelAssessed = dataForm.levelAssessed;

        this.form.cancellationPolicy = dataForm.product.cancelationDays;

        if (dataForm.reqLevelId && dataForm.reqLevelId > 0) {
          this.isDisableMinimumLevelLanguage = true;
          this.form.levelMinimumLanguage = dataForm.reqLevelId;
        }
      },
      deep: true,
    },
  },
};
</script>
