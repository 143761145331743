<template>
  <div class="fixed-pricing-component">
    <b-row>
      <b-col lg="12" md="12" sm="12">
        <v-form ref="form" v-model="valid" lazy-validation>
          <label v-if="pricing_fixed.length === 0" class="span-alert">{{
            $t("MESSAGE.ERROR.AT_LEAST_ONE_PRICE")
          }}</label>
          <table class="table-content">
            <thead>
              <tr>
                <th>{{ $t("PRICING.NEW.FIXED_TITLE_TABLE_1") }}</th>
                <th>{{ $t("PRICING.NEW.FLEXIBLE_TITLE_TABLE_1") }}</th>
                <th>{{ $t("PRICING.NEW.FIXED_TITLE_TABLE_2") }}</th>
                <th v-if="!onlyView"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in pricing_fixed"
                :key="index"
                class="mb-3 border-row"
              >
                <td>
                  <v-text-field
                    type="number"
                    v-model="item.minDuration"
                    :rules="validations.minDuration"
                    outlined
                    dense
                    :disabled="onlyView"
                  />
                </td>
                <td class="input-unit mr-3">
                  <v-select
                    v-model="item.durationTypeId"
                    :items="options"
                    :rules="validations.optionRules"
                    item-text="text"
                    item-value="value"
                    v-if="index === 0"
                    @change="changeUnit"
                    dense
                    outlined
                    :disabled="onlyView"
                  />
                  <div class="disabled-select">
                    <b-form-select
                      v-model.trim="item.durationTypeId"
                      :options="options"
                      @change="changeUnit"
                      v-if="index >= 1"
                      disabled
                    />
                  </div>
                </td>
                <td>
                  <v-text-field
                    v-model.trim="item.amount"
                    :rules="validations.amount"
                    type="number"
                    outlined
                    dense
                    :disabled="onlyView"
                  >
                    <template
                      :slot="campus.before !== 1 ? 'append' : 'prepend-inner'"
                    >
                      {{ campus.symbol }}
                    </template>
                  </v-text-field>
                </td>
                <td v-if="!onlyView">
                  <a
                    class="btn btn-icon btn-light btn-hover-danger"
                    @click="deleteItem(index)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-danger">
                      <v-icon color="red">
                        mdi-delete
                      </v-icon>
                    </span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <v-btn
            v-if="!onlyView"
            depressed
            color="primary"
            @click="add"
            class="mt-5"
            >{{ $t("PRICING.NEW.BTN_FLEXIBLE") }}</v-btn
          >
        </v-form>
      </b-col>
    </b-row>
    <b-row v-if="btnSave === 'true' && !onlyView">
      <div class="d-flex justify-content-end w-100">
        <v-btn depressed color="primary" @click="save">{{
          $t("GENERAL.SAVE")
        }}</v-btn>
      </div>
    </b-row>
  </div>
</template>

<script>
import { STORE_PRODUCT_PRICING } from "@/core/services/store/configure/product_pricing.module";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      valid: true,
      options: [
        { value: 2, text: this.$t("GENERAL.DAYS") },
        { value: 3, text: this.$t("GENERAL.WEEKS") },
        { value: 4, text: this.$t("GENERAL.MONTHS") },
      ],
      pricing_fixed: [
        {
          amount: 0,
          durationTypeId: 3,
          minDuration: 0,
          position: 1,
        },
      ],
      // VALIDATIONS
      validations: {
        valid: true,
        minDuration: [v => !!v || this.$t("GENERAL.FIELD_ERROR")],
        optionRules: [v => !!v || this.$t("GENERAL.FIELD_ERROR")],
        amount: [v => !!v || this.$t("GENERAL.FIELD_ERROR")],
      },
      newItem: {
        amount: null,
        durationTypeId: null,
        minDuration: null,
        productPricingId: null,
      },
    };
  },
  props: {
    btnSave: String,
    name: String,
    dataPeak: Array,
    onlyView: Boolean,
  },
  watch: {
    pricing_fixed: {
      handler() {
        this.$emit("data", this.pricing_fixed);
      },
      deep: true,
    },
  },
  methods: {
    deleteItem(item) {
      this.pricing_fixed.splice(item, 1);
      this.$emit("data", this.pricing_fixed);
    },
    add() {
      let auxDuration, position, newItem;
      if (this.pricing_fixed.length > 0) {
        position = this.pricing_fixed.length - 1;
        if (this.pricing_fixed[position].minDuration === 0) {
          auxDuration = 0;
        } else {
          auxDuration = parseInt(this.pricing_fixed[position].minDuration) + 1;
        }
        newItem = {
          amount: 0,
          durationTypeId: this.pricing_fixed[0].durationTypeId,
          minDuration: auxDuration,
          position: this.pricing_fixed.length + 1,
          productPricingId: null,
        };
      } else {
        newItem = {
          amount: 0,
          durationTypeId: 3,
          minDuration: 0,
          position: 1,
          productPricingId: null,
        };
      }

      this.pricing_fixed.push(newItem);
    },
    changeUnit() {
      for (let index = 1; index < this.pricing_fixed.length; index++) {
        this.pricing_fixed[
          index
        ].durationTypeId = this.pricing_fixed[0].durationTypeId;
      }
    },
    getDataToStore() {
      if (!this.$refs.form.validate()) {
        if (this.name === "") {
          const input = document.getElementById("nameNew").parentNode.parentNode
            .firstChild;
          input.style.border = "2px solid #FF0202";
        }
        return false;
      } else {
        let product_pricings;
        // Convert Data String To Int
        product_pricings = this.pricing_fixed.map(item => {
          item.amountIsPerDuration = false;
          item.amount = parseFloat(item.amount);
          item.minDuration = parseInt(item.minDuration);
          return item;
        });
        return product_pricings;
      }
    },
    save() {
      if (!this.$refs.form.validate() || this.name === "") {
        if (this.name === "") {
          const input = document.getElementById("nameNew").parentNode.parentNode
            .firstChild;
          input.style.border = "2px solid #FF0202";
        }
      } else {
        const input = document.getElementById("nameNew").parentNode.parentNode
          .firstChild;
        input.style.border = "1px solid black";
        let product_pricings, data;

        // Convert Data String To Int
        product_pricings = this.pricing_fixed.map(item => {
          item.amountIsPerDuration = false;
          item.amount = parseFloat(item.amount);
          item.minDuration = parseInt(item.minDuration);
          return item;
        });

        data = {
          name: this.name,
          campusId: this.campus.campusId,
          product_pricings: product_pricings,
        };

        this.$store
          .dispatch(STORE_PRODUCT_PRICING, data)
          .then(({ productPricingTemplateId }) => {
            this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
              title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
              variant: "success",
              solid: true,
            });

            setTimeout(() => {
              this.$router.push({
                name: "pricing-edit",
                params: { id: productPricingTemplateId },
              });
            }, 1500);
          });
      }
    },
  },
  computed: {
    ...mapGetters(["campus"]),
    getInfo() {
      return this.pricing_fixed;
    },
  },
  created() {},
  mounted() {
    this.$emit("data", this.pricing_fixed);
    if (this.dataPeak) {
      this.pricing_fixed = this.dataPeak;
    } else {
      const {
        productPricing,
        isEditingProductPricing,
        isType,
      } = this.$store.getters;

      if (productPricing.product_pricings && isEditingProductPricing) {
        if (isType === 3) {
          this.pricing_fixed = productPricing.product_pricings.filter(
            product_pricing => {
              if (
                product_pricing.start === null &&
                product_pricing.end === null
              )
                return true;
              else return false;
            }
          );
          let durationTypeId = 0;
          this.pricing_fixed.forEach((product_pricing, index) => {
            if (durationTypeId === 0)
              durationTypeId = product_pricing.durationTypeId;
            this.pricing_fixed.splice(index, 1, {
              ...product_pricing,
              durationTypeId: durationTypeId,
            });
          });
        }
      }
    }
    if (this.pricing_fixed.length === 0) {
      let newData = {
        ...this.newItem,
        position: 0,
      };
      this.pricing_fixed.push(newData);
    }
  },
};
</script>

<style lang="scss" scoped>
label.span-alert {
  color: red;
}
</style>
