<template>
  <div class="MarketsCountriesSelector" :style="cssVars">
    <validation-provider
      v-slot="{ errors }"
      :name="$t('GENERAL.MARKETS_COUNTRY_SELECTOR.LABEL')"
      :rules="{ required: isRequired }"
    >
      <v-select
        :required="isRequired"
        v-model="eligibleBy"
        :items="eligibleOptions"
        :label="label || $t('GENERAL.MARKETS_COUNTRY_SELECTOR.LABEL')"
        item-text="text"
        item-value="value"
        dense
        outlined
        :error-messages="errors"
        @change="save"
      />
    </validation-provider>
    <div v-if="eligibleBy === 'countries'">
      <div class="MarketsCountriesSelector-search">
        <b-form-input
          type="search"
          v-model="search"
          :placeholder="$t('GENERAL.SEARCH')"
          @input="handleSearch"
          :disabled="isOnlyShow"
        ></b-form-input>
      </div>
      <div class="MarketsCountriesSelector-items">
        <validation-provider
          :name="$t('GENERAL.MARKETS_COUNTRY_SELECTOR.COUNTRIES_LABEL')"
          :rules="{ required: true }"
          v-slot="{ errors }"
        >
          <v-treeview
            :error-messages="errors"
            ref="tree"
            dense
            hoverable
            selectable
            open-on-click
            return-object
            transition
            v-model="countriesSelected"
            :items="continentsData"
            :search="search"
            :open.sync="open"
            :class="errors.length ? 'MarketsCountriesSelector-hasErrors' : ''"
            @input="save"
            :item-disabled="isOnlyShow ? 'id' : ''"
          ></v-treeview>
          <div class="v-messages theme--light error--text mt-1">
            {{ errors[0] }}
          </div>
        </validation-provider>
      </div>
    </div>
    <div v-if="eligibleBy === 'markets'">
      <ProductsTreeView
        ref="marketsRef"
        :input.sync="marketsSelected"
        :selection="marketsSelected"
        :data="markets"
        :name="$t('GENERAL.MARKETS_COUNTRY_SELECTOR.MARKETS_LABEL')"
        key-id="marketId"
        is-required
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import { mapValues, reduce, map, sortBy, toString } from "lodash";

import ProductsTreeView from "@/view/content/components/ProductsTreeView";

const allOption = "all";
const countriesOption = "countries";
const marketsOption = "markets";

export default {
  props: {
    label: {
      type: String,
    },
    marketsCountriesData: {
      type: Object,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 300,
    },
    isOnlyShow: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ValidationProvider,
    ProductsTreeView,
  },
  data() {
    return {
      search: "",
      eligibleBy: allOption,
      open: [1],
      allOpened: false,
      lastOpen: [],
      eligibleOptions: [
        {
          text: this.$t(
            "GENERAL.MARKETS_COUNTRY_SELECTOR.OPTIONS.ELIGIBLE_ALL_COUNTRIES"
          ),
          value: allOption,
        },
        {
          text: this.$t(
            "GENERAL.MARKETS_COUNTRY_SELECTOR.OPTIONS.SELECT_BY_COUNTRY"
          ),
          value: countriesOption,
        },
        {
          text: this.$t(
            "GENERAL.MARKETS_COUNTRY_SELECTOR.OPTIONS.SELECT_BY_MARKET"
          ),
          value: marketsOption,
        },
      ],
      countriesSelected: [],
      marketsSelected: [],
      continentsData: [],
      form: {
        isEligibleAllCountries: null,
        eligibleCountries: null,
        eligibleMarkets: null,
      },
    };
  },
  created() {
    this.formatDataContinents();
    this.getAllMarkets();
  },
  mounted() {
    this.fillData();
  },
  computed: {
    ...mapGetters(["Countries", "Continents", "markets"]),
    cssVars() {
      return {
        "--height-tree": this.height + "px",
      };
    },
  },
  methods: {
    ...mapActions(["getAllMarkets"]),
    fillData() {
      if (this.marketsCountriesData) {
        if (
          this.marketsCountriesData.hasOwnProperty("isEligibleAllCountries")
        ) {
          this.eligibleBy = allOption;
          this.save();
        }
        if (this.marketsCountriesData.hasOwnProperty("eligibleCountries")) {
          this.eligibleBy = countriesOption;
          this.countriesSelected = map(
            this.marketsCountriesData.eligibleCountries.split(","),
            data => ({ id: data })
          );
          this.save();
        }
        if (this.marketsCountriesData.hasOwnProperty("eligibleMarkets")) {
          this.eligibleBy = marketsOption;
          this.marketsSelected = map(
            this.marketsCountriesData.eligibleMarkets.split(","),
            data => ({ id: data })
          );
        }
      }
    },
    formatDataContinents() {
      const mapContinentNames = {
        AFRICA: this.$t("MARKETS.TITLE1"),
        ASIA: this.$t("MARKETS.TITLE2"),
        EUROPE: this.$t("MARKETS.TITLE3"),
        N_AMER: this.$t("MARKETS.TITLE4"),
        OCEAN: this.$t("MARKETS.TITLE5"),
        S_AMER: this.$t("MARKETS.TITLE6"),
      };
      this.continentsData = sortBy(
        reduce(
          mapValues(this.Continents),
          (result, value, key) => {
            if (mapContinentNames[key]) {
              result.push({
                id: key,
                name: mapContinentNames[key],
                children: map(value, country => ({
                  id: country.countryId,
                  name: country.nameTranslation,
                })),
              });
            }
            return result;
          },
          []
        ),
        ["name"]
      );
    },
    handleSearch: function(val) {
      if (val) {
        if (!this.allOpened) {
          this.lastOpen = this.open;
          this.allOpened = true;
          this.$refs.tree.updateAll(true);
        }
      } else {
        this.$refs.tree.updateAll(false);
        this.allOpened = false;
        this.open = this.lastOpen;
      }
    },
    save: function() {
      if (this.eligibleBy === allOption) {
        this.form = {
          isEligibleAllCountries: 1,
        };
      }

      if (this.eligibleBy === countriesOption) {
        const eligibleCountries = toString(map(this.countriesSelected, "id"));
        this.form = {
          eligibleCountries,
        };
      }
      if (this.eligibleBy === marketsOption) {
        const eligibleMarkets = toString(map(this.marketsSelected, "id"));
        this.form = {
          eligibleMarkets,
        };
      }
    },
  },
  watch: {
    form: function() {
      this.$emit("update:input", this.form);
    },
    marketsCountriesData: function() {
      this.fillData();
    },
    marketsSelected: function() {
      this.save();
    },
  },
};
</script>

<style lang="scss">
.MarketsCountriesSelector {
  &-search {
    margin-bottom: 10px;
  }
  &-items {
    margin-bottom: 10px;
  }
  &-items {
    min-height: 50px;
    max-height: var(--height-tree);
    overflow-y: scroll;
  }
  &-hasErrors {
    transition: border 0.1s linear;
    margin-top: 3px;
    border: 2px solid red;
    border-radius: 5px;
  }
  .simple-tree .v-treeview-node__children {
    min-height: 130px;
  }
}
</style>
