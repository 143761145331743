var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mb-2"},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("PRODUCTS_ADDONS.TIMETABLE")))]),_c('v-card-text',[_c('v-form',{attrs:{"disabled":_vm.disableFields}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-radio-group',{attrs:{"row":""},on:{"change":function($event){return _vm.validateData()}},model:{value:(_vm.isVisibleSelectTimetable),callback:function ($$v) {_vm.isVisibleSelectTimetable=$$v},expression:"isVisibleSelectTimetable"}},[_c('v-radio',{attrs:{"label":_vm.$t('PRODUCTS_ADDONS.SELECT_TIMETABLE'),"value":"1"}}),_c('v-radio',{attrs:{"label":_vm.$t('PRODUCTS_ADDONS.CUSTOM_TIMETABLE'),"value":"2"}})],1)],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.isVisibleSelectTimetable === 1 || _vm.isVisibleSelectTimetable === '1'
        ),expression:"\n          isVisibleSelectTimetable === 1 || isVisibleSelectTimetable === '1'\n        "}],attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"label":_vm.$t('PRODUCTS_ADDONS.TIMETABLE'),"items":_vm.timeTables,"item-text":"name","item-value":"productTimetableTemplateId","dense":"","outlined":""},model:{value:(_vm.timetableId),callback:function ($$v) {_vm.timetableId=$$v},expression:"timetableId"}})],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(
          (_vm.isVisibleSelectTimetable === 1 ||
            _vm.isVisibleSelectTimetable === '1') &&
            _vm.timetableId > 0
        ),expression:"\n          (isVisibleSelectTimetable === 1 ||\n            isVisibleSelectTimetable === '1') &&\n            timetableId > 0\n        "}]},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('timetable-template',{key:_vm.keyTimetable,ref:"TimetableTemplateRef2",attrs:{"timetableTemplate":_vm.timetableTemplate,"showCourses":"false","onlyView":true},on:{"save_timetable_template":function($event){return _vm.save_timetable_template($event)}}})],1)],1),(
          _vm.isVisibleSelectTimetable === 2 || _vm.isVisibleSelectTimetable === '2'
        )?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('timetable-template',{key:_vm.keyTimetable,ref:"TimetableTemplateRef",attrs:{"timetableTemplate":_vm.timetableTemplate,"showCourses":"false","disableFields":_vm.disableFields},on:{"save_timetable_template":function($event){return _vm.save_timetable_template($event)}}})],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }