<template>
  <div v-if="data.length > 0">
    <b-form-input
      type="search"
      v-model="search"
      :placeholder="$t('GENERAL.SEARCH')"
      @input="handleSearch"
      :disabled="isOnlyShow"
    ></b-form-input>
    <validation-provider
      :name="name"
      :rules="{ required: isRequired }"
      v-slot="{ errors }"
    >
      <v-treeview
        class="simple-tree"
        :class="cssClasses(errors)"
        ref="tree"
        v-model="selection"
        return-object
        selectable
        :items="tree"
        :search="search"
        :open.sync="open"
        open-all="true"
        expand-icon=""
        hoverable
        :style="cssVars"
        item-disabled="lock"
      >
      </v-treeview>
      <div class="v-messages theme--light error--text mt-1">
        {{ errors[0] }}
      </div>
    </validation-provider>
  </div>
  <p v-else class="font-size-lg">No Data for {{ name }}</p>
</template>

<script>
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
export default {
  props: {
    data: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    selection: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    height: {
      type: Number,
      default: 200,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    keyId: {
      type: String,
      default: "productId",
    },
    keyName: {
      type: String,
      default: "name",
    },
    selectAll: {
      type: Boolean,
      default: true,
    },
    isOnlyShow: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ValidationProvider,
  },
  data() {
    return {
      search: "",
      open: [0],
      allOpened: false,
      lastOpen: [],
      tree: [],
    };
  },
  created() {
    this.loadChildren();
  },
  methods: {
    handleSearch: function(val) {
      if (val) {
        if (!this.allOpened) {
          this.lastOpen = this.open;
          this.allOpened = true;
          this.$refs.tree.updateAll(true);
        }
      } else {
        this.$refs.tree.updateAll(false);
        this.allOpened = true;
        this.open = this.lastOpen;
      }
    },
    compare: function(a, b) {
      const bandA = a.name.toUpperCase();
      const bandB = b.name.toUpperCase();

      let comparison = 0;
      if (bandA > bandB) {
        comparison = 1;
      } else if (bandA < bandB) {
        comparison = -1;
      }
      return comparison;
    },
    loadChildren: function() {
      try {
        let dataChildren = [];
        this.data?.map(row => {
          dataChildren.push({ id: row[this.keyId], name: row[this.keyName] });
        });

        dataChildren.sort(this.compare);

        if (this.selectAll) {
          this.tree = [
            {
              id: 0,
              name: this.$t("GENERAL.SELECT_ALL"),
              children: [...dataChildren],
              lock: this.isOnlyShow,
            },
          ];
        } else {
          this.tree = [...dataChildren];
        }
      } catch (e) {
        e;
      }
    },
    cssClasses(errors) {
      let classes = "";

      if (errors.length) {
        classes += "simple-tree-hasErrors ";
      }

      if (!this.selectAll) {
        classes += "simple-tree-withoutSelectAllOption ";
      }

      return classes;
    },
  },
  watch: {
    selection: function() {
      this.$emit("update:input", this.selection);
    },
    data: function() {
      this.loadChildren();
    },
  },
  computed: {
    cssVars() {
      return {
        "--height-tree": this.height + "px",
      };
    },
  },
};
</script>

<style lang="scss">
.simple-tree {
  .v-treeview-node__level {
    margin-right: -24px;
  }

  .v-treeview-node__root {
    border-bottom: 1px solid #ccc;
    padding-left: 0;

    .v-treeview-node__toggle--open {
      display: none;
    }
  }

  .v-treeview-node__children {
    .v-treeview-node__root {
      border-bottom: none;
    }
  }

  .v-treeview-node {
    .v-treeview-node--leaf {
      height: 27px;
    }
  }

  .v-treeview-node__children {
    min-height: 75px;
    max-height: var(--height-tree);
    overflow-y: scroll;
  }
  &.v-treeview--hoverable {
    .v-treeview-node__root {
      &:hover {
        &::before {
          height: 27px;
          top: 10px;
        }
      }
    }
  }
  &.v-treeview {
    .v-treeview-node--click {
      & > .v-treeview-node__root {
        &:hover {
          &::before {
            height: 27px;
            top: 10px;
          }
        }
      }
    }
  }
  &-hasErrors {
    transition: border 0.1s linear;
    margin-top: 3px;
    border: 2px solid red;
    border-radius: 5px;
  }
  &-withoutSelectAllOption {
    min-height: 75px;
    max-height: var(--height-tree);
    overflow-y: scroll;

    .v-treeview-node__root {
      border-bottom: none;
    }

    .v-treeview-node--leaf {
      height: 27px;
    }
  }
}
</style>
