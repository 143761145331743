<template>
  <!--begin::PeakSeason-->
  <div class="peak-season-pricing-component">
    <validation-observer ref="observer">
      <v-card class="mt-6">
        <!--begin::Body-->
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-checkbox
                v-model="seasonExist"
                class="text-black-50"
                :label="$t('PRICING.PEAK_SEASON_PRICING')"
                :disabled="onlyView"
              />
            </v-col>
          </v-row>
          <div v-if="seasonExist">
            <v-card-text>
              <v-row
                :class="`content-peak-form content-peak--${index}`"
                v-for="(item, index) in productPricingOnlySeason"
                :key="index"
              >
                <v-col lg="5" md="5" sm="12">
                  <v-menu
                    v-model="item.menu_start"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('PRICING.FROM')"
                        rules="required"
                      >
                        <v-text-field
                          :value="item.start_formated"
                          clearable
                          outlined
                          dense
                          :label="$t('PRICING.FROM')"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          @click:clear="item.start = null"
                          :error-messages="errors"
                          :disabled="onlyView"
                        ></v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-model="item.start"
                      :first-day-of-week="campus.calendar_format"
                      @change="
                        item.menu_start = false;
                        item.start = $moment(item.start).format('YYYY-MM-DD');
                        item.start_formated = $moment(item.start).format(
                          campus.date_format
                        );
                      "
                      :max="
                        typeof item.end !== 'undefined'
                          ? item.end
                          : '9999-00-00'
                      "
                      no-title
                      :disabled="onlyView"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col lg="5" md="5" sm="12">
                  <v-menu
                    v-model="item.menu_end"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('PRICING.END')"
                        rules="required"
                      >
                        <v-text-field
                          :value="item.end_formated"
                          clearable
                          outlined
                          dense
                          :label="$t('PRICING.END')"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          @click:clear="item.end = null"
                          :error-messages="errors"
                          :disabled="onlyView"
                        ></v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-model="item.end"
                      :first-day-of-week="campus.calendar_format"
                      @change="
                        item.menu_end = false;
                        item.end = $moment(item.end).format('YYYY-MM-DD');
                        item.end_formated = $moment(item.end).format(
                          campus.date_format
                        );
                      "
                      :min="
                        typeof item.start !== 'undefined'
                          ? item.start
                          : '0000-00-00'
                      "
                      no-title
                      :disabled="onlyView"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col lg="2" md="2" sm="12">
                  <div class="content-btn-delete" @click="deleteItem(index)">
                    <a class="btn btn-icon btn-light btn-hover-danger">
                      <span class="svg-icon svg-icon-md svg-icon-danger">
                        <v-icon color="red">
                          mdi-delete
                        </v-icon>
                      </span>
                    </a>
                  </div>
                </v-col>
                <v-col lg="12" md="12" sm="12" cols="12">
                  <v-radio-group
                    v-model="item.isSupplement"
                    row
                    :disabled="onlyView"
                  >
                    <v-radio
                      :label="$t('PRICING.PEAK.ADD_SUPPLEMENT')"
                      value="supplement"
                    />
                    <v-radio
                      :label="$t('PRICING.PEAK.REBUILT_TEMPLATE')"
                      value="rebuilt"
                      @click="insertParentPrices(index)"
                    />
                  </v-radio-group>
                  <span
                    class="span-alert"
                    v-if="
                      errors_radio &&
                        item.isSupplement !== 'supplement' &&
                        item.isSupplement !== 'rebuilt'
                    "
                  >
                    {{ $t("PRICING.SELECT_IS_SUPPLEMENT") }}
                  </span>
                </v-col>
                <v-col
                  lg="12"
                  md="12"
                  sm="12"
                  v-if="getOption === 1 && item.isSupplement === 'rebuilt'"
                >
                  <SimplePricing
                    :dataPeak="item.list"
                    btnSave="false"
                    :name="productPricing.name"
                    ref="SimplePricing"
                    :onlyView="onlyView"
                  />
                </v-col>
                <!--begin::Options 2::Content-->
                <v-col
                  lg="12"
                  md="12"
                  sm="12"
                  v-if="getOption === 2 && item.isSupplement === 'rebuilt'"
                >
                  <FlexiblePricing
                    :dataPeak="item.list"
                    btnSave="false"
                    :name="productPricing.name"
                    ref="FlexiblePricing"
                    :onlyView="onlyView"
                  />
                </v-col>
                <!--begin::Options 3::Content-->
                <v-col
                  lg="12"
                  md="12"
                  sm="12"
                  v-if="getOption === 3 && item.isSupplement === 'rebuilt'"
                >
                  <FixedPricing
                    btnSave="false"
                    :name="productPricing.name"
                    :dataPeak="item.list"
                    ref="FixedPricing"
                    :onlyView="onlyView"
                  />
                </v-col>
                <!--begin::Options 4::Content-->
                <v-col
                  lg="12"
                  md="12"
                  sm="12"
                  v-if="getOption === 4 && item.isSupplement === 'rebuilt'"
                >
                  <OneTime
                    btnSave="false"
                    :name="productPricing.name"
                    :dataPeak="item.list"
                    ref="OneTime"
                    :onlyView="onlyView"
                  />
                </v-col>
                <v-row v-if="item.isSupplement === 'supplement'">
                  <v-col lg="4" md="4" sm="12">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('GENERAL.AMOUNT')"
                      rules="required"
                    >
                      <v-text-field
                        v-model="item.supplementAmount"
                        :label="$t('GENERAL.AMOUNT')"
                        dense
                        outlined
                        type="number"
                        :error-messages="errors"
                        :readonly="onlyView"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col lg="4" md="4" sm="12">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('GENERAL.TYPE')"
                      rules="required"
                    >
                      <v-select
                        v-model="item.supplementType"
                        :items="optionSelect"
                        :label="$t('GENERAL.TYPE')"
                        item-text="text"
                        item-value="value"
                        dense
                        outlined
                        :error-messages="errors"
                        :readonly="onlyView"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-row>
            </v-card-text>
            <div v-if="!onlyView">
              <v-btn depressed color="primary" @click="add">{{
                $t("PRICING.PEAK.ADD")
              }}</v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </validation-observer>
  </div>
  <!--end::PeakSeason-->
</template>

<script>
import Vue from "vue";
import SimplePricing from "@/view/content/components/forms/SimplePricing";
import FlexiblePricing from "@/view/content/components/forms/FlexiblePricing";
import FixedPricing from "@/view/content/components/forms/FixedPricing";
import OneTime from "@/view/content/components/forms/OneTime";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      seasonExist: null,
      productPricingOnlySeason: [],
      optionSelect: [
        { text: "%", value: 1 },
        { text: "$", value: 2 },
      ],
      errors: [],
      errors_radio: false,
    };
  },
  components: {
    SimplePricing,
    FlexiblePricing,
    FixedPricing,
    OneTime,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    dataForm: Array,
    option: Number,
  },
  methods: {
    cancel() {
      this.seasonExist = false;
    },
    insertParentPrices(index) {
      this.productPricingOnlySeason.forEach((season, ind) => {
        if (index === ind) {
          this.productPricingOnlySeason[ind].list = JSON.parse(
            JSON.stringify(this.dataForm)
          );
        }
      });
    },
    add() {
      const addNew = [
        {
          productPricingId: null,
          amount: 0,
          maxDuration: 0,
          minDuration: 0,
          durationTypeId: 3,
          productPricingTemplateId: null,
          position: 0,
        },
      ];

      let isSupplement = false;
      if (this.productPricingOnlySeason.length > 0) isSupplement = true;
      const newDataPeak = {
        end: null,
        start: null,
        list: addNew,
        isSupplement: isSupplement,
      };
      this.productPricingOnlySeason.push(newDataPeak);
      window.scrollBy({
        top: 200,
        left: 0,
        behavior: "smooth",
      });
    },
    deleteItem(item) {
      this.productPricingOnlySeason.splice(item, 1);
    },
    changeFrom(index) {
      const end = new Date(this.productPricingOnlySeason[index].end);
      const start = new Date(this.productPricingOnlySeason[index].start);
      const endDay = end.getDay();
      const endMon = end.getMonth();
      const startDay = start.getDay();
      const startMon = start.getMonth();
      if (startMon > endMon) {
        this.productPricingOnlySeason[index].end = null;
      }
      if (startDay > endDay) {
        this.productPricingOnlySeason[index].end = null;
      }
    },
    async validate() {
      let result = false;
      this.errors_radio = true;
      await this.$refs.observer.validate().then(async data => {
        if (data) {
          if (this.seasonExist) {
            result = true;
            let selected = true;
            let pricings = true;
            this.productPricingOnlySeason.forEach(price => {
              if (
                price.isSupplement !== "rebuilt" &&
                price.isSupplement !== "supplement"
              )
                selected = false;
              if (price.isSupplement === "rebuilt") {
                let data = true;
                switch (this.getOption) {
                  case 1:
                    this.$refs.SimplePricing.forEach(SimplePricing => {
                      let prices_obtained = SimplePricing.getDataToStore();
                      if (!SimplePricing.getDataToStore()) data = false;
                      else {
                        if (prices_obtained.length === 0) data = false;
                      }
                    });
                    break;
                  case 2:
                    this.$refs.FlexiblePricing.forEach(FlexiblePricing => {
                      let prices_obtained = FlexiblePricing.getDataToStore();
                      if (!FlexiblePricing.getDataToStore()) data = false;
                      else {
                        if (prices_obtained.length === 0) data = false;
                      }
                    });
                    break;
                  case 3:
                    this.$refs.FixedPricing.forEach(FixedPricing => {
                      let prices_obtained = FixedPricing.getDataToStore();
                      if (!FixedPricing.getDataToStore()) data = false;
                      else {
                        if (prices_obtained.length === 0) data = false;
                      }
                    });
                    break;
                  case 4:
                    this.$refs.OneTime.forEach(OneTime => {
                      let prices_obtained = OneTime.getDataToStore();
                      if (!OneTime.getDataToStore()) data = false;
                      else {
                        if (prices_obtained.length === 0) data = false;
                      }
                    });
                    break;
                  case 5:
                    this.$refs.WithoutPricing.forEach(WithoutPricing => {
                      let prices_obtained = WithoutPricing.getDataToStore();
                      if (!WithoutPricing.getDataToStore()) data = false;
                      else {
                        if (prices_obtained.length === 0) data = false;
                      }
                    });
                    break;
                }
                if (!data) {
                  pricings = false;
                  result = false;
                  return false;
                }
              } else {
                if (price.supplementAmount === "" || !price.supplementType) {
                  result = false;
                  return false;
                }
              }
            });
            if (!selected) {
              result = false;
              return false;
            }
            if (!pricings) {
              result = false;
              return false;
            }

            if (!data) {
              result = false;
              return false;
            }
          } else {
            result = true;
          }
        } else {
          result = false;
          return false;
        }
      });

      return result;
    },
    update_emit() {
      if (this.seasonExist) {
        let productPricings = [];
        this.productPricingOnlySeason.forEach(productPricing => {
          let isSupplement = 0;
          if (productPricing.isSupplement === "supplement") {
            isSupplement = 1;
          } else {
            isSupplement = 0;
          }
          productPricings.push({
            ...productPricing,
            isSupplement: isSupplement,
          });
        });
        this.$emit("data", productPricings);
      } else this.$emit("data", []);
    },
    mountData() {
      //this.option = this.productPricing.type;

      let productPricingOnlySeason = [];
      if (typeof this.productPricing.list_product_pricings !== "undefined") {
        this.productPricing.list_product_pricings.forEach(product_pricing => {
          if (
            !(
              (product_pricing.end === "" || product_pricing.end === null) &&
              (product_pricing.start === "" || product_pricing.start === null)
            )
          ) {
            let isSupplement = "rebuilt";
            if (product_pricing.isSupplement) isSupplement = "supplement";
            product_pricing.start = this.$moment(product_pricing.start).format(
              "YYYY-MM-DD"
            );
            product_pricing.start_formated = this.$moment(
              product_pricing.start
            ).format("DD/MM/YYYY");
            product_pricing.end = this.$moment(product_pricing.end).format(
              "YYYY-MM-DD"
            );
            product_pricing.end_formated = this.$moment(
              product_pricing.end
            ).format("DD/MM/YYYY");
            productPricingOnlySeason.push({
              ...Vue.util.extend({}, product_pricing),
              isSupplement: isSupplement,
            });
          }
        });
      }
      if (productPricingOnlySeason.length > 0) {
        this.seasonExist = true;
      }
      this.update_emit();
      this.productPricingOnlySeason = productPricingOnlySeason;
    },
  },
  computed: {
    ...mapGetters(["productPricing", "campus"]),
    getOption() {
      return this.option;
    },
  },
  mounted() {
    this.mountData();
  },
  watch: {
    productPricingOnlySeason: {
      handler() {
        this.update_emit();
      },
      deep: true,
    },
    seasonExist: {
      handler() {
        this.update_emit();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.span-alert {
  color: rgb(255, 2, 2);
}
</style>
