<template>
  <div class="row-timetable">
    <validation-observer ref="observer_timetable">
      <b-row style="margin-bottom: 0px;">
        <b-col lg="12" md="12" sm="12" class="class_time">
          <div>
            <validation-provider
              v-slot="{ errors }"
              :name="$t('CALENDAR.START_TIME_NAME')"
              :rules="{
                required: true,
                time: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
                time_lower_than: time.end,
                time_greater_than_previous: previous.end,
              }"
            >
              <v-text-field
                v-model="time.start"
                placeholder="HH:MM"
                :label="$t('CALENDAR.START_TIME_NAME')"
                class="time_text required"
                clearable
                :error-messages="errors"
                v-mask="'##:##'"
                :readonly="onlyView"
              ></v-text-field>
            </validation-provider>
          </div>
          <span class="guion">—</span>
          <div>
            <validation-provider
              v-slot="{ errors }"
              :name="$t('CALENDAR.END_TIME_NAME')"
              :rules="{
                time: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
                required,
              }"
            >
              <v-text-field
                v-model="time.end"
                placeholder="HH:MM"
                :label="$t('CALENDAR.END_TIME_NAME')"
                class="time_text required"
                clearable
                :error-messages="errors"
                v-mask="'##:##'"
                :readonly="onlyView"
              ></v-text-field>
            </validation-provider>
          </div>
          <span class="guion"></span>
          <a
            class="btn btn-icon btn-light btn-hover-danger btn-delete-timetable"
            @click="deleteRow"
            v-if="!onlyView"
          >
            <span class="svg-icon svg-icon-md svg-icon-danger">
              <v-icon color="red">
                mdi-delete
              </v-icon>
            </span>
          </a>
          <v-btn
            v-if="show_btn_add && !onlyView"
            depressed
            color="primary"
            @click="$emit('addTimetableItem', index)"
            class="btn-add"
            >{{ $t("GENERAL.ADD") }}</v-btn
          >
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";

//import Vue from "vue";
export default {
  name: "row-timetable",
  data() {
    return {
      menu: false,
      menu2: false,
      errors: [],
    };
  },
  props: {
    show_btn_add: Boolean,
    index: Number,
    time: Object,
    previous: Object,
    onlyView: Boolean,
  },
  methods: {
    deleteRow() {
      this.$emit("delete");
    },
    empty() {
      this.time.start = null;
      this.time.end = null;
    },
    async validation() {
      let data = await this.$refs.observer_timetable.validate();
      return data;
    },
  },
  computed: {
    startDateRow() {
      return this.startDate;
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mounted() {
    //var startDateRow = Vue.util.extend({}, this.startDate);
    //this.startDateRow = startDateRow;
  },
};
</script>

<style lang="scss" scoped>
.row-timetable {
  .class_time {
    margin-top: -15px;
    > div {
      display: inline-block;
      @media (max-width: 550px) {
        width: 120px;
      }
    }
    .time_text {
      width: 100px;
      display: inline-block;
      margin-right: 20px;
      margin-top: 0px;
      @media (max-width: 1080px) {
        width: 120px;
      }
      @media (max-width: 550px) {
        width: 90%;
      }
      .v-input__prepend-outer {
        display: none !important;
        height: 1px;
        min-height: 1px;
      }
      .v-input__icon {
        display: none !important;
        i {
          display: none !important;
        }
      }
      .v-input__icon--prepend {
        display: none !important;
        i {
          display: none !important;
        }
      }
      .v-text-field__details {
        position: absolute;
        margin-top: 41px;
        width: calc(100% - 180px);
      }
    }
  }
}

.error--text {
  .v-messages__message {
    height: 66px;
  }
}
.v-input__icon--clear {
  margin-top: -10px !important;
}
.guion {
  margin: 0px 20px 0px 0px;
  @media (max-width: 800px) {
    display: none;
  }
}

.btn-delete-timetable {
  margin-right: 10px;
}

.time_text {
}
</style>
