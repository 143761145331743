<template>
  <div class="row-start-date">
    <b-row style="margin-bottom: 0px;">
      <b-col lg="11" md="6" sm="6">
        <validation-observer ref="observer">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
            style="display: unset;"
          >
            <template v-slot:activator="{ on, attrs }">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('CALENDAR.START_DATE_NAME')"
                rules="required"
              >
                <v-text-field
                  class="required"
                  outlined
                  dense
                  v-model="startDateRow.startDate"
                  :label="$t('CALENDAR.SELECT_START_DATE')"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hide-details="auto"
                  :error-messages="errors"
                  style="margin-top: 0px !important;"
                ></v-text-field>
              </validation-provider>
            </template>
            <v-date-picker
              v-model="startDateRow.startDate"
              @input="
                menu = false;
                save();
              "
              :allowedDates="val => startDateModified.indexOf(val) === -1"
            ></v-date-picker>
          </v-menu>
        </validation-observer>
      </b-col>
      <b-col lg="1" md="1" sm="1">
        <a
          class="btn btn-icon btn-light btn-hover-danger"
          @click="deleteItem()"
        >
          <span class="svg-icon svg-icon-md svg-icon-danger">
            <v-icon color="red">
              mdi-delete
            </v-icon>
          </span>
        </a>
      </b-col>
    </b-row>
  </div>
</template>

<script>
//import Vue from "vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
  name: "row-start-date",
  data() {
    return {
      menu: false,
      errors: [],
      el: ["2020-10-03", "2020-10-05"],
    };
  },
  props: {
    index: Number,
    startDate: Object,
    currentdates: Array,
  },
  methods: {
    deleteItem() {
      this.$emit("deleteItem");
    },
    save() {
      this.$emit("saveItem", this.startDateRow.startDate);
    },
  },
  computed: {
    startDateRow() {
      return this.startDate;
    },
    startDateModified() {
      return this.currentdates.map(startDate => {
        return startDate.startDate;
      });
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mounted() {
    //var startDateRow = Vue.util.extend({}, this.startDate);
    //this.startDateRow = startDateRow;
  },
};
</script>

<style lang="scss" scoped></style>
