<template>
  <div class="timetable_template">
    <validation-observer ref="observer">
      <b-row>
        <b-col :lg="showCourses ? 12 : 8" :md="showCourses ? 12 : 8" sm="12">
          <v-form ref="form" :disabled="disableFields">
            <b-row v-if="showName">
              <b-col lg="12" md="12" sm="12" cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('TIMETABLE.NAME')"
                  rules="required"
                >
                  <v-text-field
                    class="required"
                    style="max-width: 300px"
                    :label="$t('TIMETABLE.NAME')"
                    outlined
                    dense
                    v-model="timetableTemplate_modified.name"
                    hide-details="auto"
                    :error-messages="errors"
                    :readonly="onlyView"
                  ></v-text-field>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12" md="12" sm="12" cols="12">
                <h6>
                  <i>
                    {{ $t("TIMETABLE.ENTER_START_END_OF_DAY") }}
                  </i>
                </h6>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12" md="12" sm="12" cols="12">
                <v-tabs
                  left
                  v-model="tab"
                  @change="onTabChange"
                  align-with-title
                  hide-slider
                  show-arrows="false"
                  class="tab-timetable"
                >
                  <v-tab v-for="(weekDay, index) in weekDays" :key="index">
                    <div
                      :class="[
                        'weeklabels',
                        timetable[index].length !== 0 ? 'have_some' : '',
                      ]"
                    >
                      <span>{{ weekDays[Number(index)] }}</span
                      ><span>{{ weekDaysCompressed[Number(index)] }}</span>
                    </div>
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab" class="mt-5 mr-5 ml-5">
                  <v-tab-item v-for="(weekDay, index) in weekDays" :key="index">
                    <span
                      v-for="(time, index_time) in timetable[index]"
                      :key="index_time"
                    >
                      <RowTimetable
                        :time="time"
                        :index="index"
                        :ref="'rows'"
                        @delete="deleteTimetableItem(index, index_time)"
                        :previous="
                          index_time > 0 ? timetable[index][index_time - 1] : ''
                        "
                        @addTimetableItem="addTimetableItem(index)"
                        :show_btn_add="
                          timetable[index].length - 1 === index_time
                            ? true
                            : false
                        "
                        :onlyView="onlyView"
                      ></RowTimetable>
                    </span>
                    <v-btn
                      v-if="timetable[index].length === 0 && !onlyView"
                      :disabled="disableFields"
                      depressed
                      color="primary"
                      @click="addTimetableItem(index)"
                      class="btn-add"
                      >{{ $t("GENERAL.ADD_NEW") }}</v-btn
                    >
                    <v-btn
                      v-if="
                        index !== 0 &&
                          timetable[index].length === 0 &&
                          timetable[index - 1].length > 0 &&
                          !onlyView
                      "
                      depressed
                      color="default"
                      @click="copyPreviousDay(index)"
                      class="btn-copy"
                      >{{ $t("TIMETABLE.COPY_PREVIOUS_DAY") }}</v-btn
                    >
                    <v-btn
                      v-if="
                        index !== 6 &&
                          timetable[index].length > 0 &&
                          timetable[index][0].start !== '' &&
                          timetable[index][0].end !== '' &&
                          !onlyView
                      "
                      depressed
                      color="default"
                      @click="next()"
                      class="btn-copy"
                      >{{ $t("GENERAL.NEXT") }}</v-btn
                    >
                  </v-tab-item>
                </v-tabs-items>
              </b-col>
            </b-row>
          </v-form>
        </b-col>
        <b-col lg="4" md="4" sm="12" v-if="showCourses !== 'false'">
          <h4>{{ $t("TIMETABLE.COURSES_USING_TIMETABLE") }}</h4>
          <div
            class="course"
            v-for="(course, index) in coursesRelated"
            :key="index"
          >
            <router-link
              style="font-size: 14px"
              :to="'/products/edit/course/' + course.productId"
              >{{ course.name }}</router-link
            >
          </div>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import Vue from "vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import RowTimetable from "@/view/content/components/forms/RowTimetable";
import { GET_COURSES } from "@/core/services/store/productsAddons/course.module";
import { mapGetters } from "vuex";

export default {
  name: "timetable-template",
  data() {
    return {
      weekDays: [
        this.$t("TIMETABLE.MONDAY"),
        this.$t("TIMETABLE.TUESDAY"),
        this.$t("TIMETABLE.WEDNESDAY"),
        this.$t("TIMETABLE.THURSDAY"),
        this.$t("TIMETABLE.FRIDAY"),
        this.$t("TIMETABLE.SATURDAY"),
        this.$t("TIMETABLE.SUNDAY"),
      ],
      weekDaysCompressed: ["M", "T", "W", "T", "F", "S", "S"],
      timetableTemplate_modified: {},
      courses: [],
      errors: [],
      timetable: [[], [], [], [], [], [], []],
      tab: 0,
      currentTab: 0,
      items: ["asd", "asdasd"],
    };
  },
  props: {
    timetableTemplate: Object,
    showCourses: {
      type: Boolean,
      default: false,
    },
    showName: {
      type: Boolean,
      default: false,
    },
    disableFields: {
      type: Boolean,
      default: false,
    },
    onlyView: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    timetableTemplate_modified: {
      handler() {
        this.save_timetable_template();
      },
      deep: true,
    },
    timetable: {
      handler() {
        this.save_timetable_template();
      },
      deep: true,
    },
  },
  methods: {
    async next() {
      let allow = await this.validate_fields();
      if (allow) {
        this.tab = this.tab + 1;
      } else {
        this.$bvToast.toast(this.$t("MESSAGE.ERROR.DESCRIPTION_NEW"), {
          title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
          variant: "danger",
          solid: true,
        });
      }
    },
    copyPreviousDay(index) {
      this.timetable[index - 1].forEach(item => {
        this.timetable[index].push({
          productTimetableTemplateItemId: 0,
          day: index + 1,
          start: item.start,
          end: item.end,
        });
      });
    },
    deleteTimetableItem(index, index_time) {
      this.timetable[index].splice(index_time, 1);
    },
    async validate_fields() {
      return await this.$refs.observer.validate();
    },
    async onTabChange() {
      let allow = await this.validate_fields();
      if (!allow) {
        this.$nextTick(() => {
          this.tab = this.currentTab;
        });
      } else {
        this.currentTab = this.tab;
      }
    },
    async addTimetableItem(index) {
      await this.$refs.observer.validate().then(data => {
        if (data) {
          this.timetable[index].push({
            productTimetableTemplateItemId: 0,
            day: index + 1,
            start: "",
            end: "",
          });
        }
      });
    },
    save_timetable_template() {
      var timetableTemplate_modified = Vue.util.extend(
        {},
        this.timetableTemplate_modified
      );
      let timetable_final = [];
      this.timetable.forEach((list, dayofweek) => {
        dayofweek;
        list.forEach(time => {
          timetable_final.push(time);
        });
      });
      timetableTemplate_modified.new_timetable = Vue.util.extend(
        [],
        timetable_final
      );
      timetableTemplate_modified.product_timetable_template_items = Vue.util.extend(
        [],
        timetable_final
      );
      this.$emit("save_timetable_template", timetableTemplate_modified);
    },
    async mountDataProp() {
      this.timetable = [[], [], [], [], [], [], []];
      let timetableTemplate_modified = (this.timetableTemplate_modified = {
        ...this.timetableTemplate,
      });
      if (
        typeof timetableTemplate_modified.product_timetable_template_items !==
        "undefined"
      ) {
        var new_timetable = [];
        var items = [];
        for (var day = 1; day < 8; day++) {
          items = timetableTemplate_modified.product_timetable_template_items.filter(
            item => {
              if (item.day === day) return true;
              return false;
            }
          );
          if (items.length > 0) {
            items.forEach(item => {
              this.timetable[day - 1].push(Vue.util.extend({}, item));
            });
          }
        }
        timetableTemplate_modified.new_timetable = new_timetable;
        if (timetableTemplate_modified.campusId === 0) {
          timetableTemplate_modified.campusId = this.campus.campusId;
        }
        this.timetableTemplate_modified = timetableTemplate_modified;

        //We need the courses of this campus
        this.$store.dispatch(GET_COURSES, {}).then(result => {
          this.courses = result;
        });
      }

      //Avoid to show time as XX:XX:XX. transform to XX:XX
      if (
        typeof this.timetableTemplate_modified
          .product_timetable_template_items !== "undefined"
      ) {
        this.timetableTemplate_modified.product_timetable_template_items.map(
          item => {
            let start = item.start;
            if (start !== null) {
              if (start !== "") {
                if (start.split(":").length === 3) {
                  start = start.split(":");
                  start.pop();
                  start = start.join(":");
                }
              }
            }
            let end = item.end;
            if (end !== null) {
              if (end !== "") {
                if (end.split(":").length === 3) {
                  end = end.split(":");
                  end.pop();
                  end = end.join(":");
                }
              }
            }
            item.start = start;
            item.end = end;
            return item;
          }
        );
      }
      //If I don't add this timeout, it displays error validation.
      setTimeout(() => {
        this.validate_fields();
      }, 100);
    },
  },
  computed: {
    ...mapGetters(["campus"]),
    coursesRelated() {
      var courses = this.courses.filter(course => {
        if (
          parseInt(course.productTimetableTemplateId) ===
          parseInt(this.$route.params.id)
        )
          return true;
        return false;
      });
      return courses;
    },
  },
  components: {
    RowTimetable,
    ValidationObserver,
    ValidationProvider,
  },
  mounted() {
    this.mountDataProp();
  },
};
</script>

<style lang="scss" scoped>
.course {
  font-size: 14px;
  margin-top: 10px;
}
div.weeklabels {
  > span:nth-child(1) {
    @media (max-width: 1300px) {
      display: none;
    }
  }
  > span:nth-child(2) {
    display: none;
    @media (max-width: 1300px) {
      display: inline-block;
    }
  }
}
div.weeklabels.have_some {
  text-decoration: underline;
  > span {
    text-decoration: underline;
  }
}
.v-tab {
  @media (max-width: 1300px) {
    background: #eef0f8;
    border-radius: 50%;
    width: 40px !important;
    height: 40px;
    min-width: 40px !important;
    margin: 7px;
  }
}
.v-tab--active {
  @media (max-width: 1300px) {
    background: #5b86bf;
    color: white !important;
  }
}
.btn-add {
  margin-top: 20px;
}
.btn-copy {
  margin-top: 20px;
  margin-left: 10px;
}
</style>
