<template>
  <div>
    <v-menu
      v-model="datePicker"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <validation-provider
          :name="nameLarge ? nameLarge : name"
          v-slot="{ errors }"
          :rules="{ required: isRequired }"
        >
          <v-text-field
            :clearable="isClearable"
            :value="formatDate(date)"
            v-bind="attrs"
            v-on="on"
            :label="name"
            :error-messages="errors"
            readonly
            outlined
            dense
            :required="isRequired"
            @click:clear="date = null"
          ></v-text-field>
        </validation-provider>
      </template>
      <v-date-picker
        v-model="date"
        :min="dateMin"
        :first-day-of-week="campus.calendar_format"
        @input="
          datePicker = false;
          changes();
        "
        no-title
        scrollable
        @change="change"
      />
    </v-menu>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import { mapGetters } from "vuex";

const defaultDateFormat = "YYYY-MM-DD";

export default {
  props: {
    isRequired: {
      type: Boolean,
      default: () => false,
    },
    isClearable: {
      type: Boolean,
      default: () => false,
    },
    name: {
      type: String,
      required: true,
    },
    nameLarge: {
      type: String,
    },
    date: {
      type: String,
    },
    dateMin: {
      type: String,
    },
    change: {
      type: Function,
    },
  },
  components: {
    ValidationProvider,
  },
  data() {
    return {
      datePicker: false,
    };
  },
  methods: {
    formatDate(date) {
      if (this.$moment(date, defaultDateFormat).isValid()) {
        return this.$moment(date, defaultDateFormat).format(
          this.campus.date_format
        );
      }
    },
    changes() {
      if (this.change) {
        this.change(this.date);
      }
      this.$emit("changes", this.date);
    },
  },
  computed: {
    ...mapGetters(["campus"]),
  },
  watch: {
    date: function() {
      this.$emit("update:input", this.date);
    },
  },
};
</script>
