<template>
  <v-card class="mb-2">
    <v-card-title>{{ $t("PRODUCTS_ADDONS.PRICES") }}</v-card-title>
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="12" md="12">
          <validation-observer ref="observer">
            <v-form :disabled="disableFields">
              <!-- Row Field Name -->
              <v-row class="pb-0 mb-0 pt-0 mt-0">
                <v-col cols="12" md="12">
                  <v-radio-group v-model="form.typeTariffTemplate" row>
                    <v-radio
                      :label="$t('PRODUCTS_ADDONS.SELECT_TARIFF_TEMPLATE')"
                      :value="1"
                    ></v-radio>
                    <v-radio
                      v-if="create_or_edit_pricing_template"
                      :label="$t('PRODUCTS_ADDONS.CREATE_NEW_TARIFF')"
                      :value="2"
                    ></v-radio>
                    <v-radio
                      v-if="!create_or_edit_pricing_template"
                      :label="$t('PRODUCTS_ADDONS.CUSTOM_FEE_TEMPLATE')"
                      :value="3"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row
                v-show="form.typeTariffTemplate === 1"
                class="pt-0 mt-0 pb-0 mb-0"
              >
                <v-col cols="6">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('PRODUCTS_ADDONS.PRICING_TEMPLATE')"
                    rules="required"
                  >
                    <v-autocomplete
                      :items="pricingProducts"
                      item-text="name"
                      item-value="productPricingTemplateId"
                      v-model="form.productPricingTemplateId"
                      dense
                      outlined
                      :error-messages="errors"
                      :label="$t('PRODUCTS_ADDONS.PRICING_TEMPLATE')"
                      required
                      @change="keyPricingTemplate += 1"
                    ></v-autocomplete>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  form.productPricingTemplateId > 0 &&
                    form.typeTariffTemplate === 1
                "
                class="pt-0 mt-0"
              >
                <v-col cols="12">
                  <PricingTemplate
                    :showMessages="false"
                    :key="keyPricingTemplate"
                    :productPricingTemplateId="form.productPricingTemplateId"
                    :availableOptions="[1, 2, 3, 4]"
                    ref="pricingTemplate"
                    :onlyView="true"
                  ></PricingTemplate>
                </v-col>
              </v-row>
              <v-row v-show="form.typeTariffTemplate === 2">
                <v-col cols="12">
                  <PricingTemplate
                    :showMessages="false"
                    key="product-pricing-template"
                    :productPricingTemplateId="form.productPricingTemplateId"
                    :availableOptions="[1, 2, 3, 4]"
                    ref="pricingTemplate"
                  ></PricingTemplate>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  form.productPricingTemplateId > 0 &&
                    form.typeTariffTemplate === 3
                "
                class="pt-0 mt-0"
              >
                <v-col cols="12">
                  <PricingTemplate
                    :showMessages="false"
                    :key="keyPricingTemplate"
                    :productPricingTemplateId="form.productPricingTemplateId"
                    :availableOptions="[1, 2, 3, 4]"
                    ref="pricingTemplate"
                    :onlyView="false"
                  ></PricingTemplate>
                </v-col>
              </v-row>
            </v-form>
          </validation-observer>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapGetters } from "vuex";
import PricingTemplate from "@/view/pages/configure/pricing/components/PricingTemplate";
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    PricingTemplate,
  },
  props: {
    editData: {
      type: Object,
      default: function() {
        return {};
      },
    },
    disableFields: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: [],
      form: {
        productPricingTemplateId: null,
        typeTariffTemplate: 1,
        pricingTemplate: {},
      },
      keyPricingTemplate: 0,
      SELECT_TARIFF_TEMPLATE: 1,
      create_or_edit_pricing_template: 1,
      previousProductPricingTemplateId: null,
    };
  },
  methods: {
    async savePriceTemplate() {
      if (this.typeTariffTemplate !== 1 && this.typeTariffTemplate !== "1") {
        let productPricingTemplate = false;
        await this.$refs.pricingTemplate
          .validateAndGetData()
          .then(async data => {
            this.form.previousProductPricingTemplateId = null;
            productPricingTemplate = data;
            if (productPricingTemplate) {
              if (productPricingTemplate.productPricingTemplateId === 0) {
                await this.$refs.pricingTemplate.store(productPricingTemplate);
              } else {
                await this.$refs.pricingTemplate.save(productPricingTemplate);
              }
            }
            return productPricingTemplate;
          });
      } else {
        return this.form.productPricingTemplateId;
      }
    },
    async validateForm() {
      if (this.form.typeTariffTemplate === this.SELECT_TARIFF_TEMPLATE) {
        return await this.$refs.observer.validate();
      } else {
        return await this.$refs.pricingTemplate.validateAndGetData();
      }
    },
  },
  computed: {
    ...mapGetters(["productPricings"]),
    pricingProducts() {
      return this.productPricings?.filter(item => {
        return item.isReady === 1;
      });
    },
  },
  watch: {
    form: {
      handler: function() {
        this.$emit("data", this.form);
      },
      deep: true,
    },
    editData: {
      handler: function() {
        const dataForm = JSON.parse(JSON.stringify(this.editData));
        this.form.typeTariffTemplate = 1;
        if (dataForm.productPricingTemplateId > 0) {
          this.form.productPricingTemplateId =
            dataForm.productPricingTemplateId;
          if (dataForm.product_pricing_template) {
            if (dataForm.product_pricing_template.isReady === 0) {
              this.create_or_edit_pricing_template = 0;
              this.form.typeTariffTemplate = 3;
            }
          }
        }
        this.keyPricingTemplate += 1;
      },
      deep: true,
    },
    "form.typeTariffTemplate": {
      handler: function() {
        if (this.form.typeTariffTemplate === 1) {
          if (this.form.productPricingTemplateId > 0) {
            this.form.previousProductPricingTemplateId = this.form.productPricingTemplateId;
            this.form.productPricingTemplateId = null;
          }
        } else if (this.form.typeTariffTemplate === 3) {
          if (
            this.form.previousProductPricingTemplateId &&
            this.form.previousProductPricingTemplateId > 0
          )
            this.form.productPricingTemplateId = this.form.previousProductPricingTemplateId;
        }
        this.keyPricingTemplate += 1;
      },
      deep: true,
    },
  },
};
</script>
