<template>
  <div>
    <!--begin::simple-->
    <b-card
      class="mt-5 text-center option-cards"
      v-if="types === 'simple'"
      @click="showOptions(1)"
    >
      <div>
        <h4>
          {{ $t("PRICING.NEW.SIMPLE.TITLE") }}
        </h4>
        <h6 class="text-black-50 mt-3">
          {{ $t("PRICING.NEW.SIMPLE.TEXT1") }}
        </h6>
        <div class="text-black-50">
          {{ $t("PRICING.NEW.SIMPLE.TEXT2") }}
        </div>
      </div>
    </b-card>
    <!--begin::simple-->
    <b-card
      class="mt-5 text-center option-cards"
      v-if="types === 'flexible'"
      @click="showOptions(2)"
    >
      <div>
        <h4>
          {{ $t("PRICING.NEW.FLEXIBLE.TITLE") }}
        </h4>
        <h6 class="text-black-50 mt-3">
          {{ $t("PRICING.NEW.FLEXIBLE.TEXT1") }}
        </h6>
        <div class="text-black-50">
          {{ $t("PRICING.NEW.FLEXIBLE.TEXT2") }}
        </div>
        <div class="text-black-50">
          {{ $t("PRICING.NEW.FLEXIBLE.TEXT3") }}
        </div>
      </div>
    </b-card>
    <!--begin::fixed-->
    <b-card
      class="mt-5 text-center option-cards"
      v-if="types === 'fixed'"
      @click="showOptions(3)"
    >
      <div>
        <h4>
          {{ $t("PRICING.NEW.FIXED.TITLE") }}
        </h4>
        <h6 class="text-black-50 mt-3">
          {{ $t("PRICING.NEW.FIXED.TEXT1") }}
        </h6>
      </div>
    </b-card>
    <!--begin::Without Pricing-->
    <b-card
      class="mt-5 text-center option-cards card-without-description"
      v-if="types === 'one'"
      @click="showOptions(4)"
    >
      <div>
        <h4>
          {{ $t("PRICING.NEW.ONE_TITTLE") }}
        </h4>
      </div>
    </b-card>
    <!--begin::Without Pricing-->
    <b-card
      class="mt-5 text-center option-cards card-without-description"
      v-if="types === 'without'"
      @click="showOptions(5)"
    >
      <div>
        <h4>
          {{ $t("PRICING.NEW.WITHOUT_TITLE") }}
        </h4>
      </div>
    </b-card>
  </div>
</template>

<script>
import { GET_TYPE } from "@/core/services/store/configure/product_pricing.module";
export default {
  name: "new-pricing-template",
  props: {
    types: String,
  },
  methods: {
    showOptions(value) {
      this.$emit("setType", value);
      this.$store.dispatch(GET_TYPE, value);
      return true;
    },
  },
};
</script>
