<template>
  <v-card class="mb-2">
    <v-card-title>{{ $t("PRODUCTS_ADDONS.TIMETABLE") }}</v-card-title>
    <v-card-text>
      <v-form :disabled="disableFields">
        <v-row no-gutters>
          <v-col cols="12" md="12">
            <v-radio-group
              v-model="isVisibleSelectTimetable"
              @change="validateData()"
              row
            >
              <v-radio
                :label="$t('PRODUCTS_ADDONS.SELECT_TIMETABLE')"
                value="1"
              ></v-radio>
              <v-radio
                :label="$t('PRODUCTS_ADDONS.CUSTOM_TIMETABLE')"
                value="2"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          v-show="
            isVisibleSelectTimetable === 1 || isVisibleSelectTimetable === '1'
          "
        >
          <v-col cols="12" md="6">
            <v-select
              v-model="timetableId"
              :label="$t('PRODUCTS_ADDONS.TIMETABLE')"
              :items="timeTables"
              item-text="name"
              item-value="productTimetableTemplateId"
              dense
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row
          v-show="
            (isVisibleSelectTimetable === 1 ||
              isVisibleSelectTimetable === '1') &&
              timetableId > 0
          "
        >
          <v-col cols="12" md="12">
            <timetable-template
              :key="keyTimetable"
              ref="TimetableTemplateRef2"
              :timetableTemplate="timetableTemplate"
              showCourses="false"
              v-on:save_timetable_template="save_timetable_template($event)"
              :onlyView="true"
            ></timetable-template>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          v-if="
            isVisibleSelectTimetable === 2 || isVisibleSelectTimetable === '2'
          "
        >
          <v-col cols="12" md="12">
            <timetable-template
              :key="keyTimetable"
              ref="TimetableTemplateRef"
              :timetableTemplate="timetableTemplate"
              showCourses="false"
              v-on:save_timetable_template="save_timetable_template($event)"
              :disableFields="disableFields"
            ></timetable-template>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import TimetableTemplate from "@/view/content/components/forms/TimetableTemplate";
export default {
  components: { TimetableTemplate },
  props: {
    editData: {
      type: Object,
      default: function() {
        return {};
      },
    },
    disableFields: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisibleSelectTimetable: null,
      errors: [],
      timetableId: null,
      timetableTemplate: {
        name: "",
        campusId: 0,
        product_timetable_template_items: [],
      },
      keyTimetable: 0,
    };
  },
  mounted() {
    this.timetableTemplate.campusId = this.campus.campusId;
    if (this.$route.params.operation === "edit") {
      const dataForm = this.editData;
      if (dataForm) {
        if (dataForm.isReady) {
          this.isVisibleSelectTimetable = "1";
        } else {
          this.isVisibleSelectTimetable = "2";
        }

        this.timetableTemplate = dataForm.product_timetable_template;
      }
    }
  },
  methods: {
    ...mapActions(["getCustomProductTimetableTemplate"]),
    save_timetable_template(data) {
      this.timetableTemplate = data;
    },
    validateData() {
      this.timetableTemplate = {
        name: "",
        campusId: this.campus.campusId,
        product_timetable_template_items: [],
      };
      this.timetableId = null;
    },
  },
  computed: {
    ...mapGetters(["campus", "timeTables"]),
  },
  watch: {
    timetableTemplate: {
      handler: function() {
        this.$emit("data", this.timetableTemplate);
      },
      deep: true,
    },
    timetableId: {
      handler: function() {
        this.$emit("data", {
          ...this.timetableTemplate,
          productTimetableTemplateId: this.timetableId,
        });

        this.getCustomProductTimetableTemplate({
          productTimetableTemplateId: this.timetableId,
        }).then(response => {
          this.timetableTemplate = response[0];
          this.keyTimetable += 1;
        });
      },
      deep: true,
    },
    editData: {
      handler: function() {
        const dataForm = this.editData;
        if (dataForm) {
          if (dataForm.isReady) {
            this.isVisibleSelectTimetable = "1";
          } else {
            this.isVisibleSelectTimetable = "2";
          }
          this.timetableId = dataForm.productTimetableTemplateId;
          this.keyTimetable += 1;
        }
      },
      deep: true,
    },
  },
};
</script>
