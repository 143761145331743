var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"timetable_template"},[_c('validation-observer',{ref:"observer"},[_c('b-row',[_c('b-col',{attrs:{"lg":_vm.showCourses ? 12 : 8,"md":_vm.showCourses ? 12 : 8,"sm":"12"}},[_c('v-form',{ref:"form",attrs:{"disabled":_vm.disableFields}},[(_vm.showName)?_c('b-row',[_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('TIMETABLE.NAME'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"required",staticStyle:{"max-width":"300px"},attrs:{"label":_vm.$t('TIMETABLE.NAME'),"outlined":"","dense":"","hide-details":"auto","error-messages":errors,"readonly":_vm.onlyView},model:{value:(_vm.timetableTemplate_modified.name),callback:function ($$v) {_vm.$set(_vm.timetableTemplate_modified, "name", $$v)},expression:"timetableTemplate_modified.name"}})]}}],null,false,4155301756)})],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12","cols":"12"}},[_c('h6',[_c('i',[_vm._v(" "+_vm._s(_vm.$t("TIMETABLE.ENTER_START_END_OF_DAY"))+" ")])])])],1),_c('b-row',[_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12","cols":"12"}},[_c('v-tabs',{staticClass:"tab-timetable",attrs:{"left":"","align-with-title":"","hide-slider":"","show-arrows":"false"},on:{"change":_vm.onTabChange},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.weekDays),function(weekDay,index){return _c('v-tab',{key:index},[_c('div',{class:[
                      'weeklabels',
                      _vm.timetable[index].length !== 0 ? 'have_some' : '',
                    ]},[_c('span',[_vm._v(_vm._s(_vm.weekDays[Number(index)]))]),_c('span',[_vm._v(_vm._s(_vm.weekDaysCompressed[Number(index)]))])])])}),1),_c('v-tabs-items',{staticClass:"mt-5 mr-5 ml-5",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.weekDays),function(weekDay,index){return _c('v-tab-item',{key:index},[_vm._l((_vm.timetable[index]),function(time,index_time){return _c('span',{key:index_time},[_c('RowTimetable',{ref:'rows',refInFor:true,attrs:{"time":time,"index":index,"previous":index_time > 0 ? _vm.timetable[index][index_time - 1] : '',"show_btn_add":_vm.timetable[index].length - 1 === index_time
                          ? true
                          : false,"onlyView":_vm.onlyView},on:{"delete":function($event){return _vm.deleteTimetableItem(index, index_time)},"addTimetableItem":function($event){return _vm.addTimetableItem(index)}}})],1)}),(_vm.timetable[index].length === 0 && !_vm.onlyView)?_c('v-btn',{staticClass:"btn-add",attrs:{"disabled":_vm.disableFields,"depressed":"","color":"primary"},on:{"click":function($event){return _vm.addTimetableItem(index)}}},[_vm._v(_vm._s(_vm.$t("GENERAL.ADD_NEW")))]):_vm._e(),(
                      index !== 0 &&
                        _vm.timetable[index].length === 0 &&
                        _vm.timetable[index - 1].length > 0 &&
                        !_vm.onlyView
                    )?_c('v-btn',{staticClass:"btn-copy",attrs:{"depressed":"","color":"default"},on:{"click":function($event){return _vm.copyPreviousDay(index)}}},[_vm._v(_vm._s(_vm.$t("TIMETABLE.COPY_PREVIOUS_DAY")))]):_vm._e(),(
                      index !== 6 &&
                        _vm.timetable[index].length > 0 &&
                        _vm.timetable[index][0].start !== '' &&
                        _vm.timetable[index][0].end !== '' &&
                        !_vm.onlyView
                    )?_c('v-btn',{staticClass:"btn-copy",attrs:{"depressed":"","color":"default"},on:{"click":function($event){return _vm.next()}}},[_vm._v(_vm._s(_vm.$t("GENERAL.NEXT")))]):_vm._e()],2)}),1)],1)],1)],1)],1),(_vm.showCourses !== 'false')?_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('h4',[_vm._v(_vm._s(_vm.$t("TIMETABLE.COURSES_USING_TIMETABLE")))]),_vm._l((_vm.coursesRelated),function(course,index){return _c('div',{key:index,staticClass:"course"},[_c('router-link',{staticStyle:{"font-size":"14px"},attrs:{"to":'/products/edit/course/' + course.productId}},[_vm._v(_vm._s(course.name))])],1)})],2):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }