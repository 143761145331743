<template>
  <v-card class="mb-2">
    <v-card-text>
      <validation-observer ref="observer">
        <v-form :disabled="disableFields">
          <v-row no-gutters>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('PRODUCTS_ADDONS.TYPE_COURSE')"
                rules="required"
              >
                <v-radio-group
                  v-model="form.typeCourse"
                  row
                  required
                  :error-messages="errors"
                >
                  <v-radio
                    :label="$t('PRODUCTS_ADDONS.TYPE_COURSE_1')"
                    value="1"
                  ></v-radio>
                  <v-radio
                    :label="$t('PRODUCTS_ADDONS.TYPE_COURSE_2')"
                    value="2"
                  ></v-radio>
                </v-radio-group>
              </validation-provider>
            </v-col>
          </v-row>
          <!-- Row Field Name -->
          <v-row no-gutters>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('PRODUCTS_ADDONS.NAME')"
                rules="required|min:5"
              >
                <v-text-field
                  v-model="form.name"
                  dense
                  outlined
                  :error-messages="errors"
                  :label="$t('PRODUCTS_ADDONS.NAME')"
                  required
                >
                  <template v-slot:append>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                      </template>
                      {{ $t("PRODUCTS_ADDONS.MESSAGE_NAME_COURSE") }}
                    </v-tooltip>
                  </template>
                </v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <!-- Row Combobox Language -->
          <!-- Row Field Name -->
          <v-row no-gutters>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('PRODUCTS_ADDONS.LANGUAGE')"
                rules="required"
              >
                <v-autocomplete
                  v-model="form.languageId"
                  :items="languagesWithCourses"
                  dense
                  outlined
                  item-text="name"
                  item-value="languageId"
                  :error-messages="errors"
                  :label="$t('PRODUCTS_ADDONS.LANGUAGE')"
                  required
                ></v-autocomplete>
              </validation-provider>
            </v-col>
          </v-row>
          <!-- Row Combobox Category -->
          <!-- Row Field Name -->
          <v-row no-gutters>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('PRODUCTS_ADDONS.CATEGORY')"
                rules="required"
              >
                <v-select
                  v-model="form.categoryId"
                  :items="categories"
                  dense
                  outlined
                  item-text="name"
                  item-value="categoryId"
                  :error-messages="errors"
                  :label="$t('PRODUCTS_ADDONS.CATEGORY')"
                  required
                ></v-select>
              </validation-provider>
            </v-col>
          </v-row>
          <!-- Row Combobox Subcategory -->
          <!-- Row Field Name -->
          <v-row no-gutters v-show="subcategories.length > 0">
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('PRODUCTS_ADDONS.SUB_CATEGORY')"
                :rules="subcategories.length > 0 ? 'required' : ''"
              >
                <v-select
                  v-model="form.subCategoryId"
                  :items="subcategories"
                  dense
                  outlined
                  item-text="name"
                  item-value="id"
                  :error-messages="errors"
                  :label="$t('PRODUCTS_ADDONS.SUB_CATEGORY')"
                  required
                ></v-select>
              </validation-provider>
            </v-col>
          </v-row>
          <!-- Row Combobox Description -->
          <!-- Row Field Name -->
          <v-row no-gutters>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('PRODUCTS_ADDONS.DESCRIPTION')"
                rules="required|min:150|max:52000"
              >
                <v-textarea
                  v-model="form.description"
                  dense
                  outlined
                  :error-messages="errors"
                  :label="$t('PRODUCTS_ADDONS.DESCRIPTION')"
                  required
                  counter="52000"
                ></v-textarea>
              </validation-provider>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
      <v-dialog v-model="isShowDialogDelete" max-width="350">
        <v-card>
          <v-card-title class="headline">
            {{ $t("MESSAGE.DELETE.MODAL_TITLE") }}
          </v-card-title>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="red darken-1"
              text
              @click="isShowDialogDelete = false"
            >
              {{ $t("MESSAGE.DELETE.MODAL_NO") }}
            </v-btn>

            <v-btn color="primary darken-1" text @click="removeCourse()">
              {{ $t("MESSAGE.DELETE.MODAL_YES") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapGetters } from "vuex";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    editData: {
      type: Object,
      default: function() {
        return {};
      },
    },
    productType: {
      type: Object,
      default: function() {
        return 1; // Course
      },
    },
    disableFields: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: [],
      isShowDialogDelete: false,
      form: {
        typeCourse: null,
        productId: null,
        name: null,
        languageId: null,
        categoryId: null,
        subCategoryId: null,
        description: null,
      },

      // Values to list in form
      subcategories: [],
      filterStartDateTemplate: {
        isReady: 1,
        productTypeId: this.productType,
      },
      listValidStartDate: [],
    };
  },
  computed: {
    ...mapGetters(["categories", "languagesWithCourses"]),
  },
  methods: {
    validateForm() {
      return this.$refs.observer.validate();
    },
  },
  watch: {
    form: {
      handler: function() {
        this.$emit("data", this.form);
      },
      deep: true,
    },
    editData: {
      handler: function() {
        const dataForm = JSON.parse(JSON.stringify(this.editData));
        this.form.productId = dataForm.productId;
        this.form.typeCourse = dataForm.productCourseCategoryId + "";
        this.form.name = dataForm.product.name;
        this.form.languageId = dataForm.languageId;
        this.form.categoryId = dataForm.categoryId;
        this.form.subCategoryId = dataForm.subCategoryId;
        this.form.description = dataForm.product.description;
      },
      deep: true,
    },
    "form.categoryId": {
      handler: function() {
        const category = this.categories.filter(item => {
          return item.categoryId === this.form.categoryId;
        });
        if (category[0].sub_categories.length > 0) {
          this.subcategories = category[0].sub_categories;
        } else {
          this.subcategories = [];
        }
      },
      deep: true,
    },
    "form.typeCourse": {
      handler: function() {
        this.$emit("typeCourse", this.form.typeCourse);
      },
      deep: true,
    },
  },
};
</script>
