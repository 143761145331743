<template>
  <v-main>
    <v-form :disabled="isComponent">
      <v-row no-gutters justify="end" class="mb-0 mt-0 pb-0 pt-0">
        <v-col cols="12" md="12">
          <Buttons
            v-if="!isComponent"
            :textCancel="$t('GENERAL.CANCEL')"
            v-on:cancel="btnCancel"
            :textSave="$t('GENERAL.SAVE')"
            v-on:save="saveData"
            :save_loading="save_loading"
            :btnSaveNew="false"
            :save_new_loading="save_new_loading"
            :textSaveNew="$t('GENERAL.SAVE_AND_NEW')"
            v-on:save-new="btnSaveNew"
            :btnDuplicate="isEditCourse"
            :duplicate_loading="duplicate_loading"
            :textDuplicate="$t('GENERAL.DUPLICATE')"
            v-on:duplicate="duplicateData"
            :btnDelete="isEditCourse"
            :delete_loading="delete_loading"
            :textDelete="$t('GENERAL.DELETE')"
            v-on:delete="btnDelete"
          />
        </v-col>
      </v-row>
      <v-row gutters="2">
        <v-col cols="12" md="8">
          <!-- Principal Form -->
          <FormCourse
            ref="form"
            @data="generalFormData"
            @typeCourse="changeTypeCourse"
            :editData="courseEditData"
            :validateTypeCourse="changeTypeCourse"
            :disableFields="isComponent"
          ></FormCourse>
          <!-- Temporal settings -->
          <FormTemporal
            ref="formTemporal"
            @data="temporalData"
            :editData="courseEditData"
            :disableFields="isComponent"
          ></FormTemporal>

          <!-- Other settings -->
          <FormAdditional
            ref="formAdditional"
            @data="otherData"
            :editData="courseEditData"
            :typeCourse="typeCourse"
            :disableFields="isComponent"
          ></FormAdditional>

          <PricesComission
            v-if="formTemporal && formTemporal.typeDuration !== 1"
            ref="formPricesComission"
            @data="pricesData"
            :editData="courseEditData"
            :disableFields="isComponent"
          ></PricesComission>

          <Fees
            ref="formFees"
            @data="feesData"
            :editData="courseEditData"
            :disableFields="isComponent"
          ></Fees>

          <Accommodations
            ref="formAccommodation"
            @data="accommodationData"
            :editData="courseEditData"
          ></Accommodations>

          <TimeTable
            ref="timeTable"
            @data="timetableData"
            :editData="courseEditData"
            :disableFields="isComponent"
          ></TimeTable>
        </v-col>
        <!-- Additional Info -->
        <v-col cols="12" md="4">
          <v-card class="mb-2" v-if="!isComponent">
            <v-card-text class="flex-wrap d-flex">
              <h5>
                <i>
                  {{ $t("MESSAGE.INFORMATION.INFO") }}
                </i>
              </h5>
              <v-skeleton-loader
                v-bind="attrs"
                type="actions"
                v-if="$route.params.operation !== 'edit'"
              />
              <v-btn depressed color="primary" @click="preview" v-else>
                {{ $t("MESSAGE.INFORMATION.PREVIEW_BTN") }}
              </v-btn>
            </v-card-text>
          </v-card>
          <v-card class="mb-2">
            <v-card-title>{{ $t("PRODUCTS_ADDONS.VISIBILITY") }}</v-card-title>
            <v-card-text>
              <Visibility
                ref="formVisibility"
                :input.sync="formVisibility"
                :value="formVisibility"
                :disableFields="isComponent"
                :isPublished="isPublish"
              ></Visibility>
            </v-card-text>
          </v-card>
          <v-card class="mb-2">
            <v-card-title>{{
              $t("PRODUCTS_ADDONS.FOR_THIS_COURSE")
            }}</v-card-title>
            <v-card-text>
              <MarketsCountriesSelector
                :input.sync="eligibleBy"
                :markets-countries-data="eligibleByEdit"
                is-required
                :disableFields="isComponent"
              ></MarketsCountriesSelector>
            </v-card-text>
          </v-card>
          <v-card class="mb-2" v-if="currentUser.roleId == 1">
            <v-card-title>
              <div v-if="formRatingCoursesReview == 0">
                Current rating is: <strong>{{ formRatingCourses }}</strong>
              </div>
            </v-card-title>
            <v-card-text>
              <RatingCoursesSelector
                v-if="formRatingCoursesReview != 0"
                ref="formRatingCourses"
                :input.sync="formRatingCourses"
                :value="formRatingCourses"
              ></RatingCoursesSelector>
              <RatingCoursesReviewSelector
                ref="formRatingCoursesReview"
                :input.sync="formRatingCoursesReview"
                :value="formRatingCoursesReview"
              ></RatingCoursesReviewSelector>
            </v-card-text>
          </v-card>
          <Translation
            ref="translationComponent"
            :fields="fieldsTranslate"
            model="product"
            skeleton="3"
            :isOnlyShow="isComponent"
          ></Translation>
        </v-col>
      </v-row>
      <v-dialog v-model="isShowDialogDelete" max-width="350">
        <v-card>
          <v-card-title class="headline">
            {{ $t("MESSAGE.DELETE.MODAL_TITLE") }}
          </v-card-title>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="red darken-1"
              text
              @click="isShowDialogDelete = false"
            >
              {{ $t("MESSAGE.DELETE.MODAL_NO") }}
            </v-btn>

            <v-btn
              color="primary darken-1"
              text
              @click="
                deleteData();
                isShowDialogDelete = false;
              "
            >
              {{ $t("MESSAGE.DELETE.MODAL_YES") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <ForceDelete
        typeProduct="COURSE"
        :validatePromotions="validatePromotions"
        :validatePackages="validatePackages"
        @close-force-delete="cleanDataForceDelete()"
      ></ForceDelete>
    </v-form>
  </v-main>
</template>

<script>
// Import General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

// Import components
import FormCourse from "@/view/content/components/products_addons/course/Form";
import FormTemporal from "@/view/content/components/products_addons/temporal_setting/Form";
import FormAdditional from "@/view/content/components/products_addons/additional_setting/Form";
import PricesComission from "@/view/content/components/products_addons/prices_comission/Form";
import Fees from "@/view/content/components/products_addons/fees/Form";
import Accommodations from "@/view/content/components/products_addons/accommodation/Form";
import TimeTable from "@/view/content/components/products_addons/timetable/Form";
import Visibility from "@/view/content/components/forms/Visibility";
import MarketsCountriesSelector from "@/view/content/components/MarketsCountriesSelector";
import RatingCoursesSelector from "@/view/content/components/RatingCoursesSelector";
import RatingCoursesReviewSelector from "@/view/content/components/RatingCoursesReviewSelector";
import Translation from "@/view/content/components/translation/Index";
import Buttons from "@/view/content/components/Buttons";
import ForceDelete from "@/view/pages/productAddon/components/ForceDelete";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    FormCourse,
    FormTemporal,
    FormAdditional,
    PricesComission,
    Fees,
    Accommodations,
    TimeTable,
    Visibility,
    MarketsCountriesSelector,
    RatingCoursesSelector,
    RatingCoursesReviewSelector,
    Translation,
    Buttons,
    ForceDelete,
  },
  props: {
    isComponent: {
      type: Boolean,
      default: false,
    },
    propProductId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      fieldsTranslate: [
        {
          title: this.$t("PRODUCTS_ADDONS.NAME"),
          type: "name",
        },
        {
          title: this.$t("PRODUCTS_ADDONS.DESCRIPTION"),
          type: "description",
        },
      ],
      isShowDialogDelete: false,
      isEditCourse: false,
      generalData: null,
      formTemporal: null,
      formAdditional: null,
      formPricesComission: null,
      formFeesData: null,
      formAccommodationData: null,
      timeTable: null,
      formRatingCourses: null,
      formRatingCoursesReview: null,
      formVisibility: null,
      formTranslations: null,
      eligibleBy: null,
      eligibleByEdit: null,
      courseEditData: null,
      typeCourse: null,
      keyValidateDelete: 0,
      keyPrices: 0,
      validatePromotions: [],
      validatePackages: [],
      isPublish: false,
    };
  },
  created() {
    if (!this.isComponent) {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.$i18n.t("PRODUCTS_ADDONS.TITLE"),
        },
        {
          title: `${this.$i18n.t("PRODUCTS_ADDONS.COURSE")}`,
          route: "/products/course",
        },
        {
          title: `${this.$i18n.t(
            "PRODUCTS_ADDONS." + this.$route.params.operation.toUpperCase()
          )}`,
        },
      ]);
      this.getAllProductPricings();
      this.fetchData();
    }
  },
  computed: {
    ...mapGetters(["course", "currentUser"]),
  },
  methods: {
    ...mapActions([
      "storeCourse",
      "updateCourse",
      "deleteCourse",
      "getCourse",
      "getAllProductPricings",
    ]),
    preview() {
      const url = `${process.env.VUE_APP_PREVIEW_PATH}/${this.$route.params.index}`;
      window.open(url, "_blank");
    },
    generalFormData(data) {
      this.generalData = data;
    },
    temporalData(data) {
      this.formTemporal = data;
    },
    otherData(data) {
      this.formAdditional = data;
    },
    pricesData(data) {
      this.formPricesComission = data;
    },
    feesData(data) {
      this.formFeesData = data;
    },
    accommodationData(data) {
      this.formAccommodationData = data;
    },
    timetableData(data) {
      this.timeTable = data;
    },
    async validateFieldsForms() {
      const arrErrors = [];
      const resultGeneral = await this.$refs.form.validateForm();
      if (!resultGeneral) {
        arrErrors.push(1);
      }
      const resultFormTemporal = await this.$refs.formTemporal.validateForm();
      if (!resultFormTemporal) {
        arrErrors.push(2);
      }
      const resultFormAdditional = await this.$refs.formAdditional.validateForm();
      if (!resultFormAdditional) {
        arrErrors.push(3);
      }
      if (this.formTemporal && this.formTemporal.typeDuration !== 1) {
        const resultPricesTemplate = await this.$refs.formPricesComission.validateForm();
        if (!resultPricesTemplate) {
          arrErrors.push(4);
        }
      }

      if (arrErrors.length > 0) {
        this.$bvToast.toast(
          this.$t("PRODUCTS_ADDONS.PLEASE_VERIFY_FIELDS").toString(),
          {
            title: "Error",
            variant: "danger",
            solid: true,
          }
        );
        return false;
      } else {
        return true;
      }
    },
    async saveData() {
      const resultValidate = await this.validateFieldsForms();
      if (resultValidate) {
        if (
          this.formPricesComission &&
          (parseInt(this.formPricesComission.typeTariffTemplate) === 2 ||
            parseInt(this.formPricesComission.typeTariffTemplate) === 3)
        ) {
          await this.$refs.formPricesComission.savePriceTemplate();
        }

        const dataForm = this.prepareDataToSend();
        if (this.isEditCourse) {
          this.updateCourse({
            dataForm,
            productId: this.$route.params.index,
          }).then(response => {
            const dataJson = [
              response.product.name,
              response.product.description,
            ];
            this.$refs.translationComponent.getTranslations(
              this.$route.params.index,
              dataJson
            );
            this.isEditCourse = true;
            this.isPublish = response.product.isPublish;
            this.$bvToast.toast(
              this.$t("MESSAGE.UPDATE.DESCRIPTION_NEW").toString(),
              {
                title: this.$t("MESSAGE.UPDATE.TITLE_NEW").toString(),
                variant: "success",
                solid: true,
              }
            );
          });
        } else {
          this.storeCourse(dataForm).then(response => {
            this.$router.push(
              `/products/course/edit/${response.product.productId}`
            );
            this.$store.dispatch(SET_BREADCRUMB, [
              {
                title: this.$i18n.t("PRODUCTS_ADDONS.TITLE"),
              },
              {
                title: `${this.$i18n.t("PRODUCTS_ADDONS.COURSE")}`,
                route: "/products/course",
              },
              {
                title: `${this.$i18n.t("PRODUCTS_ADDONS.EDIT")}`,
              },
            ]);
            this.isEditCourse = true;
            this.$bvToast.toast(
              this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW").toString(),
              {
                title: this.$t("MESSAGE.SUCCESS.TITLE_NEW").toString(),
                variant: "success",
                solid: true,
              }
            );
            const dataJson = [
              response.product.name,
              response.product.description,
            ];
            this.$refs.translationComponent.getTranslations(
              response.product.productId,
              dataJson
            );
          });
        }
      }
    },
    duplicateData() {
      this.generalData.name = `${this.generalData.name} (copy)`;
      const dataForm = this.prepareDataToSend();

      this.storeCourse(dataForm).then(response => {
        const dataJson = [
          this.courseEditData.name,
          this.courseEditData.product.description,
        ];
        this.$refs.translationComponent.getTranslations(
          this.courseEditData.productId,
          dataJson
        );
        this.$router.push(
          `/products/course/edit/${response.product.productId}`
        );
        this.isEditCourse = true;
        this.$bvToast.toast(
          this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW").toString(),
          {
            title: this.$t("MESSAGE.SUCCESS.TITLE_NEW").toString(),
            variant: "success",
            solid: true,
          }
        );
      });
    },
    deleteData() {
      this.deleteCourse(this.$route.params.index).then(data => {
        if (data.success) {
          this.$router.push("/products/course");
          this.$bvToast.toast(this.$t("MESSAGE.DELETE.DESCRIPTION_NEW"), {
            title: this.$t("MESSAGE.DELETE.TITLE_NEW"),
            variant: "success",
            solid: true,
          });
        } else {
          this.validatePromotions = data.promotion;
          this.validatePackages = data.package;

          this.isShowDialogForceDelete = true;
          this.keyValidateDelete += 1;
        }
      });
    },
    btnDelete() {
      this.isShowDialogDelete = true;
    },
    btnCancel() {
      this.$router.push("/products/course");
    },
    changeTypeCourse(data) {
      this.typeCourse = data;
    },
    prepareDataToSend: function() {
      let fixedTypeDuration = this.formTemporal.fixedTypeDuration.map(item => {
        if (item.startDate && item.endDate && item.amount) {
          return item;
        }
      });
      if (!fixedTypeDuration[0]) {
        fixedTypeDuration = [];
      }
      let eligibleMarkets = null;
      let eligibleCountries = null;
      let isEligibleAllCountries = null;
      let startDateTemplate = null;

      if (this.eligibleBy && this.eligibleBy.eligibleMarkets) {
        eligibleMarkets = this.eligibleBy.eligibleMarkets;
      }
      if (this.eligibleBy && this.eligibleBy.eligibleCountries) {
        eligibleCountries = this.eligibleBy.eligibleCountries;
      }
      if (this.eligibleBy && this.eligibleBy.isEligibleAllCountries) {
        isEligibleAllCountries = this.eligibleBy.isEligibleAllCountries;
      }
      if (this.formTemporal.startDateTemplate) {
        startDateTemplate = this.formTemporal.startDateTemplate;
      }

      let productPriceTemplateId = "";
      if (this.formPricesComission && this.formPricesComission !== null) {
        productPriceTemplateId = this.formPricesComission
          .productPriceTemplateId;
      } else {
        productPriceTemplateId = undefined;
      }

      let visibilityIsReady = this.formVisibility;
      if (!visibilityIsReady) {
        visibilityIsReady = 0;
      }

      return {
        productPriceTemplateId,
        product: {
          name: this.generalData.name,
          description: this.generalData.description,
          isReady: visibilityIsReady,
          productDurationTypeId: this.formTemporal.typeDuration,
          reqMinDurationAmount: this.formTemporal.minimumDuration,
          reqMaxDurationAmount: this.formTemporal.maximumDuration,
          reqMinDurationTypeId: this.formTemporal.typeDurationMinMax,
          reqMinAge: this.formAdditional.minimumAge,
          reqMaxAge: this.formAdditional.maximumAge,
          availabilityStart: this.formTemporal.availabilityStartDate,
          availabilityEnd: this.formTemporal.availabilityEndDate,
          minQty: this.formAdditional.minimumStudent,
          maxQty: this.formAdditional.maximumStudent,
          lessonQty: this.formAdditional.quantityLessons,
          eligibleMarkets: eligibleMarkets,
          eligibleCountries: eligibleCountries,
          isEligibleAllCountries: isEligibleAllCountries,
        },
        course: {
          name: this.generalData.name,
          productCourseCategoryId: this.generalData.typeCourse,
          languageId: this.generalData.languageId,
          categoryId: this.generalData.categoryId,
          subCategoryId: this.generalData.subCategoryId,
          lessonDuration: this.formAdditional.durationLessons,
          reqLevelId: this.formAdditional.levelMinimumLanguage,
          firstDay: this.formAdditional.firstDay,
          levelAssessed: this.formAdditional.levelAssessed,
          rating: this.formRatingCourses,
          ratingReview: this.formRatingCoursesReview,
        },
        temporal: {
          productEdition: fixedTypeDuration,
          typeStartDateTemplate: this.formTemporal.startDateType,
          startDateTemplate: startDateTemplate,
        },
        fees: this.formFeesData,
        accommodations: this.formAccommodationData,
        timetable: this.timeTable,
      };
    },
    cleanDataForceDelete() {
      this.validatePromotions = [];
      this.validatePackages = [];
    },
    fetchData() {
      if (this.$route.params.operation === "edit") {
        this.isEditCourse = true;
        this.getCourse(this.$route.params.index).then(response => {
          this.courseEditData = response;
          this.isPublish = response.product.isPublish;
          this.formVisibility = response.product.isReady;
          this.formRatingCourses = response.rating;
          this.formRatingCoursesReview = response.ratingReview;
          const dataJson = [
            this.courseEditData.product.name,
            this.courseEditData.product.description,
          ];
          this.$refs.translationComponent.getTranslations(
            this.courseEditData.productId,
            dataJson
          );

          if (
            response.product.isEligibleAllCountries === 1 ||
            response.product.isEligibleAllCountries === "1"
          ) {
            this.eligibleByEdit = {
              isEligibleAllCountries: response.product.isEligibleAllCountries,
            };
          }

          if (response.product.eligibleMarkets) {
            this.eligibleByEdit = {
              eligibleMarkets: response.product.eligibleMarkets,
            };
          }

          if (response.product.eligibleCountries) {
            this.eligibleByEdit = {
              eligibleCountries: response.product.eligibleCountries,
            };
          }
        });
      } else {
        this.isEditCourse = false;
      }
    },
  },

  watch: {
    $route: "fetchData",
  },
};
</script>

<style scoped>
.btn-outline-primary {
  border-radius: 0;
}
ul {
  padding: 0 !important;
}

.v-list-item {
  min-height: 24px;
}
</style>
