<template>
  <div class="RatingCoursesReviewSelector">
    <v-select
      :required="isRequired"
      outlined
      dense
      label="Rated By"
      :items="ratedByOptions"
      :error-messages="errors"
      v-model="value"
      item-text="Name"
      @change="$emit('update:input', value)"
    >
    </v-select>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      ratedByOptions: [
        {
          Name: "Andrew Pozo",
          value: 3,
        },
        {
          Name: "Bernardo Newlink",
          value: 7,
        },
        {
          Name: "Maria Correia",
          value: 8,
        },
        {
          Name: "Reynalds Rodrigues",
          value: 9,
        },
      ],
    };
  },
};
</script>
