var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row-timetable"},[_c('validation-observer',{ref:"observer_timetable"},[_c('b-row',{staticStyle:{"margin-bottom":"0px"}},[_c('b-col',{staticClass:"class_time",attrs:{"lg":"12","md":"12","sm":"12"}},[_c('div',[_c('validation-provider',{attrs:{"name":_vm.$t('CALENDAR.START_TIME_NAME'),"rules":{
              required: true,
              time: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
              time_lower_than: _vm.time.end,
              time_greater_than_previous: _vm.previous.end,
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##:##'),expression:"'##:##'"}],staticClass:"time_text required",attrs:{"placeholder":"HH:MM","label":_vm.$t('CALENDAR.START_TIME_NAME'),"clearable":"","error-messages":errors,"readonly":_vm.onlyView},model:{value:(_vm.time.start),callback:function ($$v) {_vm.$set(_vm.time, "start", $$v)},expression:"time.start"}})]}}])})],1),_c('span',{staticClass:"guion"},[_vm._v("—")]),_c('div',[_c('validation-provider',{attrs:{"name":_vm.$t('CALENDAR.END_TIME_NAME'),"rules":{
              time: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
              required: _vm.required,
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##:##'),expression:"'##:##'"}],staticClass:"time_text required",attrs:{"placeholder":"HH:MM","label":_vm.$t('CALENDAR.END_TIME_NAME'),"clearable":"","error-messages":errors,"readonly":_vm.onlyView},model:{value:(_vm.time.end),callback:function ($$v) {_vm.$set(_vm.time, "end", $$v)},expression:"time.end"}})]}}])})],1),_c('span',{staticClass:"guion"}),(!_vm.onlyView)?_c('a',{staticClass:"btn btn-icon btn-light btn-hover-danger btn-delete-timetable",on:{"click":_vm.deleteRow}},[_c('span',{staticClass:"svg-icon svg-icon-md svg-icon-danger"},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-delete ")])],1)]):_vm._e(),(_vm.show_btn_add && !_vm.onlyView)?_c('v-btn',{staticClass:"btn-add",attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.$emit('addTimetableItem', _vm.index)}}},[_vm._v(_vm._s(_vm.$t("GENERAL.ADD")))]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }