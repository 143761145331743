<template>
  <v-card class="mb-2">
    <v-card-title>{{ $t("PRODUCTS_ADDONS.FEES") }}</v-card-title>
    <v-card-text>
      <v-form :disabled="disableFields">
        <v-row no-gutters>
          <v-col cols="12" md="12">
            <ProductsTreeView
              :input.sync="fees"
              :selection="fees"
              :data="listAllFees"
              :name="$t('PRODUCTS_ADDONS.FEES')"
              keyId="feeId"
              keyName="name_with_price"
              :isOnlyShow="disableFields"
            />
          </v-col>
          <v-col cols="12" md="12" v-if="!disableFields">
            <FeeModal :type="applicableTo"></FeeModal>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProductsTreeView from "@/view/content/components/ProductsTreeView";
import FeeModal from "@/view/content/components/FeeModal";

const APPLICABLE_TO_COURSES = 1;

export default {
  components: { ProductsTreeView, FeeModal },
  props: {
    editData: {
      type: Object,
      default: function() {
        return {};
      },
    },
    disableFields: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      applicableTo: APPLICABLE_TO_COURSES,
      errors: [],
      fees: [],
    };
  },
  mounted() {
    this.getListFees();
  },
  methods: {
    ...mapActions(["getListFees"]),
    async storeForm() {
      this.$refs.observer.validate().then(data => {
        if (data) {
          if (this.$route.params.operation === "new") {
            this.storeCourse(this.formCourse).then(response => {
              this.$router.push("/products/edit/course/" + response);
            });
          } else if (this.$route.params.operation === "edit") {
            this.formCourse.productId = this.$route.params.index;
            this.updateCourse(this.formCourse);
          }
        }
      });
    },
    duplicate() {
      this.$refs.observer.validate().then(data => {
        if (data) {
          this.formCourse.name = `${this.formCourse.name} (copy)`;
          delete this.formCourse.productId;
          this.storeCourse(this.formCourse).then(response => {
            this.$router.push("/products/edit/course/" + response);
          });
        }
      });
    },
    deleteRecord() {
      this.isShowDialogDelete = true;
    },
    removeCourse() {
      this.deleteCourse(this.formCourse).then(() => {
        this.$router.push("/products");
      });
    },
    addDataForm: data => {
      this.formCourse = data;
    },
  },
  computed: {
    ...mapGetters(["getFees", "fee"]),
    listAllFees() {
      return this.getFees.filter(item => {
        item.name_with_price = `${item.name} (${item.price})`;
        return item.applicableTo === 1;
      });
    },
  },
  watch: {
    fees: {
      handler: function() {
        this.$emit("data", this.fees);
      },
      deep: true,
    },
    listAllFees: {
      handler: function(val, oldVal) {
        if (oldVal.length && val.length > oldVal.length) {
          this.fees.push({
            id: this.fee.feeId,
          });
        }
      },
    },
    editData: {
      handler: function() {
        const dataForm = JSON.parse(JSON.stringify(this.editData));
        this.fees = dataForm.fee.map(item => {
          return { id: item.feeId };
        });
      },
      deep: true,
    },
  },
};
</script>
