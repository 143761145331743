export const DEFAULT_DURATION_TYPE = 3;

const durationTypesFormat = (durationTypes, durations) => {
  return durationTypes
    .filter(value => durations.includes(value.codeName))
    .map(result => {
      const codeName = result.codeName.toString().toLowerCase();
      return {
        ...result,
        codeName: `${codeName.charAt(0).toUpperCase()}${codeName.slice(1)}s`,
      };
    });
};
export default durationTypesFormat;
