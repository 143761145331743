<template>
  <v-row no-gutters>
    <v-col cols="12" md="12">
      <v-switch
        :disabled="disableFields"
        v-model="value"
        @change="$emit('update:input', value)"
        :label="`${$t('GENERAL.INFO.VISIBILITY')}`"
      ></v-switch>
      <span class="color-green" v-if="isPublished">{{
        $t("GENERAL.MESSAGE_VISIBLE_PUBLISHED")
      }}</span>
      <span class="color-red" v-else>{{
        $t("GENERAL.MESSAGE_VISIBLE_WARNING")
      }}</span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    isPublished: {
      type: Boolean,
      default: false,
    },
    disableFields: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getData() {
      return JSON.parse(JSON.stringify(this.isReady));
    },
  },
};
</script>

<style scoped>
.color-red {
  color: #f01f1f;
}

.color-green {
  color: #308732;
}
</style>
